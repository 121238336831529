import { useEffect } from "react";
import { useCart } from "../../context/Cart/CartContext.jsx";
import AddressShipping from "../../components/AddressShipping/AddressShipping.jsx";

function AddressShippingContainer() {
	const { cart } = useCart();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<AddressShipping productsInCart={cart?.length} />
		</div>
	);
}

export default AddressShippingContainer;
