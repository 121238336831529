import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { setCookie, getCookie, deleteCookie } from "../../utils/auxFunc";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const [userData, setUserData] = useState();
	const [auth, setAuth] = useState(false);

	const signIn = useCallback((newUser) => {
		// window.localStorage.setItem("userElDon", JSON.stringify(newUser));
		setCookie("userElDon", JSON.stringify(newUser), 7);
		setUserData(newUser);
		setAuth((auth) => !auth);
	}, []);

	// const signIn = (newUser) => {
	// 	setUserData(newUser);
	// 	setAuth((auth) => !auth);
	// };

	const signOut = () => {
		// window.localStorage.removeItem("userElDon");
		deleteCookie("userElDon");
		setUserData();
		setAuth((auth) => !auth);
	};

	useEffect(() => {
		// const loggedUserElDon = window.localStorage.getItem("userElDon");
		const loggedUserElDon = getCookie("userElDon");
		if (loggedUserElDon !== undefined) {
			signIn(JSON.parse(loggedUserElDon));
		}
	}, [signIn]);

	return (
		<AuthContext.Provider
			value={{
				userData,
				auth,
				signIn,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
