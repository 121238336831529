import { useState } from "react";
import Swal from "sweetalert2";
import AccountCreationForm from "../../components/AccountCreationForm/AccountCreationForm";
import REGEX from "../../utils/regex";

function AccountCreationFormContainer() {
	const [dataForm, setDataForm] = useState([
		{
			id: "nameForm",
			value: "",
			error: "",
			regEx: REGEX.textFormatValidator,
			regMessage: "El nombre debe contener solo letras.",
		},
		{
			id: "surnameForm",
			value: "",
			error: "",
			regEx: REGEX.textFormatValidator,
			regMessage: "El apellido debe contener solo letras.",
		},
		{
			id: "emailForm",
			value: "",
			error: "",
			regEx: REGEX.emailFormatValidator,
			regMessage: "El formato de email es inválido (Ej: usuario@correo.com ).",
		},
		{
			id: "passwordForm",
			value: "",
			error: "",
			regEx: REGEX.passwordFormatValidator,
			regMessage: "La contraseña debe contener 8 números.",
		},
		{
			id: "phoneForm",
			value: "",
			error: "",
			regEx: REGEX.phoneFormatValidator,
			regMessage:
				"El teléfono debe contener entre 8 y 10 números (sin espacios, ni guiones).",
		},
		{
			id: "companyNameForm",
			value: "",
			error: "",
			regEx: REGEX.companyNameFormatValidator,
			regMessage:
				"El nombre de la empresa no puede contener caracteres especiales.",
		},
		{
			id: "cuitForm",
			value: "",
			error: "",
			regEx: REGEX.cuitFormatValidator,
			regMessage: "El cuit debe contener 11 números (sin guiones).",
		},
	]);

	function handleChange(e) {
		const newDataForm = [...dataForm];
		newDataForm.forEach((dato) => {
			if (e.target.name === dato.id) dato.value = e.target.value;
		});
		setDataForm(newDataForm);
	}

	const saveDataInAPI = () => {
		let error = "";
		let headers = {};
		let body = {};
		const requestOptions = {
			method: "POST",
			mode: "cors",
			headers: headers,
			body: body,
		};
		const url = `http://168.197.51.28:96/Api/Security/CreateAccount?nameForm=${dataForm[0].value}&surnameForm=${dataForm[1].value}&emailForm=${dataForm[2].value}&passwordForm=${dataForm[3].value}&phoneForm=${dataForm[4].value}&companyNameForm=${dataForm[5].value}&cuitForm=${dataForm[6].value}`;
		fetch(url, requestOptions)
			.then((data) => {
				const newDataForm = [...dataForm];
				newDataForm.forEach((dato) => {
					dato.value = "";
				});
				setDataForm(newDataForm);
				Swal.fire({
					customClass: {
						confirmButton: "swalBtnOk",
						title: "swalTitle",
					},
					position: "left",
					icon: "success",
					title: "Su formulario se envió con éxito",
					text: "Próximamente nuestro equipo de ventas se pondrá en contacto con usted para validar su registro",
					width: "350px",
					showConfirmButton: true,
					focusConfirm: false,
					iconColor: "#36A160",
					color: "#FAFAFA",
					background: "#292929",
					confirmButtonColor: "#292929",
				});
			})
			.catch((err) => (error = err));
		return error;
	};

	function dataValidation() {
		let errorMessage = "";
		const newDataForm = [...dataForm];
		newDataForm.forEach((field) => {
			field.error = !field.value.match(field.regEx) ? field.regMessage : "";
			errorMessage = errorMessage + field.error;
		});
		setDataForm(newDataForm);

		return errorMessage;
	}
	function handleSubmit(e) {
		e.preventDefault();
		let validatingError = "";
		validatingError = dataValidation();
		if (validatingError !== "") {
			return;
		}
		saveDataInAPI();
	}

	return (
		<AccountCreationForm
			handleSubmit={handleSubmit}
			handleChange={handleChange}
			dataForm={dataForm}
		/>
	);
}

export default AccountCreationFormContainer;
