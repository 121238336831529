import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useCart } from "../../context/Cart/CartContext";
import ItemDetail from "../../components/ItemDetail/ItemDetail";
import { getSizesByColor } from "../../utils/auxFunc";
import { api } from "../../services/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/Auth/AuthContext";
import { useConfig } from "../../context/Config/ConfigContext";

const Toast = Swal.mixin({
	// toast: true,
	position: "center",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
});

function ItemDetailContainer() {
	const [product, setProduct] = useState();
	const [productToAdd, setProductToAdd] = useState({});
	const [componentColor, setComponentColor] = useState();
	const [changeImgInEachColor, setChangeImgInEachColor] = useState({ img: "" });
	const [imgIndex, setImgIndex] = useState(0);
	const [resetCounter, setResetCounter] = useState(false);
	const { idProduct } = useParams();
	const { throttle } = useConfig();
	const { addToCart, cart } = useCart();
	const { userData } = useAuth();

	const getInitialValue = (id) => {
		const componentToAdd = productToAdd.components?.find(
			(component) => component.idComponent === id
		);
		const initial = componentToAdd?.quantity;

		return initial;
	};

	const largerStockComponent = useCallback((components) => {
		let stocks = [];
		components?.forEach(({ types }) => {
			stocks.push(types.reduce((acc, { stock }) => acc + stock, 0));
		});

		const largerStockIndex = stocks.findIndex(
			(stock) => stock === Math.max(...stocks)
		);

		return components[largerStockIndex];
	}, []);

	const handleProductsToAddToCart = (id, quantity) => {
		let productToAddAux = JSON.parse(JSON.stringify(productToAdd));
		if (quantity === 0) {
			const componentsUpdated = productToAddAux.components?.filter(
				(component) => component.idComponent !== id
			);
			productToAddAux.components = componentsUpdated;
		}
		if (
			productToAddAux.components?.some(
				(component) => component.idComponent === id
			)
		) {
			productToAddAux.components.find(
				(component) => component.idComponent === id
			).quantity = quantity;
		} else {
			const componentToAdd = product.components.find(
				(component) => component.idComponent === id
			);
			componentToAdd.quantity = quantity;
			productToAddAux.components = [
				...productToAddAux.components,
				componentToAdd,
			];
		}

		setProductToAdd(productToAddAux);
		setResetCounter(false);
	};

	const handleAddToCart = () => {
		const productAux = { ...product };
		const isStockEnough = productAux.components.every((component) => {
			const comp = productToAdd.components.find(
				(el) => el.idComponent === component.idComponent
			);
			return component.stock - comp.quantity >= 0;
		});

		if (!isStockEnough) {
			Toast.fire({
				customClass: {
					title: "productAdded",
					icon: "productAddedIcon",
				},
				icon: "error",
				title: "Stock insuficiente",
				text: "Controle cantidades solicitadas",
			});
			return;
		}
		const productsToAdd = removeComponentsZeroQuantity();

		let prodInCart = cart?.find(
			(product) => product.idProduct === parseInt(idProduct)
		);

		productsToAdd.components?.forEach((component) => {
			prodInCart?.components.forEach((el) => {
				if (el.idComponent === component.idComponent) {
					component.quantity += el.quantity;
				}
			});
		});

		// para restar stock desde el front TEMPORAL
		productAux.components.forEach((component) =>
			productToAdd.components.forEach((el) => {
				if (
					el.idComponent === component.idComponent &&
					component.stock - el.quantity >= 0
				) {
					component.stock -= el.quantity;
				}
			})
		);

		const componentColorAux = { ...componentColor };
		componentColorAux.types.forEach((component) =>
			productToAdd.components.forEach((el) => {
				if (
					el.idComponent === component.idComponent &&
					component.stock - el.quantity >= 0
				) {
					component.stock -= el.quantity;
				}
			})
		);
		setProduct(productAux);
		setComponentColor(componentColorAux);

		if (productsToAdd.components.length > 0) {
			addToCart(productsToAdd);
			setProductToAdd(product);
			setResetCounter(true);
		}
	};

	//array con colores del producto con sus detalles
	const sizesByColor = product && getSizesByColor(product);

	function handleClickColor(color) {
		//setea el array de colores, y el objeto que coincida con el color presionado lo trae
		//para luego mapearlo y arrojar las medidas, con sus stocks.
		setChangeImgInEachColor({ img: "" });
		setComponentColor(
			sizesByColor.find(
				(colorComponent) => colorComponent.descriptionColor === color
			)
		);
		setImgIndex(0);
	}

	function handleChangeImg(num) {
		setImgIndex(num);
		setChangeImgInEachColor(componentColor?.imgC[num]);
	}

	//FUNCIONES PARA EL MODALSHEET////////////////////////////////////////////////////////

	const getQuantityByColor = (color) => {
		let componentsByColor = cart
			?.find((element) => element.idProduct === product.idProduct)
			?.components.filter((component) => component.descriptionColor === color);
		if (componentsByColor && color === componentsByColor[0]?.descriptionColor) {
			return componentsByColor.reduce((acc, { quantity }) => acc + quantity, 0);
		}
	};

	// const sameQuantityAsCart = () => {
	// 	let prodInCart = cart?.find(
	// 		(product) => product.idProduct === parseInt(idProduct)
	// 	);

	// 	return productToAdd.components?.every((component) => {
	// 		const compInCart = prodInCart?.components.find(
	// 			(el) => el.idComponent === component.idComponent
	// 		);
	// 		return !compInCart
	// 			? component.quantity === 0
	// 			: component.quantity === compInCart.quantity;
	// 	});
	// };

	const quantityToAddZero = () => {
		const acc = productToAdd.components?.reduce(
			(acc, { quantity }) => acc + quantity,
			0
		);
		return acc === 0;
	};

	const isDisabled = productToAdd && quantityToAddZero();

	const removeComponentsZeroQuantity = () => {
		let productToAddAux = JSON.parse(JSON.stringify(productToAdd));
		const componentsWithQuantity = productToAddAux.components?.filter(
			(component) => component.quantity !== 0
		);
		productToAddAux.components = componentsWithQuantity;

		return productToAddAux;
	};

	const getProductById = useCallback(
		async (controller) => {
			try {
				const response = await api.products.listById(
					userData.clientPriceListId,
					parseInt(idProduct),
					controller.signal
				);
				const apiProduct = await response.json();
				apiProduct.components.forEach((component) => (component.quantity = 0));
				setProduct(apiProduct);
				setProductToAdd(apiProduct);
			} catch (error) {
				return Swal.fire({
					title: "Error!",
					text: `Error de servidor: ${error.message}`,
					icon: "error",
				});
			}
		},
		[idProduct, userData.clientPriceListId]
	);

	useEffect(() => {
		const abortController = new AbortController();

		!product && getProductById(abortController);

		if (componentColor === undefined) {
			product &&
				setComponentColor(largerStockComponent(getSizesByColor(product)));
		}
		//permite que se desplace hasta la parte superior de la pagina despues de renderizar
		window.scrollTo(0, 0);

		return () => {
			abortController.abort();
		};
	}, [
		cart,
		componentColor,
		getProductById,
		idProduct,
		largerStockComponent,
		product,
		userData.clientId,
		userData.clientPriceListId,
	]);

	return (
		<ItemDetail
			throttle={throttle}
			getInitialValue={getInitialValue}
			handleProductsToAddToCart={handleProductsToAddToCart}
			handleAddToCart={handleAddToCart}
			product={product}
			sizesByColor={sizesByColor}
			handleClickColor={handleClickColor}
			componentColor={componentColor}
			isDisabled={isDisabled}
			getQuantityByColor={getQuantityByColor}
			handleChangeImg={handleChangeImg}
			changeImgInEachColor={changeImgInEachColor}
			imgIndex={imgIndex}
			resetCounter={resetCounter}
		/>
	);
}

export default ItemDetailContainer;
