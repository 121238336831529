import React, { useEffect, useState } from "react";
import Counter from "../../components/Counter/Counter";

const CounteDetailContainer = ({
	item,
	initial,
	handleProducts,
	resetCounter,
}) => {
	const [count, setCount] = useState(initial);

	const increment = () => {
		if (count < item?.stock) {
			setCount((prevCount) => prevCount + 1);
		}
	};

	const decrement = () => {
		if (count > 0) {
			setCount((prevCount) => prevCount - 1);
		}
	};

	useEffect(() => {
		if (resetCounter) setCount(0);
	}, [resetCounter]);

	useEffect(() => {
		handleProducts(item.idComponent, count);
	}, [count, item.idComponent]);

	return (
		<Counter value={count} onIncrement={increment} onDecrement={decrement} />
	);
};

export default CounteDetailContainer;
