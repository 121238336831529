import { useRef, useState } from "react";

export const useHorizontalScroll = () => {
	const [state, setState] = useState({
		isClicked: false,
		startX: 0,
		scrollX: 0,
	});
	const ref = useRef();

	const onMouseDown = (evt) => {
		if (ref && ref.current && !ref.current.contains(evt.target)) return;
		setState({
			isClicked: true,
			startX: evt.pageX - ref.current.offsetLeft,
			scrollX: ref.current.scrollLeft,
		});
	};
	const onMouseUp = (evt) => {
		if (ref && ref.current && !ref.current.contains(evt.target)) return;
		evt.preventDefault();
		setState((prevState) => ({
			...prevState,
			isClicked: false,
		}));
	};

	const handleMouseMove = (evt) => {
		if (ref && ref.current && !ref.current.contains(evt.target)) return;
		evt.preventDefault();
		const { startX, scrollX, isClicked } = state;
		if (isClicked) {
			const xMove = evt.pageX - ref.current.offsetLeft;
			const deltaX = xMove - startX;
			ref.current.scrollLeft = scrollX - deltaX;
		}
	};

	const slideLeft = () => {
		ref.current.scroll({
			left: ref.current.scrollLeft - 70,
			behavior: "smooth",
		});
	};

	const slideRight = () => {
		ref.current.scroll({
			left: ref.current.scrollLeft + 70,
			behavior: "smooth",
		});
	};

	return [ref, onMouseDown, onMouseUp, handleMouseMove, slideLeft, slideRight];

	// const refs = useRef(
	// 	Array.from({ length: numRefs }, () => createRef())
	// ).current;

	// useEffect(() => {
	// 	const onWheel = (evt, index) => {
	// 		evt.preventDefault();
	// 		refs[index].current.scrollTo({
	// 			left: refs[index].current.scrollLeft + evt.deltaY * 2,
	// 			behavior: "smooth",
	// 		});
	// 	};

	// 	refs.forEach((ref, index) => {
	// 		ref.current.addEventListener("wheel", (evt) => onWheel(evt, index));

	// 		return () => {
	// 			ref.current.removeEventListener("wheel", (evt) => onWheel(evt, index));
	// 		};
	// 	});
	// }, [refs]);

	// return refs;

	//--------------- extensible drag -----------
	//   export const useHorizontalScroll = (numRefs) => {
	//     const [state, setState] = useState({
	//       isClicked: false,
	//       startX: 0,
	//       scrollX: 0,
	//     });
	//   const refs = useRef(
	// 		Array.from({ length: numRefs }, () => createRef())
	// 	).current;
	// 	console.log(state);
	// 	const onMouseDown = (evt, index) => {
	// 		console.log(state);
	// 		console.log("hola");
	// 		evt.preventDefault();
	// 		setState({
	// 			isClicked: true,
	// 			startX: evt.pageX - refs[index].current.offsetLeft,
	// 			scrollX: refs[index].current.scrollLeft,
	// 		});
	// 	};
	// 	const onMouseUp = (evt) => {
	// 		console.log(state);
	// 		evt.preventDefault();
	// 		console.log("up");
	// 		setState((prevState) => ({
	// 			...prevState,
	// 			isClicked: false,
	// 		}));
	// 	};

	// 	const handleMouseMove = (evt, index) => {
	// 		console.log("state:", state);
	// 		const { startX, scrollX, isClicked } = state;
	// 		if (!isClicked) return;
	// 		console.log("isClicked:", state.isClicked);
	// 		console.log("moveState:", state);
	// 		console.log(!state.isClicked);
	// 		console.log("move after retrn");

	// 		evt.preventDefault();
	// 		if (isClicked) {
	// 			const xMove = evt.pageX - refs[index].current.offsetLeft;
	// 			const deltaX = xMove - startX;
	// 			refs[index].current.scrollLeft = scrollX - deltaX;
	// 		}
	// 	};
	// 	useEffect(() => {
	// 		refs.forEach((ref, index) => {
	// 			ref.current.addEventListener("mousedown", (evt) =>
	// 				onMouseDown(evt, index)
	// 			);
	// 			ref.current.addEventListener("mouseup", (evt) => onMouseUp(evt, index));
	// 			ref.current.addEventListener("mousemove", (evt) =>
	// 				handleMouseMove(evt, index)
	// 			);

	// 			return () => {
	// 				ref.current.removeEventListener("mouseup", (evt) =>
	// 					onMouseUp(evt, index)
	// 				);
	// 				ref.current.removeEventListener("mousedown", (evt) =>
	// 					onMouseDown(evt, index)
	// 				);
	// 				ref.current.removeEventListener("mousemove", (evt) =>
	// 					handleMouseMove(evt, index)
	// 				);
	// 			};
	// 		});
	// 	}, [refs]);
};
