import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "./ItemCount.css";

const ItemCount = ({
	component,
	initial,
	sum = null,
	addToComponent = null,
	removeToComponent = null,
	removeByComponentId = null,
	handleProductsToAddToCart = null,
	isInCart = false,
}) => {
	const [count, setCount] = useState(initial);
	console.log("ItemCount");
	const add = () => setCount((count) => count + 1);

	const remove = () => (count >= 1 ? setCount((count) => count - 1) : null);

	useEffect(() => {
		if (handleProductsToAddToCart && initial !== count) {
			handleProductsToAddToCart(component.idComponent, count);
		}
		console.log({ initial });
		console.log({ count });
		if (count !== initial && isInCart) {
			if (count === 0) {
				removeByComponentId({
					idProduct: component.idProduct,
					idComponent: component.idComponent,
				});
			} else if (count > initial) {
				console.log("Hola");
				addToComponent(component.idProduct, component.idComponent, count).then(
					(result) => {
						if (result) {
							setCount((prevCount) => prevCount - 1);
						}
					}
				);
			} else {
				removeToComponent(component.idProduct, component.idComponent, count);
			}
		}
	}, [
		count,
		component.idComponent,
		handleProductsToAddToCart,
		initial,
		isInCart,
		removeByComponentId,
		sum,
		component.idProduct,
		addToComponent,
		removeToComponent,
	]);

	return (
		<Box className="itemcountContainer">
			<IconButton aria-label="Disminuir el contador" onClick={remove}>
				<RemoveIcon />
			</IconButton>
			<Box>
				<Typography variant="subtitle1" color="initial">
					{count}
				</Typography>
			</Box>
			<IconButton aria-label="Aumentar el contador" onClick={add}>
				<AddIcon />
			</IconButton>
		</Box>
	);
};

export default ItemCount;
