import SizesAndColors from "../../components/SizesAndColors/SizesAndColors.jsx";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import products from '../../assets/products';
import { useState } from 'react';

function SizesAndColorsContainer(){

    const {idProduct} = useParams()

    const [item, setItem]=useState({})

    useEffect(()=>{
        products
        .then(response=>setItem(response.find(itemSizesAndColor=>itemSizesAndColor.id === parseInt(idProduct))))
    })

    //array de colores con sus detalles
    const sizesByColor=[];

    item.components?.forEach(item=>{if(!sizesByColor.find(element=>element.color === item.color )){   
        sizesByColor.push({color: item.color,
                            types:[{id: item.id, size: item.size, stock: item.stock, quantity: item.quantity}]
        });
    }else{
        const index=sizesByColor.findIndex(element=>element.color === item.color);
        const newType = [
        ...sizesByColor[index].types,
        {id: item.id, size: item.size, stock: item.stock, quantity: item.quantity},
        ];
        sizesByColor[index].types = newType;
        }
    })
    
    const [detailColor, setDetailColor]=useState({})
    const [selectColor, setSelectColor]=useState(true)

    function handleClickColor(color){
        //setea el array de colores, y el objeto que coincida con el color presionado lo trae
        //para luego mapearlo y arrojar las medidas, con sus stocks.  
        setDetailColor(sizesByColor.find(itemDetailVariety=>(itemDetailVariety.color === color)))
        //cambia el titulo 'Seleccione un color' por el detalle del color que se presione
        setSelectColor()
    };

    return(
        <div>
            <SizesAndColors item={item} 
                            sizesByColor={sizesByColor}
                            handleClickColor={handleClickColor}
                            selectColor={selectColor}
                            detailColor={detailColor}
            />
        </div>
    )
}

export default SizesAndColorsContainer;
