import { Fragment } from "react";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Step,
	StepLabel,
	Stepper,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { formatter } from "../../utils/auxFunc";
import { useDiscount } from "../../context/Discount/DiscountContext";

const steps = ["ENVÍO", "PAGO", "RESUMEN"];

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiStepConnector-root": {
			top: "18px",
			left: "calc(-50% + 25px)",
			right: "calc(50% + 25px)",
		},
		"& .MuiSvgIcon-root.MuiStepIcon-root": { width: "1.4em", height: "1.4em" },
		"& .MuiStepIcon-root.Mui-active": {
			color: "#292929",
			border: "1px solid #292929",
			borderRadius: "50%",
			"& .MuiStepIcon-text": {
				fill: "#FAFAFA",
			},
		},
		"& .MuiStepIcon-root.Mui-completed": {
			color: "#9b9797",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
		"& .Mui-disabled .MuiStepIcon-root": {
			color: "#FAFAFA",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
	},
}));

const CheckoutRadio = styled(Radio)(({ theme }) => ({
	"&.Mui-checked": {
		color: theme.palette.secondary.main,
	},
}));

// const ExpandMore = styled((props) => {
// 	const { expand, ...other } = props;
// 	return <IconButton {...other} />;
// })(({ theme, expand }) => ({
// 	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
// 	padding: "0 0.5rem",
// 	transition: theme.transitions.create("transform", {
// 		duration: theme.transitions.duration.shortest,
// 	}),
// }));

const PaymentInfo = ({
	userData,
	timer,
	closeCheckout,
	shipmentForm,
	paymentForm,
	setPaymentForm,
	getTotalAmount,
	activeStep,
	handleBack,
	handleNext,
}) => {
	const { payConditionDiscount } = useDiscount();
	const classes = useStyles();

	const handleRadio = (evt) => {
		setPaymentForm(JSON.parse(evt.target.value));
	};

	const paymentMethods = userData.paymentConditionOptions;

	return (
		<Fragment>
			<Box sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}>
				<Container maxWidth="lg">
					<Box display="flex" alignItems="center" minHeight="56px">
						<Box>
							<Typography
								variant="subtitle1"
								color="primary"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								{timer}
							</Typography>
						</Box>
						<Typography
							variant="h5"
							color="primary"
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexGrow={1}
						>
							Checkout
						</Typography>
						<Box>
							<Tooltip title="Carrito">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
									onClick={closeCheckout}
								>
									<CloseIcon color="primary" sx={{ fontSize: "2rem" }} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Container>
			</Box>
			<Box
				minHeight="calc(100vh - 64px)"
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Container
					sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
				>
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						sx={{ padding: "1.5rem 0" }}
						className={classes.root}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Método de pago:
					</Typography>
				</Container>

				<Container
					sx={{
						marginTop: "1rem",
						marginBottom: "1rem",
						backgroundColor: "#FFF",
					}}
				>
					<FormControl fullWidth>
						<RadioGroup
							aria-labelledby="Selección método de pago"
							defaultValue=""
							name="Método de pago"
							onChange={handleRadio}
						>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								key={userData.payConditionId}
							>
								<AccountBalanceWalletOutlinedIcon />
								<FormControlLabel
									value={JSON.stringify({
										paymentMethod: userData.payConditionId,
										paymentDescription: userData.payConditionDescription,
										payConditionDiscount: userData.payConditionDiscount,
									})}
									checked={
										paymentForm.paymentMethod === userData.payConditionId
									}
									control={
										<CheckoutRadio
											sx={{
												"&.Mui-checked": {
													color: "#292929",
												},
											}}
										/>
									}
									label={userData.payConditionDescription}
									labelPlacement="start"
									sx={{
										flex: 1,
										display: "flex",
										justifyContent: "space-between",
										marginLeft: ".5rem",
									}}
								/>
							</Box>
							{paymentMethods.map((method) => {
								return (
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										key={method.idPaymentCondition}
									>
										<AccountBalanceWalletOutlinedIcon />
										<FormControlLabel
											value={JSON.stringify({
												paymentMethod: method.idPaymentCondition,
												paymentDescription: method.description,
												payConditionDiscount: method.discount,
											})}
											checked={
												paymentForm.paymentMethod === method.idPaymentCondition
											}
											control={
												<CheckoutRadio
													sx={{
														"&.Mui-checked": {
															color: "#292929",
														},
													}}
												/>
											}
											label={method.description}
											labelPlacement="start"
											sx={{
												flex: 1,
												display: "flex",
												justifyContent: "space-between",
												marginLeft: ".5rem",
											}}
										/>
									</Box>
								);
							})}
						</RadioGroup>
					</FormControl>
				</Container>
				<Container
					sx={{
						backgroundColor: (theme) => theme.palette.lightBg.main,
						paddingTop: "12rem",
					}}
				></Container>
				<Box
					py={2}
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						width: "100%",
						backgroundColor: "#FFF",
					}}
				>
					<Container>
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Subtotal:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								{formatter.format(
									getTotalAmount() / (1 - payConditionDiscount)
								)}
							</Typography>
						</Box>

						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Descuento:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								-
								{formatter.format(
									(getTotalAmount() / (1 - payConditionDiscount)) *
										payConditionDiscount
								)}
							</Typography>
						</Box>

						{shipmentForm.shipmentOption === "domicilio" && (
							<Box display="flex" justifyContent="space-between">
								<Typography
									variant="subtitle1"
									sx={{
										fontSize: "1rem",
									}}
								>
									Envío:
								</Typography>
								<Typography
									variant="subtitle1"
									sx={{
										fontSize: "1rem",
									}}
								>
									Bonificado hasta el transporte
								</Typography>
							</Box>
						)}
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
									fontWeight: "600",
								}}
							>
								Total:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
									fontWeight: "600",
								}}
							>
								{formatter.format(getTotalAmount())}
							</Typography>
						</Box>
					</Container>
					<Container
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "1rem",
							paddingBottom: "1.5rem",
						}}
					>
						<Button
							color="secondary"
							variant="outlined"
							sx={{
								width: "45%",
								textTransform: "capitalize",
								fontSize: "1rem",
							}}
							onClick={handleBack}
						>
							Anterior
						</Button>
						<Button
							color="secondary"
							variant="contained"
							disableElevation
							sx={{
								width: "45%",
								textTransform: "capitalize",
								fontSize: "1rem",
							}}
							onClick={handleNext}
						>
							Siguiente
						</Button>
					</Container>
				</Box>
			</Box>
		</Fragment>
	);
};

export default PaymentInfo;
