import { Fragment, useEffect, useState } from "react";
import {
	Box,
	Button,
	Container,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../../context/Cart/CartContext";

const OrdersContainer = () => {
	const [showOrders, setShowOrders] = useState([true, false]);
	const { cart } = useCart();
	const url = useLocation();
	const navigate = useNavigate();

	const handleReturn = () => {
		navigate("/user-account");
	};

	const toggleOrders = (e) => {
		if (url.pathname.includes(e.target.name)) {
			return;
		} else {
			//si cliqueo boton de pendientes, seteo el estado 0 en true para aplicar estilos
			e.target.name === "pending"
				? setShowOrders([true, false])
				: setShowOrders([false, true]);
			navigate(`/user-account/orders/${e.target.name}`);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Container>
				<Box display="flex" alignItems="center" minHeight="56px">
					<Box>
						<Tooltip title="Volver">
							<IconButton
								size="large"
								aria-label="volver"
								aria-controls="volver-carrito"
								aria-haspopup="false"
								color="inherit"
								onClick={handleReturn}
							>
								<ArrowBackIosIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Typography
						variant="h5"
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexGrow={1}
					>
						Pedidos
					</Typography>
					<Box>
						<Tooltip title="Carrito">
							<Link to="/cart">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
								>
									{cart?.length > 0 ? (
										<ShoppingBagIcon color="secondary" />
									) : (
										<ShoppingBagOutlinedIcon color="secondary" />
									)}
								</IconButton>
							</Link>
						</Tooltip>
					</Box>
				</Box>
			</Container>
			<Divider />
			<Container sx={{ marginBottom: "3rem" }}>
				<Box display="flex" justifyContent="space-around">
					<Button
						color="secondary"
						onClick={toggleOrders}
						disableElevation
						name="pending"
						sx={{
							textTransform: "none",
							fontSize: "1rem",
							fontWeight: showOrders[0] ? "600" : "400",
							padding: "12px 6px",
							borderRadius: 0,
							borderBottom: showOrders[0]
								? "1px solid black"
								: "1px solid transparent",
						}}
					>
						Pedidos pendientes
					</Button>
					<Button
						color="secondary"
						onClick={toggleOrders}
						disableElevation
						name="closed"
						sx={{
							textTransform: "none",
							fontSize: "1rem",
							fontWeight: showOrders[1] ? "600" : "400",
							padding: "12px 6px",
							borderRadius: 0,
							borderBottom: showOrders[1]
								? "1px solid black"
								: "1px solid transparent",
						}}
					>
						Pedidos anteriores
					</Button>
				</Box>
				<Divider />
				<Outlet />
			</Container>
		</Fragment>
	);
};

export default OrdersContainer;
