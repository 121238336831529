import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider} from '@mui/material';
import './SizesAndColors.css'
import ItemCount from '../ItemCount';


const themeBtn = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',//color del boton y color del borde en este caso
        },
    },
});

const theme = createTheme();

theme.typography.body1 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "1rem",
	},
};

theme.typography.h3 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "1rem",
	},
};

function SizesAndColors({item, sizesByColor, handleClickColor, selectColor, detailColor}){

    return(
        <div>
            <center>
            <Card className='sizeCardContainer' sx={{ maxWidth: 345 }}>
                <CardContent>
                    <ThemeProvider theme={theme} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                            <Typography  
                                variant="h3" 
                                className='typographySizesAndColors' 
                                style={{fontFamily: 'Quicksand' }}
                            >
                                {item.name}
                            </Typography>
                            <Typography  
                                variant="h3" 
                                className='typographySizesAndColors' 
                                style={{fontFamily: 'Quicksand'}}
                            >
                                $ {item.price}
                            </Typography>
                        </Box>
                    </ThemeProvider>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {sizesByColor.map((arrayProduct) =>(<div key={arrayProduct.color}>
                                <button
                                    onClick={()=>{handleClickColor(arrayProduct.color)}} 
                                    className="sizeBtnColor" 
                                    style={{background:`${arrayProduct.color}`}}>
                                </button>
                                
                                <p className='sizeTextColor'>{arrayProduct.color}</p>
                            </div>))}
                        </Box>
                        {selectColor ? (<p className='sizeTextSelectColor'>Seleccione un color</p>) : (
                            <Box>
                                {detailColor.types?.map((item=>(<Box key={item.id} sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    p: 1,
                                                                    m: 1,
                                                                    bgcolor: 'background.paper',
                                                                    borderRadius: 1,
                                                                }}>
                                                            <p className='sizeText'>{item.size}</p>
                                                            <p className='sizeTextStock'>Stock: {item.stock}</p>
                                                            <ItemCount initial={item.quantity} />
                                                            </Box>
                                                            )))}
                            </Box> 
                            )
                        } 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                            <p className='sizeTextTotal'>Talles:</p>
                            <p className='sizeTextTotal'>Precio unitario:</p>
                        </Box> 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                            <p className='sizeTextTotal'>Unidades:</p>
                            <p className='sizeTextTotal'>Subtotal:</p>
                        </Box>                        
                    <ThemeProvider theme={themeBtn}>
                        <Button 
                            fullWidth 
                            className='sizeBtnAdd' 
                            style={{background: '#000000', textTransform: 'capitalize', fontWeight:'bold'}} 
                        >
                            Agregar
                        </Button>
                    </ThemeProvider>
                </CardContent>
            </Card>
        </center>
        </div>
    )
}

export default SizesAndColors;