import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderDetail from "../../components/OrderDetail";
import { useAuth } from "../../context/Auth/AuthContext";
import { api } from "../../services/api";

const OrderDetailContainer = () => {
	const [order, setOrder] = useState(null);
	const { orderId } = useParams();
	const { userData } = useAuth();

	useEffect(() => {
		api.orders
			.getOrderById(userData.clientId, orderId)
			.then((response) => response.json())
			.catch((err) => console.log(err))
			.then((orderAPI) => {
				setOrder(orderAPI);
			})
			.catch((err) => console.log(err));
	}, [orderId, userData.clientId]);

	return <OrderDetail order={order} />;
};

export default OrderDetailContainer;
