import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/Auth/AuthContext";

const PublicRoutes = ({ children }) => {
	const { auth } = useAuth();

	return auth ? <Navigate to="/" replace={true} /> : children;
};

export default PublicRoutes;
