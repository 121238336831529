import { Button } from "@mui/material";
import React from "react";

export const FilterButton = ({
	content = null,
	colorOption = null,
	isCalceButton = false,
}) => {
	return !isCalceButton ? (
		<Button
			variant="contained"
			color="primary"
			disableElevation
			sx={{
				width: "3rem",
				height: "3rem",
				border: "1px solid rgb(201, 201, 201)",
				borderRadius: "50px",
				minWidth: "0",
			}}
			style={{ backgroundColor: `${colorOption}` }}
		>
			{content}
		</Button>
	) : (
		<Button
			variant="contained"
			color="primary"
			disableElevation
			sx={{
				width: "auto",
				height: "2rem",
				border: "1px solid rgb(201, 201, 201)",
				borderRadius: "5px",
				minWidth: "0",
			}}
			style={{ backgroundColor: `${colorOption}` }}
		>
			{content}
		</Button>
	);
};
