export class Product {
	constructor(idProduct, name, img, description, price, category) {
		this.idProduct = idProduct;
		this.name = name;
		this.img = img;
		this.description = description;
		this.price = price;
		this.category = category;
		//Coleccion de compomentes
		this.components = [];
	}
	getTotalComponents = () => {
		this.components.reduce((acc, component) => acc + component.quantity, 0);
	};
	getTotalAmount = () => {
		return this.getTotalComponents * this.price;
	};
	getTotalByComponent = (idComponent) => {
		return 0;
	};
}

export class Component {
	constructor(
		idComponent,
		idProduct,
		idColor,
		idSize,
		color,
		colorHexa,
		size,
		stock,
		quantity,
		name,
		images
	) {
		this.idComponent = idComponent;
		this.idProduct = idProduct;
		this.idColor = idColor;
		this.idSize = idSize;
		this.descriptionColor = color;
		this.colorHexa = colorHexa;
		this.descriptionSize = size;
		this.stock = stock;
		this.quantity = quantity;
		this.name = name;
		this.imgComponent = images;
	}
}
