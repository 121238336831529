import { Fragment } from "react";
import {
	Box,
	Container,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router-dom";
import ProductsDetailOrder from "../ProductsDetailOrder";
import {
	formatter,
	getMonth,
	getOrderDate,
	getOrderTotalAmount,
} from "../../utils/auxFunc";
import { monthNames } from "../../assets/constants";

const OrderDetail = ({ order }) => {
	const navigate = useNavigate();

	const handleNavCart = () => {
		navigate("/cart");
	};

	const handleReturn = () => {
		navigate(-1);
	};

	const date = new Date(order?.orderDate);
	const day = getOrderDate(date);
	const month = monthNames[getMonth(date)];
	const amount = order && getOrderTotalAmount(order?.orderItems);
	const subtotal = amount / (1 - order?.discount / 100);
	const discount = order?.discount && (order?.discount * subtotal) / 100;

	return (
		<Fragment>
			<Container>
				<Box display="flex" alignItems="center" minHeight="56px">
					<Box>
						<Tooltip title="Volver">
							<IconButton
								size="large"
								aria-label="volver"
								aria-controls="volver-carrito"
								aria-haspopup="false"
								color="inherit"
								onClick={handleReturn}
							>
								<ArrowBackIosIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Typography
						variant="h5"
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexGrow={1}
					>
						Pedido pendiente
					</Typography>
					<Box>
						<Tooltip title="Carrito">
							<IconButton
								size="large"
								aria-label="carrito"
								aria-controls="logo-carrito"
								aria-haspopup="false"
								color="inherit"
								onClick={handleNavCart}
							>
								<ShoppingBagOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</Container>
			<Divider />
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="space-between" mt={2} mb={1}>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{`${day} de ${month}`}
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{`Nº ${order?.idOrder}`}
					</Typography>
				</Box>
				<Divider />
				<Box display="flex" flexDirection="column" alignItems="start" mt={2}>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
							color: (theme) => theme.palette.light.main,
						}}
					>
						Enviado a:
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{order?.name}
					</Typography>
				</Box>
				{order?.address !== "" && (
					<Box display="flex" flexDirection="column" alignItems="start" mt={2}>
						<Typography
							variant="subtitle2"
							sx={{
								fontSize: "1rem",
								color: (theme) => theme.palette.light.main,
							}}
						>
							Dirección de envío:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							{order?.address}
						</Typography>
					</Box>
				)}
				<Box display="flex" flexDirection="column" alignItems="start" mt={2}>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
							color: (theme) => theme.palette.light.main,
						}}
					>
						Forma de pago:
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{order?.paymentMethod}
					</Typography>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="start"
					mt={2}
					mb={3}
				>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
							color: (theme) => theme.palette.light.main,
						}}
					>
						Estado:
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							color: order?.isPaid ? "#4DBAA2" : "#E80025",
							fontSize: "1rem",
						}}
					>
						{order?.state}
					</Typography>
				</Box>
			</Container>
			<Box py={3} mb={4} sx={{ backgroundColor: "#F5F6F7" }}>
				<Container>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Subtotal:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							{formatter.format(subtotal)}
						</Typography>
					</Box>
					{discount !== 0 && (
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Descuento:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								{`-${formatter.format(discount)}`}
							</Typography>
						</Box>
					)}
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							Total:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							{discount === 0
								? formatter.format(amount)
								: formatter.format(subtotal - discount)}
						</Typography>
					</Box>
				</Container>
			</Box>
			<Container sx={{ marginBottom: "3rem" }}>
				<Typography
					variant="subtitle1"
					sx={{
						fontSize: "1rem",
						fontWeight: "600",
					}}
				>
					Detalle de la orden
				</Typography>
				{order?.orderItems?.map((product) => (
					<ProductsDetailOrder
						key={product.product.idProduct}
						product={product}
						discount={order?.discount / 100}
					/>
				))}
			</Container>
		</Fragment>
	);
};

export default OrderDetail;
