import { createContext, useContext, useEffect, useState } from "react";
import { useProducts } from "../Products/ProductsContext";
import { Color } from "../../entities/color";
import { Size } from "../../entities/size";
import { Fit } from "../../entities/fit";
import { api } from "../../services/api";

const FilterContext = createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterContextProvider = ({ children }) => {
	const [colors, setColors] = useState();
	const [sizeLetter, setSizeLetter] = useState();
	const [fits, setFits] = useState();
	const [filter, setFilter] = useState();
	const [filterAPI, setFilterAPI] = useState(null);
	const { setProductCategory } = useProducts();

	const toggleSizeLettersCheckboxes = (evt, index) => {
		const newSizeLetters = [...filter.sizeLetter];
		newSizeLetters.fill({ isChecked: false });
		const isChecked = filter.sizeLetter[index].isChecked;
		newSizeLetters[index] = !isChecked // cuando el valor inicial sea false, seteartlo a true y agregar el talle
			? { isChecked: true, sizeLetter: evt.target.value }
			: { isChecked: false };
		setFilter({ ...filter, sizeLetter: newSizeLetters });
	};

	const toggleColorsCheckboxes = (evt, index) => {
		const newColor = [...filter?.color];
		newColor.fill({ isChecked: false });
		const isChecked = filter?.color[index].isChecked;
		newColor[index] = !isChecked // cuando el valor inicial sea false, seteartlo a true y agregar el color
			? { isChecked: true, color: evt.target.value }
			: { isChecked: false };
		setFilter({ ...filter, color: newColor });
	};

	const toggleFitsCheckboxes = (evt, index) => {
		const newFits = [...filter.fit];
		newFits.fill({ isChecked: false });
		const isChecked = filter.fit[index].isChecked;
		newFits[index] = !isChecked // cuando el valor inicial sea false, seteartlo a true y agregar el calce
			? { isChecked: true, fit: evt.target.value }
			: { isChecked: false };
		setFilter({ ...filter, fit: newFits });
	};

	const handleSliderChange = (evt, newValue) => {
		setFilter({ ...filter, price: newValue });
	};

	const applyFilter = () => {
		const minFilter = { ...filter };
		for (const prop in minFilter) {
			if (prop !== "price") {
				minFilter[prop] = minFilter[prop].find(
					(element) => element.isChecked === true
				);
			}
		}
		setFilterAPI(minFilter);
		setProductCategory();
	};

	const resetFilter = () => {
		setFilter({
			sizeLetter: Array(sizeLetter?.length).fill({ isChecked: false }),
			color: Array(colors?.length).fill({ isChecked: false }),
			fit: Array(fits?.length).fill({ isChecked: false }),
			price: [100, 5000],
		});
		setFilterAPI(null);
		setProductCategory();
	};

	useEffect(() => {
		api.products
			.listColors()
			.then((res) => res.json())
			.then((colors) => {
				const allColors = [];
				colors.map(({ id, descripcion, hex }) =>
					allColors.push(new Color(id, descripcion, hex))
				);
				setColors(allColors);
			})
			.catch((err) => console.log("Error: ", err));

		api.products
			.listSizes()
			.then((res) => res.json())
			.then((sizes) => {
				const allSizes = [];
				sizes.map(({ id, descripcion, shortDescripcion }) =>
					allSizes.push(new Size(id, descripcion, shortDescripcion))
				);
				setSizeLetter(allSizes);
			})
			.catch((err) => console.log("Error: ", err));

		api.products
			.listFits()
			.then((res) => res.json())
			.then((fits) => {
				const allFits = [];
				fits.map(({ id, descripcion }) =>
					allFits.push(new Fit(id, descripcion))
				);
				setFits(allFits);
			})
			.catch((err) => console.log("Error: ", err));

		setFilter({
			sizeLetter: Array(sizeLetter?.length).fill({ isChecked: false }),
			color: Array(colors?.length).fill({ isChecked: false }),
			fit: Array(fits?.length).fill({ isChecked: false }),
			price: [100, 20000],
		});
	}, [colors?.length, sizeLetter?.length, fits?.length]);

	return (
		<FilterContext.Provider
			value={{
				filter,
				filterAPI,
				setFilterAPI,
				sizeLetter,
				colors,
				fits,
				toggleSizeLettersCheckboxes,
				toggleColorsCheckboxes,
				toggleFitsCheckboxes,
				handleSliderChange,
				applyFilter,
				resetFilter,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export default FilterContext;
