import { Fragment } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { monthNames } from "../../assets/constants";
import {
	formatter,
	getMonth,
	getOrderDate,
	getOrderTotalAmount,
} from "../../utils/auxFunc";

const OrderItem = ({
	orderDate,
	state,
	orderItems,
	withdrawalPoint = null,
	orderId = null,
	isPaid,
	isInClosed = false,
}) => {
	const date = new Date(orderDate);
	const amount = getOrderTotalAmount(orderItems);
	const day = getOrderDate(date);
	const month = monthNames[getMonth(date)];

	return (
		<Fragment>
			<Box display="flex" justifyContent="space-between" width="100%">
				<Box display="flex" flexDirection="column" alignItems="start">
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{`${day} de ${month}`}
					</Typography>
					{isInClosed ? (
						<Typography
							variant="subtitle2"
							sx={{
								color: (theme) => theme.palette.light.main,
								fontSize: "1rem",
							}}
						>
							{state}
						</Typography>
					) : (
						<Typography
							variant="subtitle2"
							sx={{
								color: isPaid ? "#4DBAA2" : "#E80025",
								fontSize: "1rem",
							}}
						>
							{state}
						</Typography>
					)}
				</Box>
				<Box textAlign="right">
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{formatter.format(amount)}
					</Typography>
					{isInClosed ? (
						<Typography
							variant="subtitle2"
							sx={{
								fontSize: "1rem",
								color: (theme) => theme.palette.light.main,
							}}
						>
							Nº {orderId}
						</Typography>
					) : (
						<Typography
							variant="subtitle2"
							sx={{
								fontSize: "1rem",
								color: (theme) => theme.palette.light.main,
							}}
						>
							{withdrawalPoint}
						</Typography>
					)}
				</Box>
			</Box>
			<Divider />
		</Fragment>
	);
};

export default OrderItem;
