import React, { useEffect, useState } from "react";
import Counter from "../../components/Counter/Counter";

const CounterCartContainer = ({
	item,
	initial,
	addToComponent,
	removeByComponentId,
	removeToComponent,
	resetCounter,
}) => {
	const [count, setCount] = useState(initial);

	const increment = () => {
		setCount((prevCount) => prevCount + 1);
	};

	const decrement = () => {
		if (count > 0) {
			setCount((prevCount) => prevCount - 1);
		}
	};

	useEffect(() => {
		if (resetCounter) setCount(0);
	}, [resetCounter]);

	useEffect(() => {
		if (count === 0) {
			removeByComponentId({
				idProduct: item.idProduct,
				idComponent: item.idComponent,
			});
			return;
		}

		if (count !== initial) {
			count > initial
				? addToComponent(item.idProduct, item.idComponent, count).then(
						(result) => {
							if (result) {
								setCount((prevCount) => prevCount - 1);
							}
						}
				  )
				: removeToComponent(item.idProduct, item.idComponent, count);
		}
	}, [
		addToComponent,
		count,
		initial,
		item.idComponent,
		item.idProduct,
		removeByComponentId,
		removeToComponent,
	]);

	return (
		<Counter value={count} onIncrement={increment} onDecrement={decrement} />
	);
};

export default CounterCartContainer;
