import { Fragment, useState } from "react";
import {
	Box,
	Divider,
	Typography,
	Container,
	IconButton,
	Tooltip,
	createTheme,
	ThemeProvider,
	Radio,
	Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link, useNavigate } from "react-router-dom";

const themeBtn = createTheme({
	palette: {
		primary: {
			main: "#000000", //color del btn y color del borde en este caso
		},
	},
});

const payMethods = [
	{ id: 1, name: "transferencia", icon: <CreditCardOutlinedIcon /> },
	{
		id: 2,
		name: "efectivo - 9% OFF",
		icon: <AccountBalanceWalletOutlinedIcon />,
	},
];

function PayMethods({ productsInCart }) {
	const navigate = useNavigate();
	const [payMethod, setPayMethod] = useState();
	const [selectedPayMethod, setSelectedPayMethod] = useState({});

	function payMethodSelect(id) {
		//setea el numero del id que se clickea
		setPayMethod(id);
	}

	const [show, setShow] = useState(false);

	function handleHidde(id) {
		//busca en la matriz, el objeto en donde coincida
		//el id ingresado con el id del objeto de la matriz y lo setea
		setSelectedPayMethod(payMethods.find((objectId) => id === objectId.id));
		setShow(true);
	}

	function handleShow() {
		setShow(false);
	}

	return (
		<Fragment>
			<Container>
				<Box display="flex" alignItems="center" minHeight="56px">
					<Box>
						<Tooltip title="Volver">
							<IconButton
								size="large"
								aria-label="volver"
								aria-controls="volver-carrito"
								aria-haspopup="false"
								color="inherit"
								onClick={() => navigate(-1)}
							>
								<ArrowBackIosIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Typography
						variant="h5"
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexGrow={1}
					>
						Formas de pago
					</Typography>
					<Box>
						<Tooltip title="Carrito">
							<Link to="/cart">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
								>
									{productsInCart > 0 ? (
										<ShoppingBagIcon color="secondary" />
									) : (
										<ShoppingBagOutlinedIcon color="secondary" />
									)}
								</IconButton>
							</Link>
						</Tooltip>
					</Box>
				</Box>
			</Container>

			<Divider />

			<Container>
				<Box className="boxAddressShippingContainer" my={1} pt={2}>
					<Typography className="titleFirstRow" sx={{ fontWeight: "600" }}>
						Métodos de pago
					</Typography>
					<Typography onClick={handleShow}>Editar</Typography>
				</Box>

				<Divider />

				{payMethods.map((arraypayMethods) => (
					<div key={arraypayMethods.id}>
						<Box className="boxAddressShippingContainer" my={1}>
							<Box className="boxIconAndAddressContainer">
								<Box className="boxIconLocation">
									<div style={{ color: "#000000" }} sx={{ fontSize: 32 }}>
										{arraypayMethods.icon}
									</div>
								</Box>
								<Box className="boxAddressContainer">
									<Box className="addressNameAndSelectContainer">
										<Box className="boxNameAddress">
											<Typography
												variant="subtitle1"
												className="textName"
												sx={{ fontSize: "1rem" }}
												style={{ paddingTop: 8, paddingBottom: 8 }}
											>
												{arraypayMethods.name}
											</Typography>
										</Box>
										<Box className="boxTextDefault">
											{selectedPayMethod?.id === arraypayMethods.id ? (
												<Typography
													className="textDefault"
													sx={{ fontWeight: "600", fontSize: "0.76rem" }}
												>
													predeterminada
												</Typography>
											) : (
												""
											)}
										</Box>
									</Box>
								</Box>
							</Box>
							<Box>
								{show ? (
									""
								) : (
									<Radio
										checked={payMethod === arraypayMethods.id}
										onClick={() => payMethodSelect(arraypayMethods.id)}
										value={arraypayMethods.id}
										name="radio-buttons"
										style={{ color: "#000000" }}
									/>
								)}
							</Box>
						</Box>

						<Divider />
					</div>
				))}

				<ThemeProvider theme={themeBtn}>
					<Box className="boxBtnContainer">
						{show ? (
							""
						) : (
							<Button
								className="btnAddressShipping"
								variant="outlined"
								type="submit"
								sx={{
									fontSize: "1rem",
									textAlign: "center",
									textTransform: "capitalize",
									paddingLeft: 5,
									paddingRight: 5,
								}}
								onClick={() => handleHidde(payMethod)}
							>
								guardar pago predeterminado
							</Button>
						)}
					</Box>
				</ThemeProvider>
			</Container>
		</Fragment>
	);
}

export default PayMethods;
