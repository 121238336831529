import { Box, Tooltip, Fade, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./FilterWidget.css";

const FilterWidget = ({ toggleFilterMenu, toggleSortMenu }) => {
	return (
		<Box className="categorySettings">
			<Tooltip
				title="Ordenar"
				placement="bottom"
				TransitionComponent={Fade}
				TransitionProps={{ timeout: 600 }}
			>
				<Button
					color="secondary"
					startIcon={<SwapVertIcon />}
					onClick={toggleSortMenu}
				>
					<Typography
						sx={{
							fontSize: "0.8rem",
							textAlign: "left",
							textTransform: "uppercase",
						}}
						variant="body1"
						style={{ fontFamily: "Quicksand" }}
					>
						Ordenar por
					</Typography>
				</Button>
			</Tooltip>
			<Tooltip
				title="Filtro"
				placement="bottom"
				TransitionComponent={Fade}
				TransitionProps={{ timeout: 600 }}
			>
				<Button
					color="secondary"
					startIcon={<FilterListIcon />}
					onClick={toggleFilterMenu}
				>
					<Typography
						sx={{
							fontSize: "0.8rem",
							textAlign: "left",
							textTransform: "uppercase",
						}}
						variant="body1"
						style={{ fontFamily: "Quicksand" }}
					>
						Filtrar
					</Typography>
				</Button>
			</Tooltip>
		</Box>
	);
};

export default FilterWidget;
