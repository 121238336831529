import { Fragment, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { formatter } from "../../utils/auxFunc";

const ProductsDetailOrder = ({ product, discount }) => {
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Fragment>
			<Divider />
			<Box display="flex" justifyContent="space-between" width="100%" my={2}>
				<Box display="flex" flexDirection="column" alignItems="start">
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
							textTransform: "capitalize",
						}}
					>
						{product?.product.name}
					</Typography>
					<Box
						onClick={handleExpandClick}
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<Typography
							variant="subtitle1"
							sx={{
								color: (theme) => theme.palette.light.main,
								fontSize: "1rem",
								lineHeight: 1.25,
								textTransform: "capitalize",
							}}
							color="secondary"
						>
							{product?.quantity > 1
								? `${product?.quantity} Artículos`
								: `${product?.quantity} Artículo`}
						</Typography>
					</Box>
				</Box>
				<Box textAlign="right">
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1rem",
						}}
					>
						{formatter.format(product?.quantity * product?.price)}
					</Typography>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
							color: (theme) => theme.palette.light.main,
						}}
					>
						Uni. s/desc {formatter.format(product.price / (1 - discount))}
					</Typography>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
							color: (theme) => theme.palette.light.main,
						}}
					>
						Uni. c/desc {formatter.format(product.price)}
					</Typography>
				</Box>
			</Box>
		</Fragment>
	);
};

export default ProductsDetailOrder;
