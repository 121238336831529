import { Fragment, useState } from "react";
import {
	Box,
	Button,
	Card,
	CardMedia,
	Collapse,
	Grid,
	IconButton,
	styled,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	TableHead,
	TableCell,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDiscount } from "../../context/Discount/DiscountContext";
import CounterCartContainer from "../../containers/CounterCartContainer/CounterCartContainer";
import {
	formatter,
	getQuantityByProduct,
	getSizesByColor,
} from "../../utils/auxFunc";

const CartTableCell = withStyles({
	root: {
		border: 0,
	},
})(TableCell);

const ActionsButtons = styled(Button)({
	padding: "0",
	minHeight: "24px",
});

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	padding: "0 0.5rem",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

const CartItemList = ({
	product,
	removeByProductId,
	removeByComponentId,
	addToComponent,
	removeToComponent,
}) => {
	const { payConditionDiscount } = useDiscount();
	const [expanded, setExpanded] = useState(false);

	const sizeAndQuantityByColor = getSizesByColor(product);

	const quantity = getQuantityByProduct(sizeAndQuantityByColor);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<Box maxWidth="sm" margin="0 auto 2rem">
				<Grid container columnSpacing={1} my={2}>
					<Grid item xs={3} lg={2}>
						<Card sx={{ width: "100%", borderRadius: "5px" }}>
							<CardMedia
								component="img"
								alt={product.name}
								image={product?.img} // modificar a product.img
								pt={0}
							></CardMedia>
						</Card>
					</Grid>
					<Grid
						item
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						xs={6}
						lg={8}
					>
						<Box>
							<Typography
								variant="subtitle1"
								sx={{
									textTransform: "capitalize",
									lineHeight: 1.25,
									my: 0.5,
								}}
							>
								{product.name}
							</Typography>
							<Typography variant="caption">{quantity} artículos</Typography>
						</Box>
						<Box
							onClick={handleExpandClick}
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{
									textTransform: "capitalize",
									lineHeight: 1.25,
								}}
								color="secondary"
							>
								Editar
							</Typography>
							<ExpandMore
								expand={expanded}
								aria-expanded={expanded}
								aria-label="editar compra"
							>
								<ExpandMoreIcon />
							</ExpandMore>
						</Box>
					</Grid>
					<Grid
						item
						display="flex"
						flexDirection="column"
						alignItems="flex-end"
						justifyContent="space-between"
						xs={3}
						lg={2}
					>
						<Box>
							<Typography
								variant="subtitle1"
								sx={{
									textTransform: "capitalize",
									lineHeight: 1.25,
									my: 0.5,
									textAlign: "end",
								}}
							>
								{formatter.format(
									quantity * product.price * (1 - payConditionDiscount)
								)}
							</Typography>
							<Typography
								variant="caption"
								sx={{
									textTransform: "capitalize",
								}}
							>
								un.{" "}
								{formatter.format(product.price * (1 - payConditionDiscount))}
							</Typography>
						</Box>
						<ActionsButtons
							variant="text"
							onClick={() => removeByProductId(product.idProduct)}
							sx={{ justifyContent: "flex-end" }}
						>
							<Typography
								variant="subtitle1"
								sx={{
									textTransform: "capitalize",
									lineHeight: 1.25,
								}}
								color="error"
							>
								Quitar
							</Typography>
						</ActionsButtons>
					</Grid>
					<Grid item xs={12}>
						<Collapse
							in={expanded}
							timeout="auto"
							unmountOnExit
							sx={{ width: "100%", mt: "0.5rem" }}
						>
							<TableContainer>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<CartTableCell align="left" width="25%">
												<Typography variant="captions" color="secondary">
													Color
												</Typography>
											</CartTableCell>
											<CartTableCell align="center" width="16%">
												<Typography variant="captions" color="secondary">
													Talle
												</Typography>
											</CartTableCell>
											<CartTableCell align="center" width="43%">
												<Typography variant="captions" color="secondary">
													Unidades
												</Typography>
											</CartTableCell>
											<CartTableCell align="center" width="16%"></CartTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{sizeAndQuantityByColor?.map((element) => {
											// con este map genero la tabla para cada color de un producto
											let rowSpan = element.types.length;
											let firstRow = element.types.slice(0, 1);
											/* Genero una copia solo del primer elemento del array, para utilizarlo 
											en la primer fila y utilizar el rowSpan, para que el color incluya todas 
											las filas de sus colores */
											let restRows = element.types.slice(1);
											/* Genero una copia del array excluyendo el primer elemento, para 
											continuar completando las filas de colores de productos */
											//Genero primer fila con rowspan de color para ocupar mas filas
											return (
												// Con firstRow[0] accedo al objeto en la posición 0 del array
												<Fragment key={firstRow[0].idComponent}>
													<TableRow>
														<CartTableCell
															scope="row"
															width="25%"
															rowSpan={rowSpan}
															sx={{ verticalAlign: "center" }}
														>
															<Typography
																variant="subtitle1"
																color="initial"
																sx={{
																	display: "flex",
																	alignItems: "flex-start",
																	textTransform: "capitalize",
																}}
															>
																{element.descriptionColor}
															</Typography>
														</CartTableCell>
														<CartTableCell align="center" width="16%">
															{firstRow[0].size}
														</CartTableCell>
														<CartTableCell align="center" width="43%">
															<CounterCartContainer
																item={firstRow[0]}
																initial={firstRow[0].quantity}
																addToComponent={addToComponent}
																removeToComponent={removeToComponent}
																removeByComponentId={removeByComponentId}
															/>
														</CartTableCell>
														<CartTableCell
															align="right"
															width="16%"
															sx={{ padding: "6px 12px" }}
														>
															<Tooltip title="Borrar" placement="bottom">
																<IconButton
																	aria-label="delete"
																	onClick={() => {
																		removeByComponentId({
																			idProduct: product.idProduct,
																			idComponent: firstRow[0].idComponent,
																		});
																	}}
																	sx={{
																		alignItems: "flex-start",
																		padding: 0,
																	}}
																>
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														</CartTableCell>
													</TableRow>
													{restRows?.map((row) => {
														//Genero resto de filas con la copia del array
														return (
															<TableRow key={row.idComponent}>
																<CartTableCell align="center" width="16%">
																	{row.size}
																</CartTableCell>
																<CartTableCell align="center" width="43%">
																	<CounterCartContainer
																		item={row}
																		initial={row.quantity}
																		addToComponent={addToComponent}
																		removeToComponent={removeToComponent}
																		removeByComponentId={removeByComponentId}
																	/>
																</CartTableCell>
																<CartTableCell
																	align="right"
																	width="16%"
																	sx={{ padding: "6px 12px" }}
																>
																	<Tooltip title="Borrar" placement="bottom">
																		<IconButton
																			aria-label="delete"
																			onClick={() =>
																				removeByComponentId({
																					idProduct: product.idProduct,
																					idComponent: row.idComponent,
																				})
																			}
																			sx={{
																				alignItems: "flex-start",
																				padding: 0,
																			}}
																		>
																			<DeleteIcon />
																		</IconButton>
																	</Tooltip>
																</CartTableCell>
															</TableRow>
														);
													})}
												</Fragment>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Collapse>
					</Grid>
				</Grid>
				{/* <Divider /> */}
			</Box>
		</>
	);
};

export default CartItemList;
