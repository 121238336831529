import React, { useEffect, useRef, useState } from "react";
import {
	Badge,
	Box,
	Fade,
	Grid,
	Radio,
	Tooltip,
	Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	LazyLoadImage,
	trackWindowScroll,
} from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { formatter, getColors } from "../../utils/auxFunc";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./ProductCard.css";
import { useDiscount } from "../../context/Discount/DiscountContext";

const theme = createTheme();

theme.typography.body1 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "1rem",
	},
};

theme.typography.h3 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "1rem",
	},
};

const BpIcon = styled("span")({
	borderRadius: "50%",
	width: 20,
	height: 20,
});

const BpCheckedIcon = styled(BpIcon)({
	border: "solid 1px white",
	boxShadow: "0 0 10px 7px white",
});

const useStyles = makeStyles((theme) => ({
	arrows: {
		"&.MuiSvgIcon-root": { opacity: "30%", cursor: "pointer" },
		"&:hover, &.Mui-focusVisible": { opacity: "100%" },
	},
}));

const ProductCard = ({ product }) => {
	const { payConditionDiscount } = useDiscount();
	const [showArrows, setShowArrows] = useState(false);
	const [imgColor, setImgColor] = useState(product);
	const [changeImg, setChangeImg] = useState(false);
	const [standsOutTheSelectedColor, setStandsOutTheSelectedColor] = useState();
	const slider = useRef();
	const classes = useStyles();

	const colors = product && getColors(product);

	const handleChangeColor = (color) => {
		setImgColor(colors.find((element) => element.color === color));
		setChangeImg(true);
	};

	const handleClickColor = (color) => {
		setStandsOutTheSelectedColor(color);
	};

	const slideLeft = () => {
		slider.current.scrollLeft -= 50;
	};

	const slideRight = () => {
		slider.current.scrollLeft += 50;
	};

	const isOverflown = (element) => {
		return element.scrollWidth > element.clientWidth;
	};

	useEffect(() => {
		setShowArrows(isOverflown(slider.current));
	}, []);

	return (
		product && (
			<Grid
				className="gridContainer"
				key={product.idProduct}
				item
				xs={6}
				md={3}
			>
				<Link className="linkGridHome" to={`/itemDetail/${product.idProduct}`}>
					{changeImg ? (
						<LazyLoadImage
							src={imgColor.img}
							alt={imgColor.description}
							className="productImgCard"
							effect="blur"
						/>
					) : (
						<LazyLoadImage
							src={product.img}
							alt={product.description}
							className="productImgCard"
							effect="blur"
						/>
					)}
				</Link>
				<Box className="sliderContainer">
					{showArrows && (
						<KeyboardArrowLeftIcon
							className={classes.arrows}
							sx={{ display: { xs: "none", md: "flex" } }}
							onClick={slideLeft}
						/>
					)}
					<Box
						className="sliderItemDetail"
						ref={slider}
						id="slider"
						sx={{ display: "flex" }}
					>
						{colors.map(({ color, colorHexa }, index) => (
							<Box key={index}>
								<Tooltip
									title={color}
									placement="bottom"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									sx={{ textTransform: "capitalize", fontSize: "5rem" }}
								>
									<Badge
										className="paletteColor"
										style={{ background: `#${colorHexa}` }}
										onClick={() => {
											handleChangeColor(color);
											handleClickColor(color);
										}}
										overlap="circular"
										sx={{
											"& .MuiBadge-badge": {
												color: "white",
												backgroundColor: "black",
												border: "solid 1px white",
											},
										}}
									>
										<Radio
											checked={standsOutTheSelectedColor === color}
											value={color}
											checkedIcon={
												standsOutTheSelectedColor === "white" ? (
													<BpCheckedIcon
														style={{
															backgroundColor: `#${colorHexa}`,
															border: "solid 1px lightgrey",
															boxShadow: "0 0 10px 7px lightgrey",
														}}
													/>
												) : (
													<BpCheckedIcon
														style={{ backgroundColor: `#${colorHexa}` }}
													/>
												)
											}
											icon={<BpIcon style={{ background: `#${colorHexa}` }} />}
											onClick={() => {
												handleChangeColor(color);
												handleClickColor(color);
											}}
											sx={{ padding: "4px" }}
										/>
									</Badge>
								</Tooltip>
							</Box>
						))}
					</Box>
					{showArrows && (
						<KeyboardArrowRightIcon
							className={classes.arrows}
							sx={{ display: { xs: "none", md: "flex" } }}
							onClick={slideRight}
						/>
					)}
				</Box>
				<Link className="linkGridHome" to={`/itemDetail/${product.idProduct}`}>
					<ThemeProvider theme={theme}>
						<Typography
							sx={{
								fontFamily: "Quicksand",
								textTransform: "capitalize",
								textAlign: "left",
							}}
							variant="body1"
						>
							{product?.name.toLowerCase()}
						</Typography>
						<Box className="priceContainer">
							<Box>
								<Typography
									sx={{
										fontWeight: 100,
										textAlign: "left",
										textDecoration: "line-through",
									}}
									color="text.primary"
									variant="caption"
								>
									{formatter.format(product?.price)}
								</Typography>
								<Typography
									sx={{
										textAlign: "left",
										fontWeight: "bold",
										mb: 3.5,
									}}
									color="text.primary"
									variant="h3"
								>
									{formatter.format(product.price * (1 - payConditionDiscount))}
								</Typography>
							</Box>
							{/* {payConditionDiscount > 0 && (
								<Box>
									<Typography variant="h1" color="initial">{`${payConditionDiscount * 100} % OFF`}% off`}</Typography>
								</Box>
							)} */}
							{/* <Box className="priceContainer__priceDiscount">
								<Typography
									variant="subtitle2"
									sx={{ color: "#FAFAFA", fontWeight: "600" }}
								>{`${payConditionDiscount * 100} % OFF`}</Typography>
							</Box> */}
						</Box>
					</ThemeProvider>
				</Link>
			</Grid>
		)
	);
};

export default trackWindowScroll(ProductCard);
