import { loginUser } from '../../controllers/accountController'
import {
  addProductsToCart,
  addToComponent,
  emptyCart,
  getCart,
  removeComponent,
  removeProduct,
  removeToComponent
} from '../../controllers/cartController'
import {
  confirmOrder,
  getPaymentMethods,
  sendPaymentMethod,
  sendShippingMethod
} from '../../controllers/checkoutController'
import {
  getLatestOrders,
  getOrderById
} from '../../controllers/ordersController'
import {
  getCategories,
  getColors,
  getNewIn,
  getProductsByCategory,
  getProductsById,
  getProductsSearched,
  getSizes,
  getFits
} from '../../controllers/productsController'
import { getBanners, getCarriers } from '../../controllers/utilitiesController'

const apiInstance = {
  apiURL: process.env.REACT_APP_BASE_URL,
  headers: {
    //prettier-ignore
    "Accept": "text/plain",
    'Content-Type': 'application/json; charset=utf-8'
  }
}

export const setApiURL = (url) => {
  apiInstance.apiURL = url
}

export const api = {
  categories: { listAll: (signal) => getCategories(apiInstance, signal) },
  products: {
    listNewIn: (id, signal) => getNewIn(apiInstance, id, signal),
    listByCategory: (url, signal) =>
      getProductsByCategory(apiInstance, url, signal),
    listById: (idClient, idProduct, signal) =>
      getProductsById(apiInstance, idClient, idProduct, signal),
    listProductsSearched: (url, signal) =>
      getProductsSearched(apiInstance, url, signal),
    listColors: () => getColors(apiInstance),
    listSizes: () => getSizes(apiInstance),
    listFits: () => getFits(apiInstance)
  },
  checkout: {
    shippingMethod: (clientId, method, address, date, data) =>
      sendShippingMethod(apiInstance, clientId, method, address, date, data),
    getPaymentMethods: (clientId) => getPaymentMethods(apiInstance, clientId),
    paymentMethod: (payment, discount, data) =>
      sendPaymentMethod(apiInstance, payment, discount, data),
    confirm: (data) => confirmOrder(apiInstance, data)
  },
  account: {
    login: (user, password) => loginUser(apiInstance, user, password)
  },
  cart: {
    getCart: (data) => getCart(apiInstance, data),
    addProducts: (data) => addProductsToCart(apiInstance, data),
    removeComponent: (idComponent, data) =>
      removeComponent(apiInstance, idComponent, data),
    removeProduct: (idProduct, data) =>
      removeProduct(apiInstance, idProduct, data),
    addToComponent: (idComponent, data) =>
      addToComponent(apiInstance, idComponent, data),
    removeToComponent: (idComponent, data) =>
      removeToComponent(apiInstance, idComponent, data),
    emptyCart: (data) => emptyCart(apiInstance, data)
  },
  orders: {
    getLatestOrders: (clientId, statusCode = '') =>
      getLatestOrders(apiInstance, clientId, statusCode),
    getOrderById: (clientId, orderId) =>
      getOrderById(apiInstance, clientId, orderId)
  },
  utilities: {
    getCarriers: () => getCarriers(apiInstance),
    getBanners: (signal) => getBanners(apiInstance, signal)
  }
}
