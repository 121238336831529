import { Fragment } from "react";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "@mui/lab/DatePicker";
import { makeStyles, styled } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { formatter } from "../../utils/auxFunc";
// import CheckoutBuyerForm from "../CheckoutBuyerForm";
import CheckoutShipmentForm from "../CheckoutShipmentForm";
import moment from "moment";
import { useDiscount } from "../../context/Discount/DiscountContext";

const steps = ["ENVÍO", "PAGO", "RESUMEN"];

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiStepConnector-root": {
			top: "18px",
			left: "calc(-50% + 25px)",
			right: "calc(50% + 25px)",
		},
		"& .MuiSvgIcon-root.MuiStepIcon-root": { width: "1.4em", height: "1.4em" },
		"& .MuiStepIcon-root.Mui-active": {
			color: "#292929",
			border: "1px solid #292929",
			borderRadius: "50%",
			"& .MuiStepIcon-text": {
				fill: "#FAFAFA",
			},
		},
		"& .MuiStepIcon-root.Mui-completed": {
			color: "#9b9797",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
		"& .Mui-disabled .MuiStepIcon-root": {
			color: "#FAFAFA",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
	},
}));

const CheckoutRadio = styled(Radio)(({ theme }) => ({
	"&.Mui-checked": {
		color: theme.palette.secondary.main,
	},
}));

const ShipmentInfo = ({
	timer,
	buyerForm,
	setBuyerForm,
	shipmentForm,
	setShipmentForm,
	withdrawalDate,
	setWithdrawalDate,
	destinationForm,
	setDestinationForm,
	carriers,
	getTotalAmount,
	handleSubmit,
	register,
	Controller,
	control,
	errors,
	closeCheckout,
	activeStep,
	handleNext,
}) => {
	const { payConditionDiscount } = useDiscount();
	const classes = useStyles();

	const handleRadio = (evt) => {
		setShipmentForm({
			shipmentOption: evt.target.value,
			shipmentMethod: evt.target.value === "sucursal" ? "0" : "1",
		});
	};

	function disableWeekends(date) {
		return date.getDay() === 0 || date.getDay() === 6;
	}

	function daysEnableds() {
		if (new Date().getDay() === 4 || new Date().getDay() === 5) {
			return moment().add(12, "days").toDate();
		} else if (new Date().getDay() === 6) {
			return moment().add(11, "days").toDate();
		} else {
			return moment().add(10, "days").toDate();
		}
	}

	return (
		<Fragment>
			<Box sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}>
				<Container maxWidth="lg">
					<Box display="flex" alignItems="center" minHeight="56px">
						<Box>
							<Typography
								variant="subtitle1"
								color="primary"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								{timer}
							</Typography>
						</Box>
						<Typography
							variant="h5"
							color="primary"
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexGrow={1}
						>
							Checkout
						</Typography>
						<Box>
							<Tooltip title="Carrito">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
									onClick={closeCheckout}
								>
									<CloseIcon color="primary" sx={{ fontSize: "2rem" }} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Container>
			</Box>
			<Container
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					sx={{ padding: "1.5rem 0" }}
					className={classes.root}
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Container>
			<Container
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Datos de facturación:
					</Typography>
				</Box>
			</Container>
			<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
				<Fragment>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1.1rem",
						}}
					>
						{buyerForm.clientName}
					</Typography>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						CUIT: {buyerForm.clientCuit}
					</Typography>
				</Fragment>
			</Container>
			{/* <CheckoutBuyerForm
				handleSubmit={handleSubmit}
				buyerForm={buyerForm}
				setBuyerForm={setBuyerForm}
				register={register}
				errors={errors}
			/> */}
			<Container
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Box display="flex" justifyContent="space-between" pt={3}>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Método de envío:
					</Typography>
				</Box>
			</Container>
			<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
				<FormControl fullWidth>
					<RadioGroup
						aria-labelledby="Selección método de envío"
						defaultValue=""
						name="opciones de envío"
						onChange={handleRadio}
					>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							width="100%"
						>
							<Box display="flex" alignItems="center" width="100%">
								<LocationOnOutlinedIcon />
								<FormControlLabel
									value="sucursal"
									checked={shipmentForm.shipmentOption === "sucursal"}
									control={
										<CheckoutRadio
											sx={{
												"&.Mui-checked": {
													color: "#292929",
												},
											}}
										/>
									}
									label="Retiro en sucursal"
									labelPlacement="start"
									sx={{
										display: "flex",
										flex: 1,
										justifyContent: "space-between",
									}}
								/>
							</Box>
							{shipmentForm.shipmentOption === "sucursal" && (
								<Container>
									<Typography
										variant="subtitle2"
										sx={{ paddingLeft: "17px", fontStyle: "italic" }}
									>
										Remedios Escalada de San Martín 3358, C1407 CABA
									</Typography>
								</Container>
							)}
						</Box>
						<Box display="flex" alignItems="center" width="100%">
							<LocationOnOutlinedIcon />
							<FormControlLabel
								value="domicilio"
								checked={shipmentForm.shipmentOption === "domicilio"}
								control={
									<CheckoutRadio
										sx={{
											"&.Mui-checked": {
												color: "#292929",
											},
										}}
									/>
								}
								label="Envío a domicilio"
								labelPlacement="start"
								sx={{
									display: "flex",
									flex: 1,
									justifyContent: "space-between",
								}}
							/>
						</Box>
					</RadioGroup>
				</FormControl>

				<Typography variant="caption" color="error">
					{/* {error} */}
				</Typography>
			</Container>
			{shipmentForm.shipmentOption === "sucursal" ? (
				<Fragment>
					<Container
						sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
					>
						<Box display="flex" justifyContent="space-between" pt={3}>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Fecha de retíro:
							</Typography>
						</Box>
					</Container>
					<Container>
						<Grid
							container
							spacing={2}
							sx={{
								display: "flex",
								alignItems: "center",
								paddingTop: "1.5rem",
								paddingBottom: "1.5rem",
							}}
						>
							<Grid item sx={{ display: "flex", alignItems: "center" }}>
								<LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
									<EventIcon />
									<DatePicker
										shouldDisableDate={disableWeekends}
										minDate={moment().add(2, "days").toDate()}
										maxDate={daysEnableds()}
										inputFormat="dd-MM-yyyy"
										value={withdrawalDate.withdrawalDate}
										onChange={(newValue) =>
											setWithdrawalDate({ withdrawalDate: newValue })
										}
										DialogProps={{
											sx: {
												textTransform: "capitalize",
											},
										}}
										toolbarTitle="Seleccione una fecha"
										cancelText={
											<Box
												component="span"
												color={(theme) => theme.palette.secondary.main}
											>
												Cancelar
											</Box>
										}
										okText={
											<Box
												component="span"
												color={(theme) => theme.palette.secondary.main}
											>
												Ok
											</Box>
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Elegir fecha"
												sx={{
													marginLeft: "1rem",
												}}
											/>
										)}
									></DatePicker>
								</LocalizationProvider>
							</Grid>
							<Grid item>
								<Typography>
									Si tu pedido está listo antes, te notificaremos por email para
									retirarlo
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</Fragment>
			) : shipmentForm.shipmentOption === "domicilio" ? (
				<CheckoutShipmentForm
					destinationForm={destinationForm}
					setDestinationForm={setDestinationForm}
					carriers={carriers}
					handleSubmit={handleSubmit}
					Controller={Controller}
					control={control}
					errors={errors}
					register={register}
				/>
			) : null}
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.lightBg.main,
					paddingTop: "4rem",
				}}
			></Container>
			<Box
				py={2}
				sx={{ position: "relative", bottom: 0, left: 0, width: "100%" }}
			>
				<Container>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Subtotal:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							{formatter.format(getTotalAmount() / (1 - payConditionDiscount))}
						</Typography>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Descuento:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							-
							{formatter.format(
								(getTotalAmount() / (1 - payConditionDiscount)) *
									payConditionDiscount
							)}
						</Typography>
					</Box>
					{shipmentForm.shipmentOption === "domicilio" && (
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Envío:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Bonificado hasta el transporte
							</Typography>
						</Box>
					)}
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							Total:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							{formatter.format(getTotalAmount())}
						</Typography>
					</Box>
				</Container>

				<Box pb={3} mt={2}>
					<Container>
						<Button
							color="secondary"
							variant="contained"
							fullWidth
							disableElevation
							onClick={() => {
								// isRadioChecked
								// 	?
								handleNext();
								// : setError("Seleccione un metodo de envío");
							}}
							sx={{
								textTransform: "capitalize",
							}}
						>
							Siguiente
						</Button>
					</Container>
				</Box>
			</Box>
		</Fragment>
	);
};

export default ShipmentInfo;
