export const getCart = (apiInstance, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(`${apiInstance.apiURL}/Cart/GetCart`, requestParameters)
}

export const addProductsToCart = (apiInstance, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/AddProductsToTheCart`,
    requestParameters
  )
}

export const addToComponent = (apiInstance, idComponent, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/IncrementQuantityComponentFromCart?idComponent=${idComponent}`,
    requestParameters
  )
}

export const removeComponent = (apiInstance, idComponent, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/RemoveComponentFromCart?idComponent=${idComponent}`,
    requestParameters
  )
}

export const removeProduct = async (apiInstance, idProduct, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/RemoveProductFromCart?idProduct=${idProduct}`,
    requestParameters
  )
}

export const removeToComponent = (apiInstance, idComponent, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/DecrementQuantityComponentFromCart?idComponent=${idComponent}`,
    requestParameters
  )
}

export const emptyCart = (apiInstance, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(`${apiInstance.apiURL}/Cart/emptyCart`, requestParameters)
}
