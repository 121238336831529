import {
	Box,
	Button,
	Collapse,
	Container,
	IconButton,
	Step,
	StepLabel,
	Stepper,
	styled,
	Tooltip,
	Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import CheckoutItemsDetail from "../CheckoutItemsDetail";
import { calculateIVA, formatter } from "../../utils/auxFunc";
import "./CheckoutResume.css";
import { useDiscount } from "../../context/Discount/DiscountContext";

const steps = ["ENVÍO", "PAGO", "RESUMEN"];

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiStepConnector-root": {
			top: "18px",
			left: "calc(-50% + 25px)",
			right: "calc(50% + 25px)",
		},
		"& .MuiSvgIcon-root.MuiStepIcon-root": { width: "1.4em", height: "1.4em" },
		"& .MuiStepIcon-root.Mui-active": {
			color: "#292929",
			border: "1px solid #292929",
			borderRadius: "50%",
			"& .MuiStepIcon-text": {
				fill: "#FAFAFA",
			},
		},
		"& .MuiStepIcon-root.Mui-completed": {
			color: "#9b9797",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
		"& .Mui-disabled .MuiStepIcon-root": {
			color: "#FAFAFA",
			border: "1px solid #9b9797",
			borderRadius: "50%",
		},
	},
}));

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	padding: "0 0.5rem",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

const CheckoutResume = ({
	timer,
	userData,
	buyerForm,
	shipmentForm,
	withdrawalDate,
	destinationForm,
	paymentForm,
	closeCheckout,
	activeStep,
	handleBack,
	confirmOrder,
	cart,
	getTotalByComponents,
	getTotalQuantity,
	getTotalAmount,
}) => {
	const [expanded, setExpanded] = useState(false);
	const { payConditionDiscount } = useDiscount();
	const classes = useStyles();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const numberOfProducts = getTotalByComponents().length;
	const totalQuantityOfComponets = getTotalQuantity();
	const totalAmount = getTotalAmount();

	const iva = calculateIVA(totalAmount, userData.approPercentage);

	return (
		<Fragment>
			<Box sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}>
				<Container maxWidth="lg">
					<Box display="flex" alignItems="center" minHeight="56px">
						<Box>
							<Typography
								variant="subtitle1"
								color="primary"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								{timer}
							</Typography>
						</Box>
						<Typography
							variant="h5"
							color="primary"
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexGrow={1}
						>
							Checkout
						</Typography>
						<Box>
							<Tooltip title="Carrito">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
									onClick={closeCheckout}
								>
									<CloseIcon color="primary" sx={{ fontSize: "2rem" }} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Container>
			</Box>
			<Container
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					sx={{ padding: "1.5rem 0" }}
					className={classes.root}
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<Typography
					variant="subtitle1"
					color={(theme) => theme.palette.light.main}
					sx={{
						fontSize: "1rem",
					}}
				>
					Mi pedido:
				</Typography>
			</Container>
			<Container onClick={handleExpandClick}>
				<Box display="flex" justifyContent="space-between" width="100%" my={2}>
					<Box display="flex" flexDirection="column" alignItems="start">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								textTransform: "capitalize",
							}}
						>
							Total: {formatter.format(totalAmount)}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{
									color: (theme) => theme.palette.light.main,
									fontSize: "1rem",
									lineHeight: 1.25,
									textTransform: "capitalize",
								}}
								color="secondary"
							>
								{numberOfProducts}{" "}
								{numberOfProducts > 1 ? "productos" : "producto"},{" "}
								{totalQuantityOfComponets}{" "}
								{totalQuantityOfComponets > 1 ? "unidades" : "unidad"}
							</Typography>
						</Box>
					</Box>
					<Box display="flex" alignItems="center">
						<ExpandMore
							expand={expanded}
							aria-expanded={expanded}
							aria-label="Detalle artículos"
						>
							<ExpandMoreIcon />
						</ExpandMore>
					</Box>
				</Box>
			</Container>
			<Collapse
				in={expanded}
				timeout="auto"
				unmountOnExit
				sx={{ width: "100%", marginTop: "0.5rem" }}
			>
				{cart?.map((product) => (
					<CheckoutItemsDetail key={product.idProduct} product={product} />
				))}
			</Collapse>
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.lightBg.main,
					paddingTop: "1.5rem",
				}}
			>
				<Box>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Datos de facturación:
					</Typography>
				</Box>
			</Container>
			<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
				<Fragment>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1.1rem",
						}}
					>
						{buyerForm.clientName}
					</Typography>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						CUIT: {buyerForm.clientCuit}
					</Typography>
				</Fragment>
			</Container>
			{shipmentForm.shipmentOption === "domicilio" && (
				<Fragment>
					<Container
						sx={{
							backgroundColor: (theme) => theme.palette.lightBg.main,
							paddingTop: "1.5rem",
						}}
					>
						<Box>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Dirección de envío:
							</Typography>
						</Box>
					</Container>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Fragment>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1.1rem",
								}}
							>
								{destinationForm.shopName}
							</Typography>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
									textTransform: "capitalize",
								}}
							>
								{destinationForm.shopAddress}
							</Typography>
						</Fragment>
					</Container>
				</Fragment>
			)}
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.secondary.main,
					paddingTop: "0.5rem",
					paddingBottom: "0.5rem",
				}}
			>
				<Typography
					variant="caption"
					color={(theme) => theme.palette.primary.main}
					sx={{
						textTransform: "uppercase",
						fontWeight: 600,
					}}
				>
					desde su cuenta podrá ver su estado y fecha de envío
				</Typography>
			</Container>
			{shipmentForm.shipmentOption === "domicilio" ? (
				<Fragment>
					<Container
						sx={{
							backgroundColor: (theme) => theme.palette.lightBg.main,
							paddingTop: "1.5rem",
						}}
					>
						<Box>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Medio de transporte:
							</Typography>
						</Box>
					</Container>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.1rem",
								textTransform: "capitalize",
							}}
						>
							{destinationForm.transportName}
						</Typography>
					</Container>
				</Fragment>
			) : (
				<Fragment>
					<Container
						sx={{
							backgroundColor: (theme) => theme.palette.lightBg.main,
							paddingTop: "1.5rem",
						}}
					>
						<Box>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Método de envío:
							</Typography>
						</Box>
					</Container>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.1rem",
								textTransform: "capitalize",
							}}
						>
							{shipmentForm.shipmentOption === "sucursal"
								? "Retira por sucursal"
								: "Envío a domicilio"}
						</Typography>
					</Container>
				</Fragment>
			)}
			{shipmentForm.shipmentOption === "sucursal" ? (
				<Fragment>
					<Container
						sx={{
							backgroundColor: (theme) => theme.palette.lightBg.main,
							paddingTop: "1.5rem",
						}}
					>
						<Box>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Fecha de retiro:
							</Typography>
						</Box>
					</Container>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.1rem",
								textTransform: "capitalize",
							}}
						>
							{withdrawalDate.withdrawalDate.toLocaleDateString(
								"es-AR",
								options
							)}
						</Typography>
					</Container>
				</Fragment>
			) : null}
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.lightBg.main,
					paddingTop: "1.5rem",
				}}
			>
				<Box>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Método de pago:
					</Typography>
				</Box>
			</Container>
			<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
				<Fragment>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: "1.1rem",
							textTransform: "capitalize",
						}}
					>
						{paymentForm.paymentDescription}
					</Typography>
				</Fragment>
			</Container>
			<Container
				sx={{
					backgroundColor: (theme) => theme.palette.lightBg.main,
					paddingTop: "4rem",
				}}
			></Container>
			<Box
				py={2}
				sx={{
					position: "relative",
					bottom: 0,
					left: 0,
					width: "100%",
					backgroundColor: "#FFF",
				}}
			>
				<Container>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Subtotal:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							{formatter.format(totalAmount / (1 - payConditionDiscount))}
						</Typography>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Descuento:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							-
							{formatter.format(
								(totalAmount / (1 - payConditionDiscount)) *
									payConditionDiscount
							)}
						</Typography>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							Apropiación:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
							}}
						>
							{formatter.format(iva)}
						</Typography>
					</Box>
					{shipmentForm.shipmentOption === "domicilio" && (
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Envío:
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
								}}
							>
								Bonificado hasta el transporte
							</Typography>
						</Box>
					)}
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							Total:
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
							}}
						>
							{formatter.format(totalAmount + iva)}
						</Typography>
					</Box>
				</Container>
				<Container
					sx={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "1rem",
						paddingBottom: "1.5rem",
					}}
				>
					<Button
						color="secondary"
						variant="outlined"
						sx={{
							width: "45%",
							textTransform: "capitalize",
							fontSize: "1rem",
						}}
						onClick={handleBack}
					>
						Anterior
					</Button>
					<Button
						color="secondary"
						variant="contained"
						disableElevation
						sx={{
							width: "45%",
							textTransform: "capitalize",
							fontSize: "1rem",
						}}
						onClick={confirmOrder}
					>
						Finalizar
					</Button>
				</Container>
			</Box>
		</Fragment>
	);
};

export default CheckoutResume;
