import { Fragment, useEffect, useState } from "react";
import { Box, Container, Divider, Typography, Button } from "@mui/material";
import OrderItem from "../../components/OrderItem/OrderItem";
import InventoryIcon from "@mui/icons-material/Inventory";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PaymentIcon from "@mui/icons-material/Payment";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth/AuthContext";
import UserConfig from "../../components/UserConfig";
import { api } from "../../services/api";
import HeaderContainer from "../HeaderContainer/HeaderContainer";

const config = [
	// {
	// 	startIcon: <AppRegistrationIcon />,
	// 	endIcon: <ArrowForwardIosIcon />,
	// 	title: "Mis Datos",
	// 	href: "/user-account/data",
	// },
	{
		startIcon: <LocationOnIcon />,
		endIcon: <ArrowForwardIosIcon />,
		title: "Direcciones y formas de envío",
		href: "/user-account/address-shipping",
	},
	// {
	// 	startIcon: <PaymentIcon />,
	// 	endIcon: <ArrowForwardIosIcon />,
	// 	title: "Formas de pago",
	// 	href: "/user-account/pay-methods",
	// },
];

const MyAccountContainer = () => {
	const [orders, setOrders] = useState();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { userData, signOut } = useAuth();

	const redirectOrder = (id) => {
		navigate(`/user-account/orders/${id}`);
	};

	const handleReturn = () => {
		if (pathname === "/user-account") {
			navigate("/");
		} else {
			navigate(-1);
		}
	};

	useEffect(() => {
		api.orders
			.getLatestOrders(userData.clientId)
			.then((response) => response.json())
			.catch((err) => console.log(err))
			.then((ordersAPI) => setOrders(ordersAPI))
			.catch((err) => console.log(err));
	}, [userData]);

	return (
		<Fragment>
			<HeaderContainer handleReturn={handleReturn} title="Mi cuenta" />
			<Divider />
			<Container>
				<Typography
					variant="h6"
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexGrow={1}
					mt={2}
					sx={{ fontWeight: "600" }}
				>
					¡Hola, {userData.userName}!
				</Typography>
				<Typography
					variant="subtitle1"
					mb={3}
					sx={{ color: (theme) => theme.palette.light.main }}
				>
					Conocé el estado de tu cuenta
				</Typography>
				<Box display="flex" alignItems="center">
					<InventoryIcon fontSize="small" />
					<Typography
						variant="body1"
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						ml={2}
						flexGrow={1}
						sx={{ fontWeight: "600" }}
					>
						{orders?.length} Pedidos Pendientes
					</Typography>
					<Link to="/user-account/orders/pending" className="config-items">
						<Button color="secondary" sx={{ textTransform: "none" }}>
							Ver más
						</Button>
					</Link>
				</Box>
				<Divider />
				{orders?.length === 0 ? (
					<Box
						sx={{
							minHeight: "25vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography variant="h5" sx={{ color: "#5E5E5E" }}>
							No tiene pedidos pendientes
						</Typography>
					</Box>
				) : (
					orders?.slice(0, 7).map((order) => {
						return (
							<Fragment key={order.idOrder}>
								<Button
									sx={{ textTransform: "none" }}
									color="secondary"
									fullWidth
									onClick={() => redirectOrder(order.idOrder)}
								>
									<OrderItem
										orderDate={order.orderDate}
										state={order.state}
										orderItems={order.orderItems}
										withdrawalPoint={order.withdrawalPoint}
										isPaid={order.isPaid}
									/>
								</Button>
								<Divider />
							</Fragment>
						);
					})
				)}

				{/******  Pendiente para otra etapa ******/}
				{/* <Box display="flex" justifyContent="center" mt={4}>
					<Button
						variant="contained"
						color="primary"
						sx={{
							fontSize: "1rem",
							fontWeight: "400",
							border: "1px solid #292929",
							width: "50%",
							padding: "0.5rem",
							textTransform: "capitalize",
						}}
						disableElevation
					>
						Asociar envíos
					</Button>
				</Box> 
				<Box display="flex" mt={5}>
					<CreditCardIcon fontSize="small" />
					<Typography
						variant="body1"
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						flexGrow={1}
						mb={2}
						ml={2}
						sx={{ fontWeight: "600" }}
					>
						Saldo de tu cuenta
					</Typography>
				</Box>
				<Divider />
				<Box display="flex" justifyContent="space-between" my={2}>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
						}}
					>
						Saldo a pagar
					</Typography>
					<Typography
						variant="subtitle2"
						textAlign="right"
						sx={{
							fontSize: "1rem",
						}}
					>
						{formatter.format(user.debit)}
					</Typography>
				</Box>
				
				<Divider />
				<Box display="flex" justifyContent="space-between" my={2}>
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "1rem",
						}}
					>
						Saldo a favor
					</Typography>
					<Typography
						variant="subtitle2"
						textAlign="right"
						sx={{
							fontSize: "1rem",
						}}
					>
						{formatter.format(user.credit)}
					</Typography>
				</Box>
				*/}
				<Typography
					variant="h6"
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexGrow={1}
					mt={5}
					mb={1}
					sx={{ fontWeight: "600" }}
				>
					Mi configuración
				</Typography>
			</Container>
			<Container>
				<Divider />

				{config.map((el, index) => {
					return (
						<UserConfig
							key={index}
							startIcon={el.startIcon}
							endIcon={el.endIcon}
							title={el.title}
							href={el.href}
						/>
					);
				})}

				<Button
					color="secondary"
					startIcon={<LogoutIcon />}
					fullWidth
					sx={{ textTransform: "none", padding: "1rem" }}
					onClick={signOut}
				>
					<Typography
						variant="body1"
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						flexGrow={1}
						sx={{ fontWeight: "600" }}
					>
						Cerrar Sesión
					</Typography>
				</Button>

				<Divider sx={{ marginBottom: "1rem" }} />
			</Container>
		</Fragment>
	);
};

export default MyAccountContainer;
