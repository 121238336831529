import { useState } from "react";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import { useAuth } from "../../context/Auth/AuthContext";
import LoginForm from "../../components/LoginForm";
import "./LoginContainer.css";
import { api } from "../../services/api";
import { setCookie, getCookie, deleteCookie } from "../../utils/auxFunc";

const LoginRoute = () => {
	const [user, setUser] = useState({
		userAccount: getCookie("elDonUserName") || "",
		userPassword: "",
	});
	const [isChecked, setIsChecked] = useState(
		Boolean(getCookie("elDonRememberUser"))
	);
	const { signIn } = useAuth();
	// let navigate = useNavigate()

	const handleSubmit = (evt) => {
		evt.preventDefault();

		if (user.userAccount !== "" && user.userPassword !== "") {
			api.account
				.login(user.userAccount, user.userPassword)
				.then((response) => response.json())
				.catch((error) => console.log("login error:", error))
				.then((data) => {
					if (data?.status) {
						saveUserNameCookie("elDonUserName", user.userAccount);
						signIn(data);
					} else {
						Swal.fire({
							customClass: {
								confirmButton: "swalBtnOk",
							},
							position: "center",
							icon: "error",
							title: "Hubo un problema al iniciar sesión",
							text: data.message,
							showConfirmButton: true,
							focusConfirm: false,
							iconColor: "#FF8A65",
							color: "#FAFAFA",
							background: "#292929",
							confirmButtonColor: "#292929",
						});
					}
				})
				.catch((error) => console.error(error));
		} else {
			(user.userAccount === "" || user.userPassword === "") &&
				Swal.fire({
					customClass: {
						confirmButton: "swalBtnOk",
						title: "swalTitle",
					},
					position: "center",
					icon: "warning",
					title: "Complete todos los campos",
					width: "350px",
					showConfirmButton: true,
					focusConfirm: false,
					iconColor: "#FF8A65",
					color: "#FAFAFA",
					background: "#292929",
					confirmButtonColor: "#292929",
				});
		}
	};

	const handleChange = (evt) => {
		setUser({ ...user, [evt.target.name]: evt.target.value });
	};

	const saveUserNameCookie = (cookieName, cookieValue) => {
		if (!isChecked) {
			deleteCookie(cookieName);
			return;
		}

		setCookie(cookieName, cookieValue, 365);
	};

	const handleChecked = (evt) => {
		setIsChecked(evt.target.checked);

		if (!evt.target.checked) {
			deleteCookie("elDonUserName");
			deleteCookie("elDonRememberUser");
			return;
		}

		setCookie("elDonRememberUser", evt.target.checked, 365);
	};

	const forgotPassword = () => {
		Swal.fire({
			customClass: {
				confirmButton: "swalBtnOk",
			},
			icon: "info",
			position: "center",
			text: "Si no recuerda su contraseña, contacte a su vendedor",
			width: "350px",
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#64B5F6",
			color: "#FAFAFA",
			background: "#292929",
			confirmButtonColor: "#292929",
		});
	};

	return (
		<Box
			component="main"
			sx={{
				backgroundColor: "#0F0F0F",
				width: "100vw",
				heigth: "100vh",
			}}
		>
			<LoginForm
				user={user}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				forgotPassword={forgotPassword}
				isChecked={isChecked}
				handleChecked={handleChecked}
			/>
		</Box>
	);
};

export default LoginRoute;
