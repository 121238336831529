import { Fragment } from "react";
import {
	createTheme,
	ThemeProvider,
	Box,
	Badge,
	Radio,
	Tooltip,
	Fade,
	Card,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useDiscount } from "../../context/Discount/DiscountContext";
import SpinnerContainer from "../../containers/SpinnerContainer/SpinnerContainer";
import CounterDetailContainer from "../../containers/CounterDetailContainer";
import { formatter } from "../../utils/auxFunc";
import "./ItemDetail.css";

const themeBtn = createTheme({
	palette: {
		primary: {
			main: "#000000", //color del btn y color del borde en este caso
		},
	},
	typography: {
		fontFamily: "Quicksand",
	},
});

const theme = createTheme();

theme.typography.body1 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.down("lg")]: {
		fontSize: "0.80rem",
	},
};

theme.typography.h3 = {
	fontSize: "1rem",
	"@media (min-width:600px)": {
		fontSize: "1rem",
	},
	[theme.breakpoints.down("lg")]: {
		fontSize: "0.80rem",
	},
};

function ItemDetail({
	throttle,
	getInitialValue,
	handleProductsToAddToCart,
	handleAddToCart,
	product,
	sizesByColor,
	handleClickColor,
	componentColor,
	isDisabled,
	getQuantityByColor,
	handleChangeImg,
	changeImgInEachColor,
	imgIndex,
	resetCounter,
}) {
	const { payConditionDiscount } = useDiscount();
	const navigate = useNavigate();
	const BpIcon = styled("span")({
		borderRadius: "50%",
		width: 30,
		height: 30,
	});

	const BpCheckedIcon = styled(BpIcon)({
		border: "solid 2px white",
		boxShadow: "0 0 15px 10px white",
	});

	return (
		<Fragment>
			{componentColor ? (
				<Box className="itemDetailContainer">
					<button className="btnBackItemDetail" onClick={() => navigate(-1)}>
						<ArrowBackIcon className="iconSizeGuide" />
					</button>
					<Box className="cardContainer" maxWidth={1200}>
						<button
							className="btnBackItemDetailMobile"
							onClick={() => navigate(-1)}
						>
							<ArrowBackIcon className="iconSizeGuide" />
						</button>
						<Box>
							<Box className="boxBtnsAndImg">
								<Box className="boxButtonsBack">
									<button
										className="btnChangeImgBack"
										onClick={() => {
											handleChangeImg(imgIndex - 1);
										}}
										disabled={imgIndex === 0 ? true : false}
									>
										<ArrowBackIosNewIcon />
									</button>
								</Box>
								<Card>
									<CardMedia
										component="img"
										image={
											changeImgInEachColor?.img === "" ||
											changeImgInEachColor?.img === undefined
												? componentColor?.img
												: changeImgInEachColor?.img
										}
										alt={`${product?.name.toLowerCase()} color ${componentColor?.descriptionColor.toLowerCase()}`}
										className="imgCard"
									/>
								</Card>
								<Box className="boxButtonFoward">
									<button
										className="btnChangeImgFoward"
										onClick={() => {
											handleChangeImg(imgIndex + 1);
										}}
										disabled={
											imgIndex === componentColor?.imgC?.slice(0, 3).length
												? true
												: false
										}
									>
										<ArrowForwardIosIcon />
									</button>
								</Box>
							</Box>
							<Box className="boxBtnHandleChangeImg">
								{changeImgInEachColor?.img === ""
									? componentColor?.imgC?.slice(0, 4).map((img, index) => (
											<Button
												className="btnHandleChangeImg"
												sx={{
													borderRadius: "100%",
													minWidth: 0,
													width: "20px",
													height: "20px",
													margin: "5px",
													border: "transparent",
													backgroundColor: index === 0 ? "#29343f" : "#ced4da",
													paddingTop: "10px",
													"&:hover": {
														backgroundColor: "#6f7275",
													},
												}}
												onClick={() => {
													handleChangeImg(index);
												}}
												key={index}
											></Button>
									  ))
									: componentColor?.imgC?.slice(0, 4).map((img, index) => (
											<Button
												className="btnHandleChangeImg"
												sx={{
													borderRadius: "100%",
													minWidth: 0,
													width: "20px",
													height: "20px",
													margin: "5px",
													border: "transparent",
													backgroundColor:
														changeImgInEachColor?.img === img.img
															? "#29343f"
															: "#ced4da",
													paddingTop: "10px",
													"&:hover": {
														backgroundColor: "#6f7275",
													},
												}}
												onClick={() => {
													handleChangeImg(index);
												}}
												key={index}
											></Button>
									  ))}
							</Box>
						</Box>
						<Box className="detailContainer">
							<CardContent className="detailContent">
								<ThemeProvider theme={theme}>
									<Box className="typographyItemDetail">
										<Typography
											variant="h3"
											style={{ fontFamily: "Quicksand" }}
										>
											{product?.name}
										</Typography>
										<Typography
											sx={{
												fontWeight: 100,
												textAlign: "left",
												textDecoration: "line-through",
											}}
											color="text.primary"
											variant="caption"
										>
											{formatter.format(product?.price)}
										</Typography>
										<Typography
											variant="h3"
											style={{ fontFamily: "Quicksand" }}
										>
											{formatter.format(
												product?.price * (1 - payConditionDiscount)
											)}
										</Typography>
									</Box>
									<Box className="sliderColors" sx={{ display: "flex" }}>
										{sizesByColor.map((colors) => (
											<Box key={colors.descriptionColor}>
												<Tooltip
													title={colors.descriptionColor}
													placement="bottom"
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 600 }}
													sx={{
														textTransform: "capitalize",
														fontSize: "1rem",
													}}
												>
													<Badge
														className="paletteColor"
														style={{ background: `#${colors.colorHexa}` }}
														onClick={() => {
															handleClickColor(colors.descriptionColor);
														}}
														overlap="circular"
														badgeContent={getQuantityByColor(
															colors.descriptionColor
														)}
														sx={{
															"& .MuiBadge-badge": {
																color: "white",
																backgroundColor: "black",
																border: "solid 1px white",
															},
														}}
													>
														<Radio
															sx={{ padding: "2px" }}
															checked={
																componentColor?.descriptionColor ===
																colors.descriptionColor
															}
															value={colors.descriptionColor}
															checkedIcon={
																componentColor?.descriptionColor.toLowerCase() ===
																"blanco" ? (
																	<BpCheckedIcon
																		style={{
																			backgroundColor: `#${colors.colorHexa}`,
																			border: "solid 2px lightgrey",
																			boxShadow: "0 0 8px 6px lightgrey",
																		}}
																	/>
																) : (
																	<BpCheckedIcon
																		style={{
																			backgroundColor: `#${colors.colorHexa}`,
																			boxShadow: "0 0 8px 6px lightgrey",
																		}}
																	/>
																)
															}
															icon={
																<BpIcon
																	style={{
																		background: `#${colors.colorHexa}`,
																	}}
																/>
															}
															onClick={() => {
																handleClickColor(colors.descriptionColor);
															}}
														/>
													</Badge>
												</Tooltip>
											</Box>
										))}
									</Box>
									<Typography
										className="sizeTextColor"
										variant="body1"
										color="text.secondary"
									>
										Color: {componentColor?.descriptionColor}
									</Typography>
									<Typography
										className="sizeTextColor"
										variant="body1"
										color="text.secondary"
									>
										Calce: {product?.fit}
									</Typography>
									<Typography
										className="typographyItemDetailDescription"
										variant="body1"
										color="text.secondary"
									>
										{product?.description}
									</Typography>
								</ThemeProvider>
							</CardContent>
							<ThemeProvider theme={themeBtn}>
								<CardActions
									className="cardActionsSizesContainer"
									sx={{ justifyContent: "center" }}
								>
									{/* <Link to={`/SizesAndColors/${product.id}`} className='linkBtnSizesAndColor'>
                                <Button 
                                    style={{color:'#000000', background:'#FFFFFF', fontFamily: 'Quicksand'}} 
                                    variant="outlined" 
                                    fullWidth 
                                    sx={{ mt: 0.0, fontSize: "1rem", fontWeight: 700, textTransform: "none", textAlign: "center" }} 
                                >
                                    Elegir talle y color
                                </Button>
                            </Link> */}
									<Box>
										<Box>
											{componentColor?.types?.map((item) => (
												<Fragment key={item.idComponent}>
													<Box
														sx={{
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
															p: 1,
															m: 1,
															bgcolor: "background.paper",
															borderRadius: 1,
														}}
													>
														<Typography
															className="sizeText"
															sx={{
																fontSize: {
																	xs: "80%",
																	md: "80%",
																	lg: "80%",
																	xl: "100%",
																},
															}}
														>
															{item.size}
														</Typography>
														<Typography
															className="sizeTextStock"
															sx={{
																fontSize: {
																	xs: "80%",
																	md: "80%",
																	lg: "80%",
																	xl: "100%",
																},
															}}
														>
															Stock: {item.stock}
														</Typography>
														<CounterDetailContainer
															item={item}
															initial={getInitialValue(item.idComponent)}
															handleProducts={handleProductsToAddToCart}
															resetCounter={resetCounter}
														/>
													</Box>
												</Fragment>
											))}
										</Box>
									</Box>
								</CardActions>
								<CardActions className="cardActionsButton">
									<Button
										style={{
											color: "#000000",
											background: "#FFFFFF",
											fontFamily: "Quicksand",
										}}
										variant="outlined"
										disabled={isDisabled}
										fullWidth
										sx={{
											fontSize: { xs: "80%", md: "80%", lg: "80%", xl: "100%" },
											mt: 0.0,
											// fontSize: "1rem",
											fontWeight: 700,
											textTransform: "none",
											textAlign: "center",
										}}
										onClick={throttle(handleAddToCart, 1500)}
									>
										Agregar
									</Button>
									{/* <Button
								style={{ fontFamily: "Quicksand" }}
								variant="contained"
								fullWidth
								sx={{
									mt: 2,
									mr: 1,
									fontSize: "1rem",
									fontWeight: 700,
									textTransform: "none",
									textAlign: "center",
								}}
							>
								Agregar curva preferida
							</Button> */}
								</CardActions>
							</ThemeProvider>
							{/* ------ Próxima etapa ------ */}
							{/* <ThemeProvider theme={theme}>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Link to={`/SizeGuide/${product.category}`} className='linkSizeGuide'> 
									<Typography
										className="sizeGuide"
										sx={{
											borderBottom: 0.5,
											display: "flex",
											alignItems: "center",
											ml: 1,
											mr: 1,
										}}
										style={{ fontFamily: "Quicksand" }}
									>
										<StraightenIcon className="iconItemDetail" />
										Guía de Talles
									</Typography>
									</Link> 
									<Typography
										className="shippingMethods"
										sx={{ display: "flex", alignItems: "center", ml: 1 }}
										style={{ fontFamily: "Quicksand" }}
									>
										<LocalShippingIcon className="iconItemDetail" />
										Medios y formas de envío
									</Typography>
								</Box>
							</ThemeProvider> */}
						</Box>
					</Box>
				</Box>
			) : (
				<SpinnerContainer />
			)}
		</Fragment>
	);
}

export default ItemDetail;
