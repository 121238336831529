import { useState, useEffect } from "react"
import sizeGuide from "../../assets/sizeGuide"
import { useParams } from 'react-router-dom';
import SizeGuide from "../../components/SizeGuide/SizeGuide";

function SizeGuideContainer(){

    const[arraySizeGuide, setArraySizeGuide]=useState([])
    const {idCategory}=useParams()

    useEffect(()=>{
    
    if(idCategory){
        sizeGuide
        .then(response=>setArraySizeGuide(response.filter((sizeByCategory)=>sizeByCategory.category === idCategory)))
        .catch(error=>alert('Hubo un error', error))
    }else{
        sizeGuide
        .then(response=>setArraySizeGuide(response))
        .catch(error=>alert('Hubo un error', error))
    }
    },[idCategory])

    return(
        <div>
            <SizeGuide sizeTable={arraySizeGuide} nameCategory={idCategory}/>
        </div>
    )
}

export default SizeGuideContainer;