import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import Swal from "sweetalert2";
import { api } from "../../services/api";
import { normalizedCategories } from "../../utils/auxFunc";

const ProductsContext = createContext();
export const useProducts = () => useContext(ProductsContext);

export const ProductsContextProvider = ({ defaultValue = null, children }) => {
	const [products, setProducts] = useState();
	const [productCategory, setProductCategory] = useState();
	const [pages, setPages] = useState();
	const [categories, setCategories] = useState(null);

	const getCategories = async (signal) => {
		api.categories
			.listAll(signal)
			.then((response) => response.json())
			.then((categories) => {
				let allCategories = normalizedCategories(categories);
				setCategories(allCategories);
			})
			.catch((error) => {
				Swal.fire({
					title: "Error al obtener Categorías!",
					text: `Intente nuevamente`,
					icon: "error",
				});
			});
	};

	useEffect(() => {
		const controller = new AbortController();
		getCategories(controller.signal);
		return () => {
			controller.abort();
		};
	}, []);

	return (
		<ProductsContext.Provider
			value={{
				categories,
				productCategory,
				products,
				setProducts,
				pages,
				setPages,
				setProductCategory,
			}}
		>
			{children}
		</ProductsContext.Provider>
	);
};

export default ProductsContext;
