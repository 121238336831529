import { Box, IconButton, Tooltip, Fade } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link } from "react-router-dom";

const CartWidget = ({ productsInCart }) => {
	return (
		<Box className="cartWidget" sx={{ flexGrow: 0 }}>
			<Tooltip
				title="Bolsa de compras"
				placement="bottom"
				TransitionComponent={Fade}
				TransitionProps={{ timeout: 600 }}
			>
				<IconButton
					sx={{
						padding: "11px 12px 7px",
					}}
				>
					<Link to="/cart">
						{productsInCart > 0 ? (
							<ShoppingBagIcon color="secondary" />
						) : (
							<ShoppingBagOutlinedIcon color="secondary" />
						)}
					</Link>
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export default CartWidget;
