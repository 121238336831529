export const imgGrid = [
	{
		idProduct: 1,
		name: "remera stay true",
		img: "https://cdn.pixabay.com/photo/2016/08/12/00/36/legendary-1587325_960_720.jpg",
		description:
			"remera stay true. de calce slim fit. material 70% algodon - 30% poliester",
		price: 867,
		category: "remeras",
		components: [
			{
				idComponent: 1001,
				idProduct: 1,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/10/02/22/17/red-t-shirt-1710578_960_720.jpg",
					},
					{
						img: "https://drifters.com.ar/uploads/product_image/22320/650w_DriftersPDP_APP_VOL0003163-F90_Shot2.jpg",
					},
					{
						img: "https://images.asos-media.com/products/camiseta-roja-jordan-jumpman-de-nike/11720669-3?$n_640w$&wid=513&fit=constrain",
					},
					{
						img: "https://images.asos-media.com/products/camiseta-roja-con-estampado-en-la-espalda-de-estilo-universitario-de-asos-design/22820711-4?$n_640w$&wid=513&fit=constrain",
					},
				],
			},
			{
				idComponent: 1002,
				idProduct: 1,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/05/23/10/53/t-shirt-design-2336850_960_720.jpg",
					},
					{
						img: "https://footprintsclothes.com.ar/wp-content/uploads/2021/01/Remera-azul-marino.jpg",
					},
					{
						img: "https://cardon.com.ar/wp-content/uploads/2020/05/CD-147307000-30-922.jpg",
					},
					{
						img: "https://falabella.scene7.com/is/image/FalabellaAR/3147303_1?wid=1004&hei=1500&crop=248,0,1004,1500&qlt=70",
					},
				],
			},
			{
				idComponent: 1011,
				idProduct: 1,
				descriptionColor: "rojo",
				idColor: "RO",
				idSize: 2,
				colorHexa: "#ff6961",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/10/02/22/17/red-t-shirt-1710578_960_720.jpg",
					},
					{
						img: "https://drifters.com.ar/uploads/product_image/22320/650w_DriftersPDP_APP_VOL0003163-F90_Shot2.jpg",
					},
					{
						img: "https://images.asos-media.com/products/camiseta-roja-jordan-jumpman-de-nike/11720669-3?$n_640w$&wid=513&fit=constrain",
					},
					{
						img: "https://images.asos-media.com/products/camiseta-roja-con-estampado-en-la-espalda-de-estilo-universitario-de-asos-design/22820711-4?$n_640w$&wid=513&fit=constrain",
					},
				],
			},
			{
				idComponent: 1012,
				idProduct: 1,
				idColor: "AZ",
				idSize: 2,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/05/23/10/53/t-shirt-design-2336850_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 1013,
				idProduct: 1,
				idColor: "BL",
				idSize: 1,
				descriptionColor: "blanco",
				colorHexa: "#ffffff",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/08/12/00/36/legendary-1587325_960_720.jpg",
					},
					{
						img: "https://kunbambu.com/wp-content/uploads/2019/02/Remera-blanca-Bambu-1.jpg",
					},
					{
						img: "https://d2r9epyceweg5n.cloudfront.net/stores/399/451/products/20190809_2240421-a18edc65c7205d555b15967714721366-1024-1024.jpg",
					},
					{
						img: "https://elcortes.com.ar/wp-content/uploads/2020/08/REMERA-LISA-BLANCA1.jpg",
					},
				],
			},
		],
	},
	{
		idProduct: 2,
		name: "pantalon chino pekin",
		img: "https://media.istockphoto.com/photos/portrait-of-happy-handsome-asian-young-man-attractive-in-fashionable-picture-id1289201912?b=1&k=20&m=1289201912&s=170667a&w=0&h=vG7Qy8TnMf6V45Lb3nGSaqrKG68CoaXNJf4dlvccaoo=",
		description:
			"pantalon chino pekin. corte fit con dos bosillos laterales. material 97% algodon - 3% elastano",
		price: 3150,
		category: "chinos",
		components: [
			{
				idComponent: 2001,
				idProduct: 2,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/22/12/49/clothing-3831829_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 2002,
				idProduct: 2,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2020/04/26/15/15/denim-5095731_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 2011,
				idProduct: 2,
				idColor: "RO",
				idSize: 2,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/22/12/49/clothing-3831829_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 2012,
				idProduct: 2,
				idColor: "AZ",
				idSize: 3,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/05/23/10/53/t-shirt-design-2336850_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 2013,
				idProduct: 2,
				idColor: "NE",
				idSize: 2,
				descriptionColor: "negro",
				colorHexa: "#000000",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://media.istockphoto.com/photos/portrait-of-happy-handsome-asian-young-man-attractive-in-fashionable-picture-id1289201912?b=1&k=20&m=1289201912&s=170667a&w=0&h=vG7Qy8TnMf6V45Lb3nGSaqrKG68CoaXNJf4dlvccaoo=",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 3,
		name: "campera athens",
		img: "https://cdn.pixabay.com/photo/2018/01/11/09/40/woman-3075710_960_720.jpg",
		description: "campera athens. rompeviento por fuera, corderito por dentro",
		price: 5280,
		category: "abrigos",
		components: [
			{
				idComponent: 3001,
				idProduct: 3,
				idColor: "BL",
				idSize: 1,
				descriptionColor: "blanco",
				colorHexa: "#ffffff",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2019/08/14/15/05/model-4405951_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 3002,
				idProduct: 3,
				idColor: "VE",
				idSize: 1,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/guapo-barbudo-sonriente-feliz-joven-mirando-al-frente-aislado-vivo-estudio-verde-moda_155003-29270.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 3011,
				idProduct: 3,
				idColor: "BL",
				idSize: 2,
				descriptionColor: "blanco",
				colorHexa: "#ffffff",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2019/08/14/15/05/model-4405951_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 3012,
				idProduct: 3,
				idColor: "VE",
				idSize: 2,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/guapo-barbudo-sonriente-feliz-joven-mirando-al-frente-aislado-vivo-estudio-verde-moda_155003-29270.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 3013,
				idProduct: 3,
				idColor: "AM",
				idSize: 3,
				descriptionColor: "amarillo",
				colorHexa: "#fdfd96",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "remera stay true",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/01/11/09/40/woman-3075710_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 4,
		name: "remera basica maltinto",
		img: "https://cdn.pixabay.com/photo/2017/12/13/09/23/model-3016384_960_720.jpg",
		description:
			"remera basica maltinto. de calce slim fit. material 70% algodon - 30% poliester",
		price: 833,
		category: "remeras",
		components: [
			{
				idComponent: 4001,
				idProduct: 4,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera basica maltinto",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/10/02/22/17/red-t-shirt-1710578_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 4002,
				idProduct: 4,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera basica maltinto",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/05/23/10/53/t-shirt-design-2336850_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 4011,
				idProduct: 4,
				idColor: "RO",
				idSize: 2,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera basica maltinto",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/10/02/22/17/red-t-shirt-1710578_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 4012,
				idProduct: 4,
				idColor: "AZ",
				idSize: 4,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "XL",
				stock: 20,
				quantity: 0,
				name: "remera basica maltinto",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/05/23/10/53/t-shirt-design-2336850_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 4013,
				idProduct: 4,
				idColor: "GR",
				idSize: 3,
				descriptionColor: "gris",
				colorHexa: "#9c9c9c",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "remera basica maltinto",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/12/13/09/23/model-3016384_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 5,
		name: "short de baño full tropical",
		img: "https://cdn.pixabay.com/photo/2019/06/13/12/07/suit-4271440_960_720.jpg",
		description:
			"short de baño. cintura con cordon ajustable y bolsillos laterales",
		price: 867,
		category: "bermudas",
		components: [
			{
				idComponent: 5001,
				idProduct: 5,
				idColor: "VE",
				idSize: 1,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "short de baño full tropical",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/mujer-joven-vieja-usanza-bailando-aislado-verde_155003-43007.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 5002,
				idProduct: 5,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "short de baño full tropical",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/retrato-cuerpo-entero-chico-feliz-pelota-playa_171337-16893.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 5011,
				idProduct: 5,
				idColor: "NE",
				idSize: 2,
				descriptionColor: "negro",
				colorHexa: "#000000",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "short de baño full tropical",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/saltar-hombre-joven-moda-vieja-escuela-bailando-aislado-estudio-azul_155003-34002.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 5012,
				idProduct: 5,
				idColor: "VE",
				idSize: 3,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "short de baño full tropical",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/mujer-joven-vieja-usanza-bailando-aislado-verde_155003-43007.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 5013,
				idProduct: 5,
				idColor: "MA",
				idSize: 3,
				descriptionColor: "marrón",
				colorHexa: "#6c3b2a",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "short de baño full tropical",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2019/06/13/12/07/suit-4271440_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 6,
		name: "pantalon chino pekin",
		img: "https://media.istockphoto.com/photos/portrait-of-happy-handsome-asian-young-man-attractive-in-fashionable-picture-id1289201912?b=1&k=20&m=1289201912&s=170667a&w=0&h=vG7Qy8TnMf6V45Lb3nGSaqrKG68CoaXNJf4dlvccaoo=",
		description:
			"pantalon chino pekin. corte fit con dos bosillos laterales. material 97% algodon - 3% elastano",
		price: 3150,
		category: "pantalones",
		components: [
			{
				idComponent: 6001,
				idProduct: 6,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "pantalon chino pekin",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/22/12/49/clothing-3831829_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 6002,
				idProduct: 6,
				idColor: "RS",
				idSize: 1,
				descriptionColor: "rosa",
				colorHexa: "#ea899a",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "pantalon chino pekin",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/senora-top-blanco-panuelo-seda-mira-camara-sobre-fondo-rosa-mujer-ojos-verdes-pelo-corto-pantalones-rojos-blusa-ligera-mira-camara_197531-18036.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 6011,
				idProduct: 6,
				idColor: "NE",
				idSize: 2,
				descriptionColor: "negro",
				colorHexa: "#000000",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "pantalon chino pekin",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/sueter-rojo-jeans-ajustados-negros-chica-delgada-moderna-cabello-corto-morena-pie-estudio-sobre-fondo-blanco_343629-256.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 6012,
				idProduct: 6,
				idColor: "ROS",
				idSize: 2,
				descriptionColor: "rosa",
				colorHexa: "#ea899a",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "pantalon chino pekin",
				imgComponent: [
					{
						img: "https://image.freepik.com/foto-gratis/senora-top-blanco-panuelo-seda-mira-camara-sobre-fondo-rosa-mujer-ojos-verdes-pelo-corto-pantalones-rojos-blusa-ligera-mira-camara_197531-18036.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 6013,
				idProduct: 6,
				idColor: "MA",
				idSize: 4,
				descriptionColor: "marron",
				colorHexa: "#6c3b2a",
				descriptionSize: "XL",
				stock: 20,
				quantity: 0,
				name: "pantalon chino pekin",
				imgComponent: [
					{
						img: "https://media.istockphoto.com/photos/portrait-of-happy-handsome-asian-young-man-attractive-in-fashionable-picture-id1289201912?b=1&k=20&m=1289201912&s=170667a&w=0&h=vG7Qy8TnMf6V45Lb3nGSaqrKG68CoaXNJf4dlvccaoo=",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 7,
		name: "campera athens",
		img: "https://cdn.pixabay.com/photo/2018/01/11/09/40/woman-3075710_960_720.jpg",
		description: "campera athens. rompeviento por fuera, corderito por dentro",
		price: 5280,
		category: "abrigos",
		components: [
			{
				idComponent: 7001,
				idProduct: 7,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "campera athens",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/08/17/10/16/red-2650667_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 7002,
				idProduct: 7,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "campera athens",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/03/02/10/09/young-3192787_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 7011,
				idProduct: 7,
				idColor: "VE",
				idSize: 1,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "campera athens",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/11/22/10/51/girl-1848955_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 7012,
				idProduct: 7,
				idColor: "VE",
				idSize: 2,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "campera athens",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2016/11/22/10/51/girl-1848955_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 7013,
				idProduct: 7,
				idColor: "AM",
				idSize: 4,
				descriptionColor: "amarillo",
				colorHexa: "#fdfd96",
				descriptionSize: "XL",
				stock: 20,
				quantity: 0,
				name: "campera athens",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/01/11/09/40/woman-3075710_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
	{
		idProduct: 8,
		name: "remera release the mind",
		img: "https://cdn.pixabay.com/photo/2019/06/13/12/07/suit-4271440_960_720.jpg",
		description:
			"remera release the mind. de calce slim fit. material 70% algodon - 30% poliester",
		price: 833,
		category: "remeras",
		components: [
			{
				idComponent: 8001,
				idProduct: 8,
				idColor: "RO",
				idSize: 1,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera release the mind",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/30/07/10/woman-3847131_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 8002,
				idProduct: 8,
				idColor: "AZ",
				idSize: 1,
				descriptionColor: "azul",
				colorHexa: "#84b6f4",
				descriptionSize: "S",
				stock: 20,
				quantity: 0,
				name: "remera release the mind",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2017/06/15/11/32/model-2405071_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 8011,
				idProduct: 8,
				idColor: "RO",
				idSize: 2,
				descriptionColor: "rojo",
				colorHexa: "#ff6961",
				descriptionSize: "M",
				stock: 20,
				quantity: 0,
				name: "remera release the mind",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/30/07/10/woman-3847131_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 8012,
				idProduct: 8,
				idColor: "VE",
				idSize: 4,
				descriptionColor: "verde",
				colorHexa: "#77dd77",
				descriptionSize: "XL",
				stock: 20,
				quantity: 0,
				name: "remera release the mind",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2018/11/30/07/15/male-3847146_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
			{
				idComponent: 8013,
				idProduct: 8,
				idColor: "MA",
				idSize: 2,
				descriptionColor: "marron",
				colorHexa: "#6c3b2a",
				descriptionSize: "L",
				stock: 20,
				quantity: 0,
				name: "remera release the mind",
				imgComponent: [
					{
						img: "https://cdn.pixabay.com/photo/2019/06/13/12/07/suit-4271440_960_720.jpg",
					},
					{ img: "" },
					{ img: "" },
					{ img: "" },
				],
			},
		],
	},
];

const products = new Promise((resolve) => {
	resolve(imgGrid);
});

export default products;
