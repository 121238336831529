import PayMethods from "../../components/PayMethods/PayMethods";
import { useCart } from "../../context/Cart/CartContext";

function PayMethodsContainer() {
	const { cart } = useCart();
	return (
		<div>
			<PayMethods productsInCart={cart?.length} />
		</div>
	);
}

export default PayMethodsContainer;
