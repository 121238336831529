import { Fragment } from "react";
import {
	Box,
	Card,
	CardMedia,
	Grid,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Typography,
	TableHead,
	TableCell,
	Container,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
	getQuantityByProduct,
	getSizesByColor,
	formatter,
} from "../../utils/auxFunc";
import { useDiscount } from "../../context/Discount/DiscountContext";

const CartTableCell = withStyles({
	root: {
		borderBottom: "none",
	},
})(TableCell);

const CheckoutItemsDetail = ({ product }) => {
	const { payConditionDiscount } = useDiscount();
	const sizeAndQuantityByColor = getSizesByColor(product);
	const quantity = getQuantityByProduct(sizeAndQuantityByColor);

	return (
		<Fragment>
			<Container>
				<Box maxWidth="sm" margin="0 auto">
					<Grid container columnSpacing={1} my={2}>
						<Grid item xs={3} lg={2}>
							<Card sx={{ width: "100%", borderRadius: "5px" }}>
								<CardMedia
									component="img"
									alt="blablabla"
									image={product.img}
									pt={0}
								></CardMedia>
							</Card>
						</Grid>
						<Grid
							item
							display="flex"
							flexDirection="column"
							justifyContent="start"
							xs={6}
							lg={8}
						>
							<Typography
								variant="subtitle1"
								sx={{
									textTransform: "capitalize",
									lineHeight: 1.25,
									my: 0.5,
								}}
							>
								{product.name}
							</Typography>
							<Typography variant="caption">{quantity} artículos</Typography>
						</Grid>
						<Grid
							item
							display="flex"
							flexDirection="column"
							alignItems="flex-end"
							justifyContent="space-between"
							xs={3}
							lg={2}
						>
							<Box>
								<Typography
									variant="subtitle1"
									sx={{
										textTransform: "capitalize",
										lineHeight: 1.25,
										my: 0.5,
										textAlign: "end",
									}}
								>
									{formatter.format(
										quantity * product.price * (1 - payConditionDiscount)
									)}
								</Typography>
								<Typography
									variant="caption"
									sx={{
										textTransform: "capitalize",
									}}
								>
									un.{" "}
									{formatter.format(product.price * (1 - payConditionDiscount))}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
			<Container maxWidth="md">
				<TableContainer>
					<Table size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<CartTableCell align="left" width="25%">
									<Typography
										variant="captions"
										color={(theme) => theme.palette.light.main}
									>
										Color
									</Typography>
								</CartTableCell>
								<CartTableCell align="center" width="16%">
									<Typography
										variant="captions"
										color={(theme) => theme.palette.light.main}
									>
										Talle
									</Typography>
								</CartTableCell>
								<CartTableCell align="center" width="43%">
									<Typography
										variant="captions"
										color={(theme) => theme.palette.light.main}
									>
										Unidades
									</Typography>
								</CartTableCell>
								<CartTableCell align="center" width="16%"></CartTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sizeAndQuantityByColor?.map((element) => {
								// con este map genero la tabla para cada color de un producto
								let rowSpan = element.types.length;
								let firstRow = element.types.slice(0, 1);
								/* Genero una copia solo del primer elemento del array, para utilizarlo 
									en la primer fila y utilizar el rowSpan, para que el color incluya todas 
									las filas de sus colores */
								let restRows = element.types.slice(1);
								/* Genero una copia del array excluyendo el primer elemento, para 
									continuar completando las filas de colores de productos */
								//Genero primer fila con rowspan de color para ocupar mas filas
								return (
									// Con firstRow[0] accedo al objeto en la posición 0 del array
									<Fragment key={firstRow[0].idComponent}>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<CartTableCell
												scope="row"
												width="25%"
												rowSpan={rowSpan}
												sx={{ verticalAlign: "text-top" }}
											>
												<Typography
													variant="subtitle1"
													color="initial"
													sx={{
														fontSize: "14px",
														display: "flex",
														alignItems: "flex-start",
														textTransform: "capitalize",
													}}
												>
													{element.descriptionColor}
												</Typography>
											</CartTableCell>
											<CartTableCell align="center" width="16%">
												{firstRow[0].size}
											</CartTableCell>
											<CartTableCell align="center" width="43%">
												{firstRow[0].quantity} Un.
											</CartTableCell>
										</TableRow>

										{restRows?.map((row) => {
											//Genero resto de filas con la copia del array
											return (
												<TableRow
													key={row.idComponent}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<CartTableCell align="center" width="16%">
														{row.size}
													</CartTableCell>
													<CartTableCell align="center" width="43%">
														{row.quantity} Un.
													</CartTableCell>
												</TableRow>
											);
										})}
									</Fragment>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</Fragment>
	);
};

export default CheckoutItemsDetail;
