import {
	Box,
	Container,
	IconButton,
	Tooltip,
	Typography,
	Fade,
	Checkbox,
	Button,
	Divider,
	Slider,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FilterButton } from "../FilterButtons/FilterButton";
import { CheckedFilterButton } from "../FilterButtons/CheckedFilterButton";
import { useFilter } from "../../context/FilterContext/FilterContext";
import { formatter } from "../../utils/auxFunc";
import "./Filter.css";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";

const labelTalles = {
	inputProps: { "aria-label": "filtro de talles" },
};

const labelColores = {
	inputProps: { "aria-label": "filtro de colores" },
};

const labelCalces = {
	inputProps: { "aria-label": "filtro de calces" },
};

const useStyles = makeStyles((theme) => ({
	arrows: {
		"&.MuiSvgIcon-root": { opacity: "30%", cursor: "pointer" },
		"&:hover, &.Mui-focusVisible": { opacity: "100%" },
	},
}));

const valueRendered = (value) => {
	return formatter.format(value);
};

const ElDonSlider = styled(Slider)({
	color: "#292929",
	height: 2,
	"& .MuiSlider-track": {
		border: "none",
		height: "3px",
	},
	"& .MuiSlider-rail": {
		height: "1px",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#fafafa",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#292929",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
});

const Filter = ({ toggleFilterMenu }) => {
	const {
		filter,
		sizeLetter,
		colors,
		fits,
		toggleSizeLettersCheckboxes,
		toggleColorsCheckboxes,
		toggleFitsCheckboxes,
		handleSliderChange,
		applyFilter,
		resetFilter,
	} = useFilter();

	const classes = useStyles();
	// const [sizeScroll, colorScroll] = useHorizontalScroll(2);
	const [
		sizeRef,
		onMouseDownSize,
		onMouseUpSize,
		handleMouseMoveSize,
		sliderLeftSize,
		sliderRightSize,
	] = useHorizontalScroll();
	const [
		colorRef,
		onMouseDownColor,
		onMouseUpColor,
		handleMouseMoveColor,
		sliderLeftColor,
		sliderRightColor,
	] = useHorizontalScroll();

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				backgroundColor: (theme) => theme.palette.primary.main,
			}}
			style={{ paddingBottom: 200 }}
		>
			<Container
				maxWidth="xs"
				sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					minHeight="56px"
					mb={2}
				>
					<Typography variant="h5" color="secondary">
						Filtrar por
					</Typography>
					<Tooltip
						title="Cerrar"
						placement="bottom"
						TransitionComponent={Fade}
						TransitionProps={{ timeout: 600 }}
						PopperProps={{ sx: { zIndex: 99999999 } }}
					>
						<IconButton
							aria-label="cerrar filtro"
							size="large"
							onClick={() => {
								toggleFilterMenu();
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Box>
				<Typography
					variant="subtitle1"
					color="secondary"
					sx={{ textTransform: "uppercase", marginBottom: "0.5rem" }}
				>
					talles
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<KeyboardArrowLeftIcon
						className={classes.arrows}
						sx={{ display: { xs: "none", md: "flex" }, marginBottom: "8px" }}
						onClick={sliderLeftSize}
					/>
					<Box
						className="slider"
						mb={1}
						ref={sizeRef}
						onMouseDown={onMouseDownSize}
						onMouseUp={onMouseUpSize}
						onMouseMove={(evt) => handleMouseMoveSize(evt)}
					>
						{sizeLetter?.map((size, index) => {
							return (
								<Checkbox
									key={size.idSize}
									{...labelTalles}
									icon={<FilterButton content={size.value} />}
									name={size.descripcion}
									value={size.value}
									checked={filter.sizeLetter[index]?.isChecked} // para tomar el valor guardado en filter y controlar el estado
									checkedIcon={<CheckedFilterButton content={size.value} />}
									sx={{ margin: "5px 8px", padding: 0 }}
									onChange={(e) => toggleSizeLettersCheckboxes(e, index)}
								/>
							);
						})}
					</Box>
					<KeyboardArrowRightIcon
						className={classes.arrows}
						sx={{ display: { xs: "none", md: "flex" }, marginBottom: "8px" }}
						onClick={sliderRightSize}
					/>
				</Box>
				<Divider />
				<Typography
					variant="subtitle1"
					color="secondary"
					mt={3}
					mb={1}
					sx={{ textTransform: "uppercase" }}
				>
					colores
				</Typography>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<KeyboardArrowLeftIcon
						className={classes.arrows}
						sx={{ display: { xs: "none", md: "flex" }, marginBottom: "8px" }}
						onClick={sliderLeftColor}
					/>
					<Box
						className="slider"
						mb={1}
						ref={colorRef}
						onMouseDown={onMouseDownColor}
						onMouseUp={onMouseUpColor}
						onMouseMove={(evt) => handleMouseMoveColor(evt)}
					>
						{colors?.map((color, index) => {
							return (
								<Tooltip
									key={color.idColor}
									title={color.description}
									placement="bottom"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									PopperProps={{ sx: { zIndex: 99999999 } }}
									sx={{
										textTransform: "capitalize",
										fontSize: "1rem",
									}}
								>
									<Checkbox
										{...labelColores}
										icon={<FilterButton colorOption={`#${color.hex}`} />}
										name={color.description}
										value={color.idColor}
										checked={filter.color[index]?.isChecked} // para tomar el valor guardado en filter y controlar el estado
										checkedIcon={
											<CheckedFilterButton
												content={<CheckIcon />}
												colorOption={color.hex}
											/>
										}
										sx={{ padding: "5px" }}
										onChange={(e) => toggleColorsCheckboxes(e, index)}
									/>
								</Tooltip>
							);
						})}
					</Box>
					<KeyboardArrowRightIcon
						className={classes.arrows}
						sx={{ display: { xs: "none", md: "flex" }, marginBottom: "8px" }}
						onClick={sliderRightColor}
					/>
				</Box>

				{/* <-------------FILTRO CALCE-------------------------------------------------------------------------->  */}
				<Divider />
				<Typography
					mt={3}
					mb={1}
					variant="subtitle1"
					color="secondary"
					sx={{ textTransform: "uppercase", marginBottom: "0.5rem" }}
				>
					calce
				</Typography>
				<Box className="slider" mb={1}>
					{fits?.map((fit, index) => {
						return (
							<Checkbox
								key={fit.idFit}
								{...labelCalces}
								icon={
									<FilterButton
										content={
											<p style={{ fontSize: 9, fontWeight: "bold" }}>
												{fit.fitDescription}
											</p>
										}
										isCalceButton={true}
									/>
								}
								name={fit.fitDescription}
								value={fit.idFit}
								checked={filter.fit[index]?.isChecked} // para tomar el valor guardado en filter y controlar el estado
								checkedIcon={
									<CheckedFilterButton
										content={
											<p style={{ fontSize: 9, fontWeight: "bold" }}>
												{fit.fitDescription}
											</p>
										}
										isCalceButton={true}
									/>
								}
								sx={{ padding: "5px" }}
								onChange={(e) => toggleFitsCheckboxes(e, index)}
							/>
						);
					})}
				</Box>
				<Divider />
				<Typography
					variant="subtitle1"
					color="secondary"
					mt={3}
					mb={1}
					sx={{ textTransform: "uppercase" }}
				>
					precio
				</Typography>
				<Box mb={1}>
					<ElDonSlider
						color="secondary"
						getAriaLabel={() => "Rango de precios"}
						min={500}
						step={50}
						max={15000}
						value={filter.price}
						onChange={handleSliderChange}
						valueLabelDisplay="auto"
						getAriaValueText={valueRendered}
					/>
				</Box>
				<Box display="flex" justifyContent="space-between">
					<Typography
						variant="subtitle1"
						color="secondary"
						mt={1}
						mb={1}
						sx={{ textTransform: "capitalize" }}
					>
						desde {formatter.format(filter.price[0])}
					</Typography>
					<Typography
						variant="subtitle1"
						color="secondary"
						mt={1}
						mb={1}
						sx={{ textTransform: "capitalize" }}
					>
						hasta {formatter.format(filter.price[1])}
					</Typography>
				</Box>
				<Container maxWidth="xs">
					<Box className="filterButtons">
						<Button
							variant="contained"
							color="primary"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
								border: "1px solid #292929",
								width: "50%",
								margin: "1rem 0.5rem",
								padding: "1rem",
								textTransform: "capitalize",
							}}
							onClick={resetFilter}
						>
							Borrar
						</Button>
						<Button
							variant="contained"
							color="secondary"
							sx={{
								fontSize: "1rem",
								fontWeight: "600",
								border: "1px solid #292929",
								width: "50%",
								margin: "1rem 0.5rem",
								padding: "1rem",
								textTransform: "capitalize",
							}}
							onClick={applyFilter}
						>
							Aplicar
						</Button>
					</Box>
				</Container>
			</Container>
		</Box>
	);
};

export default Filter;
