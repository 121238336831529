export const sendShippingMethod = async (
  apiInstance,
  clientId,
  method,
  address,
  date,
  data
) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/ChangeSelectedShippingMethod/${clientId}/${method}/${address}?fechaRetiro=${date}`,
    requestParameters
  )
}

export const getPaymentMethods = async (apiInstance, clientId) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(
    `${apiInstance.apiURL}/PaymentConditions/GetPaymentConditions`,
    requestParameters
  )
}

export const sendPaymentMethod = (apiInstance, method, discount, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(
    `${apiInstance.apiURL}/Cart/ChangeSelectedPaymentType?paymentType=${method}&discount=${discount}`,
    requestParameters
  )
}

export const confirmOrder = async (apiInstance, data) => {
  let requestParameters = {
    method: 'POST',
    mode: 'cors',
    headers: apiInstance.headers,
    body: JSON.stringify(data)
  }

  return fetch(`${apiInstance.apiURL}/Cart/Checkout`, requestParameters)
}
