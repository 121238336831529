import { Box } from "@mui/material";
import Spinner from "../../components/Spinner";
import "./SpinnerContainer.css";

const SpinnerContainer = ({ loading }) => {
	return (
		<Box className="spinnerContainer">
			<Spinner loading={loading} />
		</Box>
	);
};

export default SpinnerContainer;
