import React from "react";
import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useTimer } from "../../context/TimerContext/TimerContext";
import { useCart } from "../../context/Cart/CartContext";
import { Link } from "react-router-dom";
import "./HeaderContainer.css";

const HeaderContainer = ({ title, handleReturn }) => {
	const { timer } = useTimer();
	const { cart } = useCart();
	return (
		<Container>
			<Box display="flex" alignItems="center" minHeight="64px">
				<Box>
					<Tooltip title="Volver">
						<IconButton
							size="large"
							aria-label="volver"
							aria-controls="volver-carrito"
							aria-haspopup="false"
							color="inherit"
							onClick={handleReturn}
						>
							<ArrowBackIosNewIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<Typography
					variant="h5"
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexGrow={1}
				>
					{title}
				</Typography>
				<Box className="timerContainer">
					<Box className="timerCart">
						<Typography variant="subtitle1" color="initial">
							{timer}
						</Typography>
					</Box>
					<Tooltip title="Carrito">
						<Link to="/cart">
							<IconButton
								size="large"
								aria-label="carrito"
								aria-controls="logo-carrito"
								aria-haspopup="false"
								color="inherit"
							>
								{cart?.length > 0 ? (
									<ShoppingBagIcon color="secondary" />
								) : (
									<ShoppingBagOutlinedIcon color="secondary" />
								)}
							</IconButton>
						</Link>
					</Tooltip>
				</Box>
			</Box>
		</Container>
	);
};

export default HeaderContainer;
