export const loginUser = (apiInstance, user, password) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }
  return fetch(
    `${apiInstance.apiURL}/Security/GetLogin/${user}/${password}`,
    requestParameters
  )
}

export const registerUser = (apiInstance, user, password) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }
  return fetch(
    `${apiInstance.apiURL}/Security/GetLogin/${user}/${password}`,
    requestParameters
  )
}
