import { Button, Divider, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./UserConfig.css";

const UserConfig = ({ startIcon, endIcon, title, href }) => {
	return (
		<Fragment>
			<Link to={href} className="config-items">
				<Button
					color="secondary"
					startIcon={startIcon}
					endIcon={endIcon}
					fullWidth
					sx={{ textTransform: "none", padding: "1rem" }}
				>
					<Typography
						variant="body1"
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						flexGrow={1}
						sx={{ fontWeight: "400" }}
					>
						{title}
					</Typography>
				</Button>
			</Link>
			<Divider />
		</Fragment>
	);
};

export default UserConfig;
