import { Fragment } from "react";
import { Box, Container, Divider, Typography, Button } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useConfig } from "../../context/Config/ConfigContext";
import { useCart } from "../../context/Cart/CartContext";
import { useTimer } from "../../context/TimerContext/TimerContext";
import CartItemList from "../../components/CartItemList/CartItemList";
import { formatter } from "../../utils/auxFunc";
import HeaderContainer from "../HeaderContainer/HeaderContainer";

const CartContainer = () => {
	const { config } = useConfig();
	const {
		cart,
		// timer,
		// initTimer,
		addToComponent,
		removeToComponent,
		removeByProductId,
		removeByComponentId,
		clear,
		getTotalAmount,
	} = useCart();
	const { startTimer } = useTimer();

	let navigate = useNavigate();

	const handleReturn = () => {
		navigate("/");
	};

	return (
		<Fragment>
			<HeaderContainer handleReturn={handleReturn} title="Carrito" />
			<Divider />
			{cart?.length > 0 ? (
				<Fragment>
					<Container>
						{cart?.map((product) => {
							return (
								<CartItemList
									product={product}
									removeByProductId={removeByProductId}
									removeByComponentId={removeByComponentId}
									addToComponent={addToComponent}
									removeToComponent={removeToComponent}
									key={product.idProduct}
								/>
							);
						})}
					</Container>
					<Container maxWidth="xs" sx={{ marginBottom: "2rem" }}>
						{cart?.length > 1 && (
							<Button
								variant="outlined"
								color="secondary"
								fullWidth
								onClick={clear}
								sx={{ textTransform: "capitalize", mt: 4 }}
							>
								Vaciar carrito
							</Button>
						)}
					</Container>
					<Box>
						<Divider />
						<Container maxWidth="sm">
							<Box display="flex" justifyContent="space-between" py="1rem">
								<Typography
									variant="body1"
									color="secondary"
									sx={{ fontWeight: "bold" }}
								>
									Subtotal:
								</Typography>
								<Typography
									variant="body1"
									color="secondary"
									sx={{ fontWeight: "bold" }}
								>
									{formatter.format(getTotalAmount())}
								</Typography>
							</Box>
							<Container maxWidth="sm">
								<NavLink to="/cart/checkout" style={{ textDecoration: "none" }}>
									<Button
										variant="contained"
										color="secondary"
										fullWidth
										sx={{ textTransform: "capitalize", marginBottom: "1rem" }}
										onClick={() => startTimer(config.CHECKOUT_TIMER)}
									>
										Iniciar Compra
									</Button>
								</NavLink>
							</Container>
						</Container>
					</Box>
				</Fragment>
			) : (
				<Container>
					<Box
						height="calc(100vh - 50px)"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h4" fontSize="2rem" color="initial" mb={2}>
							Tu carrito está vacío!
						</Typography>
						<Link to="/" style={{ textDecoration: "none" }}>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								sx={{ textTransform: "capitalize" }}
							>
								Buscar productos
							</Button>
						</Link>
					</Box>
				</Container>
			)}
		</Fragment>
	);
};

export default CartContainer;
