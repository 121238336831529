import {
	Box,
	Container,
	Divider,
	Fade,
	IconButton,
	Tooltip,
	Typography,
	Button,
	ButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Sort = ({ toggleSortMenu, handleSort, sortValue }) => {
	const buttons = [
		<Button
			key="1"
			value="relevance"
			fullWidth
			sx={{
				color: "black",
				borderRadius: 0,
				backgroundColor: sortValue === "relevance" ? "#E0DDDC" : "transparent",
			}}
			onClick={(evt) => handleSort(evt)}
		>
			Más relevantes
		</Button>,
		<Button
			key="2"
			value="higherPrice"
			fullWidth
			sx={{
				color: "black",
				borderRadius: 0,
				backgroundColor:
					sortValue === "higherPrice" ? "#E0DDDC" : "transparent",
			}}
			onClick={(evt) => handleSort(evt)}
		>
			Mayor precio
		</Button>,
		<Button
			key="3"
			value="lowerPrice"
			fullWidth
			sx={{
				color: "black",
				borderRadius: 0,
				backgroundColor: sortValue === "lowerPrice" ? "#E0DDDC" : "transparent",
			}}
			onClick={(evt) => handleSort(evt)}
		>
			Menor precio
		</Button>,
	];

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				backgroundColor: (theme) => theme.palette.primary.main,
			}}
		>
			<Container
				maxWidth="xs"
				sx={{
					height: "100%",
					backgroundColor: (theme) => theme.palette.primary.main,
				}}
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					minHeight="56px"
					mb={2}
				>
					<Typography variant="h5" color="secondary">
						Ordenar por
					</Typography>
					<Tooltip
						title="Cerrar"
						placement="bottom"
						TransitionComponent={Fade}
						TransitionProps={{ timeout: 600 }}
					>
						<IconButton
							aria-label="cerrar panel de orden"
							size="large"
							sx={{ padding: "8px 0" }}
							onClick={() => {
								toggleSortMenu();
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Box>
				<Divider />
				<Box>
					<ButtonGroup
						orientation="vertical"
						aria-label="botón para ordenar productos por relevancia"
						variant="contained"
						fullWidth
						sx={{ mt: 4 }}
						onClick={() => {
							toggleSortMenu();
						}}
					>
						{buttons}
					</ButtonGroup>
				</Box>
			</Container>
		</Box>
	);
};

export default Sort;
