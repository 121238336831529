import React, { useRef } from "react";
import { Box, Container } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useProducts } from "../../context/Products/ProductsContext";
import "./CategoriesSlider.css";
import { useFilter } from "../../context/FilterContext/FilterContext";

const useStyles = makeStyles(() => ({
	arrows: {
		"&.MuiSvgIcon-root": { opacity: "30%", cursor: "pointer" },
		"&:hover, &.Mui-focusVisible": { opacity: "100%" },
	},
}));

const CategoriesSlider = () => {
	const { categories } = useProducts();
	const { resetFilter } = useFilter();
	const slider = useRef();
	const classes = useStyles();

	const slideLeft = () => {
		slider.current.scrollLeft -= 350;
	};

	const slideRight = () => {
		slider.current.scrollLeft += 350;
	};

	return (
		<Container maxWidth="xl">
			<Box className="sliderCategoriesContainer">
				<KeyboardArrowLeftIcon
					className={classes.arrows}
					sx={{ display: { xs: "none", md: "flex", fontSize: "3rem" } }}
					onClick={slideLeft}
				/>
				<Box
					className="sliderCategories"
					ref={slider}
					id="slider"
					sx={{ display: "flex" }}
				>
					{categories?.map((category) => (
						<Box key={category.idCategory} className="categoryContainer">
							<Link
								to={`/category/${category.idCategory}`}
								className="sliderCategoryImgContainer"
								onClick={resetFilter}
							>
								<img
									className="sliderCategoryImg"
									src={category.imageURL}
									alt={category.name}
								/>
							</Link>
							<p className="sliderCategoryName">{category.name}</p>
						</Box>
					))}
				</Box>

				<KeyboardArrowRightIcon
					className={classes.arrows}
					sx={{ display: { xs: "none", md: "flex", fontSize: "3rem"  } }}
					onClick={slideRight}
				/>
			</Box>
		</Container>
	);
};

export default CategoriesSlider;
