export const getCategories = async (apiInstance, signal) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Categories`, {
    ...requestParameters,
    signal
  })
}

export const getNewIn = (apiInstance, id, signal) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(
    `${apiInstance.apiURL}/Products/GetByNewIn?idListaPrecios=${id}`,
    { ...requestParameters, signal }
  )
}

export const getProductsByCategory = async (apiInstance, url, signal) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/GetByCategory/${url}`, {
    ...requestParameters,
    signal
  })
}

export const getProductsById = async (
  apiInstance,
  idClient,
  idProduct,
  signal
) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/${idClient},${idProduct}`, {
    ...requestParameters,
    signal
  })
}

export const getProductsSearched = async (apiInstance, url, signal) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/Search/${url}`, {
    ...requestParameters,
    signal
  })
}

export const getColors = async (apiInstance) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/GetColores`, requestParameters)
}

export const getSizes = async (apiInstance) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/GetTalles`, requestParameters)
}

export const getFits = async (apiInstance) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Products/GetCalces`, requestParameters)
}
