const REGEX = {
	// eslint-disable-next-line no-useless-escape
	emailFormatValidator: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	textFormatValidator: /^[a-zA-ZÀ-ÿ\s]{2,20}$/,
	passwordFormatValidator: /^\d{8}$/,
	phoneFormatValidator: /^\d{8,10}$/,
	cuitFormatValidator: /^\d{11}$/,
	companyNameFormatValidator: /^[0-9a-zA-Z]+$/,
	nameandaddressshippingFormatValidator: /^[0-9a-zA-ZÀ-ÿ\s]{2,20}$/,
};

export default REGEX;
