import { Box, Divider, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import SpinnerContainer from "../../containers/SpinnerContainer";
import { useAuth } from "../../context/Auth/AuthContext";
import { api } from "../../services/api";
import OrderItem from "../OrderItem/OrderItem";

const ClosedOrders = () => {
	const [orders, setOrders] = useState(null);
	const [loading, setLoading] = useState(true);
	const { userData } = useAuth();

	const finishedOrderCode = "500";

	// const sortOrders = (feature) => {
	// 	orders.sort();
	// };

	// const orderBy = (evt) => {
	// 	switch (evt.target.name) {
	// 		case "menor precio":
	// 			orders.sort((a, b) => a.amount - b.amount);
	// 			break;
	// 		case "mayor precio":
	// 			orders.sort((a, b) => b.amount - a.amount)
	// 			break;
	// 		default:
	// 			setOrders(closedOrders);
	// 	}
	// };

	useEffect(() => {
		api.orders
			.getLatestOrders(userData.clientId, finishedOrderCode)
			.then((response) => response.json())
			.catch((err) => console.log(err))
			.then((ordersAPI) => {
				setOrders(ordersAPI);
				setLoading(false);
			})
			.catch((err) => console.log(err));

		return () => {
			setLoading(true);
		};
	}, [userData]);

	if (loading) {
		<SpinnerContainer loading={loading} />;
	}

	return (
		<Fragment>
			{/* <Box display="flex" justifyContent="space-between">
				<Button color="secondary" sx={{ textTransform: "none" }}>
					Ordenar
				</Button>
				<Button color="secondary" sx={{ textTransform: "none" }}>
					Filtros
				</Button>
			</Box> */}
			<Divider />
			{orders?.length > 0 ? (
				orders?.map((order) => {
					return (
						<OrderItem
							key={order.idOrder}
							orderDate={order.orderDate}
							state={order.state}
							orderItems={order.orderItems}
							withdrawalPoint={order.withdrawalPoint}
							isPaid={order.isPaid}
						/>
					);
				})
			) : (
				<Box
					sx={{
						minHeight: "calc(100vh - 560px)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" sx={{ color: "#5E5E5E" }}>
						No tiene pedidos finalizados
					</Typography>
				</Box>
			)}
		</Fragment>
	);
};

export default ClosedOrders;
