import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useCart } from '../Cart/CartContext'

const TimerContext = createContext()

export const useTimer = () => useContext(TimerContext)

export const TimerContextProvider = ({ children }) => {
  const [timer, setTimer] = useState(null)
  const { cart, clear, minutes } = useCart()
  const timeInterval = useRef()

  const startTimer = useCallback(
    (minutes) => {
      clearInterval(timeInterval.current)
      let eventTime = moment().add(minutes, 'm').add(2, 's')
      const interval = 1000

      timeInterval.current = setInterval(countdown, interval)

      function countdown() {
        const now = moment()
        const duration = moment.utc(eventTime?.diff(now)).format('mm:ss')

        if (duration.includes('00:00')) {
          clearInterval(timeInterval.current)
          setTimer(null)
          clear()
        } else if (duration.includes('00:45')) {
          Swal.fire({
            icon: 'info',
            text: 'Te quedan 45 segundos para iniciar el checkout',
            showConfirmButton: true,
            focusConfirm: false,
            iconColor: '#64B5F6',
            color: '#FAFAFA',
            background: '#292929',
            confirmButtonColor: '#292929'
          })
          setTimer(duration)
        } else {
          setTimer(duration)
        }
      }
    },
    [clear]
  )

  useEffect(() => {
    if (cart.length > 0) {
      minutes && startTimer(minutes)
    } else {
      clearInterval(timeInterval.current)
      setTimer(null)
    }
    return () => {
      clearInterval(timeInterval.current)
    }
  }, [cart, minutes])

  return (
    <TimerContext.Provider value={{ timer, startTimer }}>
      {children}
    </TimerContext.Provider>
  )
}
