import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthContext";

const DiscountContext = createContext();

export const useDiscount = () => useContext(DiscountContext);

export const DiscountProvider = ({ children }) => {
	const { auth, userData } = useAuth();
	const [payConditionDiscount, setPayConditionDiscount] = useState(0);

	useEffect(() => {
		if (auth && userData?.payConditionDiscount !== null) {
			setPayConditionDiscount(userData?.payConditionDiscount / 100);
		}
	}, [auth, userData?.payConditionDiscount]);

	return (
		<DiscountContext.Provider
			value={{
				payConditionDiscount,
				setPayConditionDiscount,
			}}
		>
			{children}
		</DiscountContext.Provider>
	);
};
