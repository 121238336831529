import { styled } from '@mui/system';
import './SizeGuide.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Root = styled('div')(
    ({ theme }) => `
    table {
        font-family: Quicksand, sans-serif;
        font-size: 1rem;
        border-collapse: collapse;
        width: 100%;
    }
    td,
    th {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        text-align: left;
        padding: 6px;
    }
    th {
        background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
    }
    `,
);

const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

function SizeGuide({sizeTable, nameCategory}){

    const navigate = useNavigate();

    return(
        <center>
            <div className='containerTitleSizeGuide'>
                <button className='btnBackSizeGuide' onClick={()=>navigate(-1)} ><ArrowBackIcon className='iconSizeGuide'/></button>
                <p className='titleSizeGuide'>Guia de talles de {nameCategory && nameCategory}</p>
            </div>
            <Root sx={{ width: 400, maxWidth: '100%' }}>
                <table className='tableSizeGuide'  aria-label="custom pagination table">
                    <thead>
                        <tr> 
                            <th className='titleColumn'>Talle</th>
                            <th className='titleColumn'>Ancho</th>
                            <th className='titleColumn'>Largo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sizeTable.map((row) => (
                            <tr key={row.id}>
                                <td className='textSize'>{row.size}</td>
                                <td style={{ width: 120 }} align="right">
                                    {row.width}
                                </td>
                                <td style={{ width: 120 }} align="right">
                                    {row.length}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Root>
        </center>
    )
}

export default SizeGuide;

