import { Category } from "../entities/category";
import { Component, Product } from "../entities/product";

export const setCookie = (cName, value, validityDays) => {
	const today = new Date();
	const validityMiliSeconds = validityDays * 24 * 60 * 60 * 1000;
	const expirationDate = new Date(
		today.getTime() + validityMiliSeconds
	).toUTCString();
	document.cookie = `${cName}=${value};expires=${expirationDate}`;
};

export const getCookie = (cookieName) => {
	const allCookies = document.cookie.split(";");
	let searchedCookie;
	allCookies.forEach((cookie) => {
		if (cookie.includes(cookieName)) searchedCookie = cookie;
	});
	const indexOfValue = searchedCookie?.indexOf("=") + 1;
	const value = searchedCookie?.substring(indexOfValue);

	return value;
};

export const deleteCookie = (cookieName) => {
	document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
// Formatear números a pesos argentinos
export const formatter = new Intl.NumberFormat("es-AR", {
	style: "currency",
	currency: "ARS",
	minimumFractionDigits: 2,
});
// generar instancias de Categories
export const normalizedCategories = (categoriesArray) => {
	return categoriesArray.map((category) => {
		const url = category.routeURL.toLowerCase();
		const normalizedCategory = new Category(
			category.idCategory,
			category.name,
			category.imageURL,
			url
		);
		return normalizedCategory;
	});
};

// generar instancias de Product
export const normalizedProducts = (productsArray) => {
	return productsArray.map((product) => {
		const normalizedProduct = new Product(
			product.idProduct,
			product.description,
			product.img,
			product.description,
			product.price,
			product.category
		);
		let components = product.components;
		components.forEach((component) => {
			const normalizedComponent = new Component(
				component.idComponent,
				product.idProduct,
				component.descriptionColor,
				component.descriptionSize,
				component.stock,
				component.quantity,
				component.name,
				component.imgComponent
			);
			normalizedProduct.components.push(normalizedComponent);
		});

		return normalizedProduct;
	});
};
// Obtener array de objetos por color, con talle y cantidad
export const getSizesByColor = (product) => {
	const sizesByColor = [];

	product?.components?.forEach((component) => {
		if (
			!sizesByColor.find(
				(element) => element.descriptionColor === component.descriptionColor
			)
		) {
			sizesByColor.push({
				descriptionColor: component.descriptionColor,
				colorHexa: component.colorHexa,
				img: component.imgComponent[0].img,
				imgC: component.imgComponent,
				types: [
					{
						idComponent: component.idComponent,
						idProduct: product.idProduct,
						size: component.descriptionSize,
						stock: component.stock,
						quantity: component.quantity,
					},
				],
			});

			if (component.imgComponent) {
				const index = sizesByColor.findIndex(
					(element) => component.descriptionColor === element.descriptionColor
				);
				sizesByColor[index] = {
					...sizesByColor[index],
					img: component.imgComponent[0].img,
					imgC: component.imgComponent,
				};
				return sizesByColor;
			}
		} else {
			const index = sizesByColor.findIndex(
				(element) => element.descriptionColor === component.descriptionColor
			);
			const newType = [
				...sizesByColor[index].types,
				{
					idComponent: component.idComponent,
					idProduct: product.idProduct,
					size: component.descriptionSize,
					stock: component.stock,
					quantity: component.quantity,
				},
			];

			sizesByColor[index].types = newType;
		}
	});

	return sizesByColor;
};
// Obtener cantidad de articulos de un tipo en particular
export const getQuantityByProduct = (products) => {
	return products.reduce(
		(acc, product) =>
			acc +
			product.types.reduce((acc, type) => {
				return acc + type.quantity;
			}, 0),
		0
	);
};
// Obtener colores de un producto
export const getColors = (product) => {
	const colors = [];
	product.components?.forEach((component) => {
		if (
			!colors.find((element) => element.color === component.descriptionColor)
		) {
			colors.push({
				color: component.descriptionColor,
				colorHexa: component.colorHexa,
				description: product.description,
				img: component.imgComponent[0].img,
			});
		}
	});
	return colors;
};
// Obtiene el producto en cart que coincida con el del ItemDetailContainer
export const productInCart = (cart, id) => {
	cart?.find((element) => element.idProduct === parseInt(id));
};
// Obtiene todos los idComponents con sus quantities
export const getComponentsWithQuantity = (components) => {
	const componentsWithQuantity = [];

	components?.forEach((component) =>
		componentsWithQuantity.push({
			idComponent: component.idComponent,
			quantity: component.quantity,
		})
	);
	return componentsWithQuantity;
};

export const getCarrierId = (carriers, carrier) => {
	const carrierSelected = carriers.find(
		(carrierList) => carrierList.name.toLowerCase() === carrier.toLowerCase()
	);

	return carrierSelected.id;
};

export const calculateIVA = (total, percentage) => {
	const iva = 0.105;
	return total * (percentage / 100) * iva;
};

export const getOrderTotalAmount = (products) => {
	const amount = products.reduce((acc, product) => {
		return acc + product.price * product.quantity;
	}, 0);

	return amount;
};

export const getOrderDate = (date) => {
	return date.getDate();
};

export const getMonth = (date) => {
	return date.getMonth();
};
