import { useCallback, useEffect, useRef } from 'react'
import { createContext, useContext, useState } from 'react'
import { setApiURL } from '../../services/api'
import SpinnerContainer from '../../containers/SpinnerContainer'
import Spinner from '../../components/Spinner'

const ConfigContext = createContext()
export const useConfig = () => useContext(ConfigContext)

export const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState()
  let run = useRef()

  const throttle = useCallback((cb, delay) => {
    return function () {
      if (!run.current) {
        cb()
        run.current = true
        setTimeout(() => (run.current = false), delay)
      }
    }
  }, [])

  const getConfig = async () => {
    fetch('/appsettings.json')
      .then((res) => res.json())
      .then((configData) => {
        const { apiURL } = configData
        if (process.env.NODE_ENV !== 'development') {
          setApiURL(apiURL)
        }
        setConfig(configData)
        return apiURL
      })
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <ConfigContext.Provider value={{ config, throttle }}>
      {config ? (
        children
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </ConfigContext.Provider>
  )
}

export default ConfigContext
