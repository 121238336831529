
const clothingSizeGuide = [{id:1, size: 'xs', nameWidth:'pecho', width: 44, nameLenght: 'largo', length: 64, category:'remeras'},
                        {id:2, size: 's', nameWidth:'pecho', width: 47, nameLenght: 'largo', length: 67, category:'remeras'},
                        {id:3, size: 'm', nameWidth:'pecho', width: 50, nameLenght: 'largo', length: 70, category:'remeras'},
                        {id:4, size: 'l', nameWidth:'pecho', width: 53, nameLenght: 'largo', length: 73, category:'remeras'},
                        {id:5, size: 'xl', nameWidth:'pecho', width: 56, nameLenght: 'largo', length: 76, category:'remeras'},
                        {id:6, size: 'xxl', nameWidth:'pecho', width: 59, nameLenght: 'largo', length: 80, category:'remeras'},
                        {id:7, size: 'xxxl', nameWidth:'pecho', width: 62, nameLenght: 'largo', length: 84, category:'remeras'},
                        {id:8, size: 36, nameWidth:'cintura', width: 36, nameLenght: 'largo', length: 102, category:'pantalones'},
                        {id:9, size: 38, nameWidth:'cintura', width: 38, nameLenght: 'largo', length: 103, category:'pantalones'},
                        {id:10, size: 40, nameWidth:'cintura', width: 40, nameLenght: 'largo', length: 104, category:'pantalones'},
                        {id:11, size: 42, nameWidth:'cintura', width: 41, nameLenght: 'largo', length: 105, category:'pantalones'},
                        {id:12, size: 44, nameWidth:'cintura', width: 43, nameLenght: 'largo', length: 106, category:'pantalones'},
                        {id:13, size: 46, nameWidth:'cintura',  width: 45, nameLenght: 'largo', length: 107, category:'pantalones'},
]

const sizeGuide = new Promise ((resolve)=>(
    resolve(clothingSizeGuide)
))

export default sizeGuide;