import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth/AuthContext";
import { api } from "../../services/api";
import OrderItem from "../OrderItem/OrderItem";
import Spinner from "../Spinner";
import "./PendingOrders.css";

const PendingOrders = () => {
	const [orders, setOrders] = useState();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { userData } = useAuth();

	const redirectOrder = (id) => {
		navigate(`/user-account/orders/${id}`);
	};

	useEffect(() => {
		api.orders
			.getLatestOrders(userData.clientId)
			.then((response) => response.json())
			.catch((err) => console.log(err))
			.then((ordersAPI) => {
				setOrders(ordersAPI);
				setLoading(false);
			})
			.catch((err) => console.log(err));

		return () => {
			setLoading(true);
		};
	}, [userData]);

	if (loading) {
		return (
			<Container maxWidth="xl">
				<Box className="ordersSpinnerContainer">
					<Spinner />
				</Box>
			</Container>
		);
	}

	return (
		<Fragment>
			{orders?.length > 0 ? (
				orders?.map((order) => {
					return (
						<Fragment key={order.idOrder}>
							<Button
								sx={{ textTransform: "none" }}
								color="secondary"
								fullWidth
								onClick={() => redirectOrder(order.idOrder)}
							>
								<OrderItem
									orderDate={order.orderDate}
									state={order.state}
									orderItems={order.orderItems}
									withdrawalPoint={order.withdrawalPoint}
									isPaid={order.isPaid}
								/>
							</Button>
							<Divider />
						</Fragment>
					);
				})
			) : (
				<Box
					sx={{
						minHeight: "calc(100vh - 559px)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" sx={{ color: "#5E5E5E" }}>
						No tiene pedidos pendientes
					</Typography>
				</Box>
			)}
			{/* <Button
				color="secondary"
				variant="contained"
				fullWidth
				sx={{
					textTransform: "capitalize",
					fontSize: "0.8rem",
					fontWeight: "600",
					marginTop: "2.5rem",
					marginBottom: "4rem",
				}}
			>
				Asociar envíos
			</Button> */}
		</Fragment>
	);
};

export default PendingOrders;
