import { Fragment } from "react";
import {
	Box,
	Divider,
	Typography,
	Container,
	IconButton,
	Tooltip,
	// createTheme,
	// ThemeProvider,
	// Radio,
	// Button,
	// FilledInput,
	// InputLabel,
	// FormControl,
} from "@mui/material";
// import { styled } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import Sheet from "react-modal-sheet";
// import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import "./AddressShipping.css";
// import { useForm } from "react-hook-form";
import { useAuth } from "../../context/Auth/AuthContext";

// const CheckoutFormControl = styled(FormControl)(({ theme }) => ({
// 	marginBottom: ".8rem",
// 	width: "100%",
// 	"& .MuiInputLabel-root.Mui-focused": {
// 		color: theme.palette.light.main,
// 	},
// }));

// const themeBtn = createTheme({
// 	palette: {
// 		primary: {
// 			main: "#000000", //color del btn y color del borde en este caso
// 		},
// 	},
// });

function AddressShipping({ productsInCart }) {
	const { userData } = useAuth();
	const navigate = useNavigate();
	// const {
	// 	register,
	// 	handleSubmit,
	// 	formState: { errors },
	// 	reset,
	// } = useForm();

	// const [addressId, setAddressId] = useState(1);
	// const [selectedAddress, setSelectedAddress] = useState({
	// 	id: 1,
	// 	clientBusinessName: "local san martin",
	// 	address: "san martin 4436",
	// 	province: "neuquen",
	// });

	// function addressSelect(id) {
	// 	//setea el numero del id que se clickea
	// 	setAddressId(id);
	// 	//busca en la matriz, el objeto en donde coincida
	// 	//el id ingresado con el id del objeto de la matriz y lo setea
	// 	setSelectedAddress(
	// 		listOfShippingAddresses.find((objectId) => id === objectId.id)
	// 	);
	// }

	// const [listOfShippingAddresses, setListOfShippingAddresses] = useState([
	// 	{
	// 		id: 1,
	// 		clientBusinessName: "local san martin",
	// 		address: "san martin 4436",
	// 		province: "neuquen",
	// 	},
	// ]);

	// const handleEdit = (data) => {
	// 	data.id = listOfShippingAddresses.length + 1;
	// 	setListOfShippingAddresses([...listOfShippingAddresses, data]);
	// 	handleClose2();
	// 	reset();
	// };

	// const [transportModal, setTransportModal] = useState();
	// const [selectedTransportModal, setSelectedTransportModal] = useState({});

	// function transportSelectModal(id) {
	// 	setTransportModal(id);
	// 	setSelectedTransportModal(
	// 		transportDataBaseModal.find((objectId) => id === objectId.id)
	// 	);
	// }

	// //se crea un nuevo array para enviar alli los elementos seleccionados en selectedTransportModal
	// const [transportArrayWithDuplicate, setTransportArrayWithDuplicate] =
	// 	useState([]);

	// //solucion a lo objetos duplicados:
	// const transportDataBase = transportArrayWithDuplicate.reduce(
	// 	(array, newObject) => {
	// 		//si el nuevo objeto incluido, no coincide en el array...
	// 		if (!array.includes(newObject)) {
	// 			//...entonces pushear el nuevo objeto al array...
	// 			array.push(newObject);
	// 		}
	// 		//..en caso contrario, retornar el array
	// 		return array;
	// 	},
	// 	[]
	// );

	// function onAddTransport() {
	// 	//pusheo al nuevo array (transporDataBase), el nuevo objeto seleccionado que se encuentra en selectedTransportModal
	// 	setTransportArrayWithDuplicate((transportArrayWithDuplicate) => [
	// 		...transportArrayWithDuplicate,
	// 		selectedTransportModal,
	// 	]);
	// 	setOpen(false);
	// }

	// const [transport, setTransport] = useState();
	// const [selectedTransport, setSelectedTransport] = useState({});

	// function transportSelect(id) {
	// 	setTransport(id);
	// 	setSelectedTransport(
	// 		transportDataBase.find((objectId) => id === objectId.id)
	// 	);
	// }

	// const [isOpen, setOpen] = useState(false);

	// function handleOpen() {
	// 	setOpen(true);
	// }

	// function handleClose() {
	// 	setOpen(false);
	// }

	// const [isOpen2, setOpen2] = useState(false);

	// function handleOpen2() {
	// 	setOpen2(true);
	// }

	// function handleClose2() {
	// 	setOpen2(false);
	// }

	return (
		<Fragment>
			<Container>
				<Box display="flex" alignItems="center" minHeight="56px">
					<Box>
						<Tooltip title="Volver">
							<IconButton
								size="large"
								aria-label="volver"
								aria-controls="volver-carrito"
								aria-haspopup="false"
								color="inherit"
								onClick={() => navigate(-1)}
							>
								<ArrowBackIosIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Typography
						variant="h5"
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexGrow={1}
					>
						Direcciones y envíos
					</Typography>
					<Box>
						<Tooltip title="Carrito">
							<Link to="/cart">
								<IconButton
									size="large"
									aria-label="carrito"
									aria-controls="logo-carrito"
									aria-haspopup="false"
									color="inherit"
								>
									{productsInCart > 0 ? (
										<ShoppingBagIcon color="secondary" />
									) : (
										<ShoppingBagOutlinedIcon color="secondary" />
									)}
								</IconButton>
							</Link>
						</Tooltip>
					</Box>
				</Box>
			</Container>

			<Divider />

			<Container className="infoContainer">
				<Box className="boxInfoContainer" my={1} pt={2}>
					<Typography className="titleFirstRow" sx={{ fontWeight: "600" }}>
						Dirección de envío
					</Typography>
				</Box>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						pt: 1,
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<LocationOnOutlinedIcon
							style={{ color: "#000000" }}
							sx={{ fontSize: 32, paddingRight: "1rem" }}
						/>
						<Typography className="payment_title_typography">
							{`${userData.clientAddress}, CP: ${userData.clientZipCode}`}
						</Typography>
					</Box>
					<Typography
						className="textDefault"
						sx={{
							fontWeight: "600",
							fontSize: "0.76rem",
							marginTop: "4px",
						}}
					>
						predeterminada
					</Typography>
				</Box>
				<Box className="boxInfoContainer" my={1} pt={6}>
					<Typography className="titleFirstRow" sx={{ fontWeight: "600" }}>
						Método de envío
					</Typography>
				</Box>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						pt: 1,
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<LocalShippingOutlinedIcon
							style={{ color: "#000000" }}
							sx={{ fontSize: 32, paddingRight: "1rem" }}
						/>
						<Typography className="payment_title_typography">
							{userData.clientTranspoName}
						</Typography>
					</Box>
					<Typography
						className="textDefault"
						sx={{
							fontWeight: "600",
							fontSize: "0.76rem",
							marginTop: "4px",
						}}
					>
						predeterminada
					</Typography>
				</Box>
			</Container>
			{/* <Container>
				<Box className="boxAddressShippingContainer" my={1} pt={2}>
					<Typography className="titleFirstRow" sx={{ fontWeight: "600" }}>
						Direcciones de envío
					</Typography>
				</Box>

				<Divider />

				{listOfShippingAddresses.map((arrayAddress) => (
					<div key={arrayAddress.id}>
						<Box className="boxAddressShippingContainer" my={1}>
							<Box className="boxIconAndAddressContainer">
								<Box className="boxIconLocation">
									<LocationOnOutlinedIcon
										style={{ color: "#000000" }}
										sx={{ fontSize: 32 }}
									/>
								</Box>
								<Box className="boxAddressContainer">
									<Box className="addressNameAndSelectContainer">
										<Box className="boxNameAddress">
											<Typography
												variant="subtitle1"
												className="textName"
												sx={{ fontSize: "1rem" }}
											>
												{arrayAddress.clientBusinessName}
											</Typography>
										</Box>
										<Box className="boxTextDefault">
											{selectedAddress?.id === arrayAddress.id ? (
												<Typography
													className="textDefault"
													sx={{ fontWeight: "600", fontSize: "0.76rem" }}
												>
													predeterminada
												</Typography>
											) : (
												""
											)}
										</Box>
									</Box>

									<Typography
										variant="subtitle2"
										className="textAddress"
										sx={{
											fontSize: "1rem",
											color: (theme) => theme.palette.light.main,
										}}
									>
										{arrayAddress.address}, {arrayAddress.province}
									</Typography>
								</Box>
							</Box>
							<Box>
								<Radio
									checked={addressId === arrayAddress.id}
									onClick={() => addressSelect(arrayAddress.id)}
									value={arrayAddress.id}
									name="radio-buttons"
									style={{ color: "#000000" }}
								/>
							</Box>
						</Box>

						<Divider />
					</div>
				))}

				{/* <ThemeProvider theme={themeBtn}>
					<Box className="boxBtnContainer">
						<Button
							className="btnAddressShipping"
							variant="outlined"
							type="submit"
							sx={{
								fontSize: "1rem",
								textAlign: "center",
								textTransform: "capitalize",
								paddingLeft: 5,
								paddingRight: 5,
							}}
							onClick={handleOpen2}
						>
							agregar dirección
						</Button>
					</Box>
				</ThemeProvider> 
			</Container> */}

			{/* <Container className="transportContainer">
				<Box className="boxAddressShippingContainer" my={1} pt={6}>
					<Typography className="titleFirstRow" sx={{ fontWeight: "600" }}>
						Métodos de envío
					</Typography>
				</Box>

				<Divider />

				{transportDataBase.map((arrayTransport) => (
					<div key={arrayTransport.id}>
						<Box className="boxAddressShippingContainer" my={1}>
							<Box className="boxIconAndAddressContainer">
								<Box className="boxIconLocation">
									<LocalShippingOutlinedIcon
										style={{ color: "#000000" }}
										sx={{ fontSize: 32 }}
									/>
								</Box>
								<Box className="boxAddressContainer">
									<Box className="addressNameAndSelectContainer">
										<Box className="boxNameAddress">
											<Typography
												variant="subtitle1"
												className="textName"
												sx={{ fontSize: "1rem" }}
											>
												{arrayTransport.name}
											</Typography>
										</Box>
										<Box className="boxTextDefault">
											{selectedTransport?.id === arrayTransport.id ? (
												<Typography
													className="textDefault"
													sx={{ fontWeight: "600", fontSize: "0.76rem" }}
												>
													predeterminada
												</Typography>
											) : (
												""
											)}
										</Box>
									</Box>
								</Box>
							</Box>
							<Box>
								<Radio
									checked={transport === arrayTransport.id}
									onClick={() => transportSelect(arrayTransport.id)}
									value={arrayTransport.id}
									name="radio-buttons"
									style={{ color: "#000000" }}
								/>
							</Box>
						</Box>

						<Divider />
					</div>
				))}

				{/* <ThemeProvider theme={themeBtn}>
					<Box className="boxBtnContainer">
						<Button
							className="btnAddressShipping"
							variant="outlined"
							type="submit"
							sx={{
								fontSize: "1rem",
								textAlign: "center",
								textTransform: "capitalize",
								paddingLeft: 4,
								paddingRight: 4,
							}}
							onClick={handleOpen}
						>
							agregar transporte
						</Button>
					</Box>
				</ThemeProvider> 
			</Container> */}

			{/* //////////////////////////////MODAL SHEET DIRECCIONES///////////////////////////////////////////////////////// */}

			{/* <Sheet
				isOpen={isOpen2}
				onClose={handleClose2}
				snapPoints={[900, 900, 0]}
				initialSnap={1}
			>
				<Sheet.Container style={{ height: "100vh", borderRadius: 0 }}>
					<Sheet.Header>
						<Container>
							<Box className="titleSelectTransport">
								<Box className="boxNameAddress">
									<Typography
										variant="h5"
										display="flex"
										justifyContent="center"
										alignItems="center"
										flexGrow={1}
									>
										Agregá dirección
									</Typography>
								</Box>
								<Box>
									<ClearIcon
										onClick={handleClose2}
										sx={{ fontSize: 32, paddingRight: 1 }}
									/>
								</Box>
							</Box>
						</Container>
					</Sheet.Header>
					<Sheet.Content>
						<Container className="transportContainer">
							<Divider />
							<Box
								sx={{ marginTop: "10px" }}
								component="form"
								onSubmit={handleSubmit((data) => handleEdit(data))}
							>
								<CheckoutFormControl fullWidth>
									<InputLabel>Nombre del local</InputLabel>
									<FilledInput
										type="text"
										{...register("clientBusinessName", {
											required: "Debe completar este campo",
										})}
										variant=""
									></FilledInput>
									{errors.clientBusinessName ? (
										<Typography variant="caption" color="error">
											{errors.clientBusinessName.message}
										</Typography>
									) : (
										<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
											<br />
										</Typography>
									)}
								</CheckoutFormControl>

								<CheckoutFormControl fullWidth>
									<InputLabel>Dirección</InputLabel>
									<FilledInput
										type="text"
										{...register("address", {
											required: "Debe completar este campo",
										})}
										variant=""
									></FilledInput>
									{errors.address ? (
										<Typography variant="caption" color="error">
											{errors.address.message}
										</Typography>
									) : (
										<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
											<br />
										</Typography>
									)}
								</CheckoutFormControl>

								<CheckoutFormControl fullWidth>
									<InputLabel>Provincia</InputLabel>
									<FilledInput
										type="text"
										{...register("province", {
											required: "Debe completar este campo",
										})}
										variant=""
									></FilledInput>
									{errors.province ? (
										<Typography variant="caption" color="error">
											{errors.province.message}
										</Typography>
									) : (
										<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
											<br />
										</Typography>
									)}
								</CheckoutFormControl>
								<ThemeProvider theme={themeBtn}>
									<Box className="boxBtnContainer">
										<Button
											type="sumbit"
											style={{ fontFamily: "Quicksand" }}
											variant="contained"
											fullWidth
											sx={{
												mt: 0.0,
												fontSize: "1rem",
												fontWeight: 700,
												textTransform: "none",
												textAlign: "center",
											}}
											className="btnAddressShipping"
										>
											Agregar
										</Button>
									</Box>
								</ThemeProvider>
							</Box>
						</Container>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet> */}

			{/* //////////////////////////////MODAL SHEET TRANSPORTE////////////////////////////////////////////////////////// */}

			{/* <Sheet
				isOpen={isOpen}
				onClose={handleClose}
				snapPoints={[900, 900, 0]}
				initialSnap={1}
			>
				<Sheet.Container style={{ height: "100vh", borderRadius: 0 }}>
					<Sheet.Header>
						<Container>
							<Box className="titleSelectTransport">
								<Box className="boxNameAddress">
									<Typography
										variant="h5"
										display="flex"
										justifyContent="center"
										alignItems="center"
										flexGrow={1}
									>
										Seleccioná transporte
									</Typography>
								</Box>
								<Box>
									<ClearIcon
										onClick={handleClose}
										sx={{ fontSize: 32, paddingRight: 1 }}
									/>
								</Box>
							</Box>
						</Container>
					</Sheet.Header>
					<Sheet.Content>
						<Container className="transportContainer">
							<Divider />

							{transportDataBaseModal.map((arrayTransportModal) => (
								<div key={arrayTransportModal.id}>
									<Box className="boxAddressShippingContainer" my={1}>
										<Box className="boxIconAndAddressContainer">
											<Box className="boxAddressContainer">
												<Box className="addressNameAndSelectContainer">
													<Box className="boxNameAddress">
														<Typography
															variant="subtitle1"
															className="textName"
															sx={{ fontSize: "1rem" }}
														>
															{arrayTransportModal.name}
														</Typography>
													</Box>
												</Box>
											</Box>
										</Box>
										<Box>
											<Radio
												checked={transportModal === arrayTransportModal.id}
												onClick={() =>
													transportSelectModal(arrayTransportModal.id)
												}
												value={arrayTransportModal.id}
												name="radio-buttons"
												style={{ color: "#000000" }}
											/>
										</Box>
									</Box>

									<Divider />
								</div>
							))}

							<ThemeProvider theme={themeBtn}>
								<Box className="boxBtnContainer">
									<Button
										style={{ fontFamily: "Quicksand" }}
										variant="contained"
										fullWidth
										sx={{
											mt: 0.0,
											fontSize: "1rem",
											fontWeight: 700,
											textTransform: "none",
											textAlign: "center",
										}}
										className="btnAddressShipping"
										onClick={onAddTransport}
									>
										Agregar
									</Button>
								</Box>
							</ThemeProvider>
						</Container>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop />
			</Sheet> */}
		</Fragment>
	);
}

export default AddressShipping;
