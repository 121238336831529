import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "./Counter.css";

const Counter = ({ value, onIncrement, onDecrement }) => {
	return (
		<Box className="itemcountContainer">
			<IconButton aria-label="Aumentar el contador" onClick={onDecrement}>
				<RemoveIcon />
			</IconButton>
			<Box>
				<Typography variant="subtitle1" color="initial">
					{value}
				</Typography>
			</Box>
			<IconButton aria-label="Disminuir el contador" onClick={onIncrement}>
				<AddIcon />
			</IconButton>
		</Box>
	);
};

export default Counter;
