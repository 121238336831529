import {
	AppBar,
	Avatar,
	Box,
	Button,
	Container,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	styled,
	alpha,
	InputBase,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useCart } from "../../context/Cart/CartContext";
import { useAuth } from "../../context/Auth/AuthContext";
import { useTimer } from "../../context/TimerContext/TimerContext";
import CartWidget from "../../components/CartWidget";
import { api } from "../../services/api";
import logo from "../../img/logos/logo-eldon-negro-xs.png";
import { normalizedCategories } from "../../utils/auxFunc";
import "./Navbar.css";

const SearchWrapper = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("lg")]: {
			width: "12ch",
			"&:focus": {
				width: "12ch",
			},
		},
	},
}));

const useStyles = makeStyles((theme) => ({
	offset: theme.mixins.toolbar,
	appbar: {
		backgroundColor: theme.palette.primary.main,
	},
}));

const Navbar = () => {
	const [categories, setCategories] = useState(null);
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [search, setSearch] = useState("");
	const { cart } = useCart();
	const { timer } = useTimer();
	const { signOut } = useAuth();
	const navigate = useNavigate();
	const classes = useStyles();

	const sendSearch = (evt) => {
		if (evt.key === "Enter") {
			navigate(`/search/${search}`);
			setSearch("");
			toggleNavMenu();
		}
	};

	const handleSearch = (evt) => {
		setSearch(evt.target.value);
	};
	// Solo sirve para el menu con estilo mobile
	const toggleNavMenu = (event) => {
		anchorElNav ? setAnchorElNav(null) : setAnchorElNav(event.currentTarget);
	};

	const getCategories = async (condition) => {
		api.categories
			.listAll()
			.then((response) => response.json())
			.then((categories) => {
				if (condition) {
					const allCategories = normalizedCategories(categories);
					setCategories(allCategories);
				}
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		let isMounted = true;

		getCategories(isMounted);

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<>
			<AppBar position="fixed" className={classes.appbar}>
				<Container maxWidth="xl">
					<Toolbar
						disableGutters
						sx={{ display: "flex", justifyContent: "space-between" }}
					>
						<Box sx={{ display: "flex" }}>
							<Tooltip title="Menú">
								<IconButton
									size="large"
									aria-label="menu"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									color="inherit"
									onClick={toggleNavMenu}
								>
									{anchorElNav ? <CloseIcon /> : <MenuIcon />}
								</IconButton>
							</Tooltip>
							<Menu
								id="menu-appbar"
								// anchorEl={anchorElNav}
								anchorReference="anchorPosition"
								anchorPosition={{ top: 56, left: 0 }}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={toggleNavMenu}
								PaperProps={{
									style: {
										width: "100vw",
										maxWidth: "100vw",
									},
								}}
								MenuListProps={{ disablePadding: true }} //quitar padding de menu hamburguesa
								style={{
									left: "-16px",
								}}
								sx={{ display: "block" }}
							>
								<SearchWrapper
									sx={{
										display: "flex",
										flexGrow: 0,
									}}
									style={{
										background:
											"linear-gradient(180deg, rgba(230,230,230,1) 0%, rgba(247,247,247,1) 100%)",
									}}
								>
									<SearchIconWrapper>
										<SearchIcon />
									</SearchIconWrapper>
									<SearchInputBase
										fullWidth
										value={search}
										placeholder="Buscar..."
										inputProps={{ "aria-label": "Búsqueda" }}
										onChange={handleSearch}
										onKeyPress={sendSearch}
										onKeyDown={(e) => e.stopPropagation()} //evitar el focus a MenuItem con teclas
									/>
								</SearchWrapper>
								{categories?.map(({ name, routeURL }) => (
									<Link to={routeURL} className="menu-links" key={name}>
										<MenuItem onClick={toggleNavMenu} divider dense={true}>
											{name}
										</MenuItem>
									</Link>
								))}
								<Box sx={{ mt: 3 }}>
									<Button
										startIcon={<PersonIcon />}
										fullWidth
										sx={{
											display: "flex",
											justifyContent: "start",
											fontFamily: "Quicksand",
											color: "#292929",
											paddingLeft: "16px",
											marginBottom: "0.5rem",
											textTransform: "none",
										}}
									>
										<Link to="/user-account" className="user-account">
											Mi Cuenta
										</Link>
									</Button>
								</Box>
								<Box>
									<Button
										startIcon={<LogoutIcon />}
										fullWidth
										sx={{
											display: "flex",
											justifyContent: "start",
											fontFamily: "Quicksand",
											color: "#292929",
											paddingLeft: "16px",
											marginBottom: "0.5rem",
											textTransform: "none",
										}}
										onClick={signOut}
									>
										Cerrar Sesión
									</Button>
								</Box>
							</Menu>
						</Box>
						<Box sx={{ display: "flex", width: "fit-content" }}>
							<Link to="/">
								<Avatar
									alt="Logo de El Don"
									variant="square"
									src={logo}
									className="navbarLogo"
									sx={{
										justifyContent: "center",
										width: "auto",
										maxHeight: "30px",
									}}
								/>
							</Link>
						</Box>
						<Box className="timer">
							<Typography variant="subtitle1" color="initial">
								{timer}
							</Typography>
						</Box>
						<CartWidget productsInCart={cart?.length} />
					</Toolbar>
				</Container>
			</AppBar>
			<div className={classes.offset}></div>
		</>
	);
};

export default Navbar;
