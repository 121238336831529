import { Container, Typography, Box, TextField, Button } from "@mui/material";
import { createTheme, ThemeProvider, styled, StyledEngineProvider } from '@mui/material/styles';
import './AccountCreationForm.css';
import logo from "../../img/logos/logo-eldon-blanco-xs.png";

const LoginTextField = styled(TextField)({
	input: {
		"&:-webkit-autofill": {
			WebkitBoxShadow: "0 0 0 1000px #0F0F0F inset",
			WebkitTextFillColor: "#FAFAFA !important",
		},
	},
	"&:-webkit-autofill": {
		WebkitBoxShadow: "0 0 0 1000px white inset",
	},
	"& label.Mui-focused": {
		color: "#FAFAFA",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#FAFAFA",
		},
		"&:hover fieldset": {
			borderColor: "#FFFFFF",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#FAFAFA",
		},
	},
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
});

function AccountCreationForm({ handleSubmit, handleChange, dataForm }){
    
    return(
        <div className="main">
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <Container
                        maxWidth="xs"
                        sx={{display: "flex",
                            flexDirection: "column",
                            justifyContect:"space-between",
                            alignItems: "center",
                            height:"100%",
                            color: "#FAFAFA",
                            paddingTop: 3,
                            paddingBottom: 3,
                        }}
                    >
                        <img
							src={ logo }
							alt="Logo de El Don Mayorista"
							className="form-logo"
						/>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 2, 
                                // textAlign: "center" 
                            }}
                        >
                            <Typography component="h1" sx={{ fontFamily: "Quicksand", textAlign: "center" }}
                            >
                                CREAR CUENTA
                            </Typography>    
                            <LoginTextField 
                                id="nameForm" 
                                name="nameForm" 
                                type="text" 
                                label="Nombre" 
                                onChange={handleChange}  
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: '#FAFAFA', fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }}
                                value={dataForm[0].value} 
                            />
                            {<p style={{color:'red'}}>{dataForm[0].error}</p>}
                            <LoginTextField 
                                id="surnameForm" 
                                name="surnameForm" 
                                type="text" 
                                label="Apellido" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }} 
                                value={dataForm[1].value}
                            />
                            {<p style={{color:'red'}}>{dataForm[1].error}</p>}
                            <LoginTextField 
                                id="emailForm" 
                                name="emailForm" 
                                type="email" 
                                label="Email" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }} 
                                value={dataForm[2].value}
                            />
                            {<p style={{color:'red'}}>{dataForm[2].error}</p>}
                            <LoginTextField 
                                id="passwordForm" 
                                name="passwordForm" 
                                type="password" 
                                label="Contraseña" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }}
                                value={dataForm[3].value} 
                            />
                            {<p style={{color:'red'}}>{dataForm[3].error}</p>}
                            <LoginTextField 
                                id="phoneForm" 
                                name="phoneForm" 
                                type="text" 
                                label="Teléfono" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }} 
                                value={dataForm[4].value}
                            />
                            {<p style={{color:'red'}}>{dataForm[4].error}</p>}
                            <LoginTextField 
                                id="companyNameForm" 
                                name="companyNameForm" 
                                type="text" 
                                label="Empresa" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }} 
                                value={dataForm[5].value}
                            />
                            {<p style={{color:'red'}}>{dataForm[5].error}</p>}
                            <LoginTextField 
                                id="cuitForm" 
                                name="cuitForm" 
                                type="text" 
                                label="CUIT" 
                                onChange={handleChange} 
                                variant="outlined" 
                                margin="normal" 
                                fullWidth 
                                InputLabelProps={{style: { color: "#FAFAFA", fontFamily:'Quicksand' }}} 
                                sx={{ input: { color: 'white' } }} 
                                value={dataForm[6].value}
                            />
                            {<p style={{color:'red'}}>{dataForm[6].error}</p>}
                            <Button 
                                id="btnForm" 
                                type="submit" 
                                variant="contained" 
                                disableElevation 
                                fullWidth
                                sx={{ mt: 2, fontSize: "1rem", fontWeight: 700, textTransform: "none", textAlign: "center" }}
                                style={{ fontFamily: "Quicksand" }}
                            >
                                Solicitar cuenta
                            </Button>
                            <Typography 
                                component="h6"  
                                sx={{ fontFamily: "Quicksand", textAlign: "center" }}
                            >
                                Una vez solicitada su cuenta, el equipo de Ventas 
                                se pondrá en contacto para validar su registro.
                            </Typography>
                        </Box>
                    </Container>
                </StyledEngineProvider>
            </ThemeProvider>
        </div>
    )
}

export default AccountCreationForm;