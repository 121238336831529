import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/Auth/AuthContext'
import { ConfigContextProvider } from './context/Config/ConfigContext'
import { DiscountProvider } from './context/Discount/DiscountContext'
import { CartContextProvider } from './context/Cart/CartContext'
import { TimerContextProvider } from './context/TimerContext/TimerContext'
import { ProductsContextProvider } from './context/Products/ProductsContext'
import { FilterContextProvider } from './context/FilterContext/FilterContext'
import Navbar from './containers/Navbar'
import HomeContainer from './containers/HomeContainer'
import LoginContainer from './containers/LoginContainer'
import AccountCreationFormContainer from './containers/AccountCreationFormContainer/AccountCreationFormContainer'
import ItemDetailContainer from './containers/ItemDetailContainer/ItemDetailContainer'
import ItemsListContainer from './containers/ItemsListContainer'
import AddressShippingContainer from './containers/AddressShippingContainer/AddressShippingContainer'
import CartContainer from './containers/CartContainer'
import SizeGuideContainer from './containers/SizeGuideContainer/SizeGuideContainer'
import SizesAndColorsContainer from './containers/SizesAndColorsContainer/SizesAndColorsContainer.jsx'
import MyAccountContainer from './containers/MyAccountContainer'
import OrdersContainer from './containers/OrdersContainer'
import OrderDetailContainer from './containers/OrderDetailContainer'
import PayMethodsContainer from './containers/PayMethodsContainer/PayMethodsContainer'
import CheckoutContainer from './containers/CheckoutContainer'
import ProtectedRoutes from './containers/ProtectedRoutes'
import PublicRoutes from './containers/PublicRoutes'
import PendingOrders from './components/PendingOrders'
import ClosedOrders from './components/ClosedOrders'
import theme from './assets/elDonTheme'
import './App.css'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <ConfigContextProvider>
            <DiscountProvider>
              <CartContextProvider>
                <TimerContextProvider>
                  <ProductsContextProvider>
                    <FilterContextProvider>
                      <Routes>
                        <Route
                          exact
                          path="/login"
                          element={
                            <PublicRoutes>
                              <LoginContainer />
                            </PublicRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/create-user"
                          element={
                            <PublicRoutes>
                              <AccountCreationFormContainer />
                            </PublicRoutes>
                          }
                        ></Route>
                        <Route
                          path="/"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <HomeContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          path="/home"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <HomeContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/user-account"
                          element={
                            <ProtectedRoutes>
                              <MyAccountContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>

                        <Route
                          exact
                          path="/user-account/orders"
                          element={
                            <ProtectedRoutes>
                              <OrdersContainer />
                            </ProtectedRoutes>
                          }
                        >
                          <Route path="pending" element={<PendingOrders />} />
                          <Route path="closed" element={<ClosedOrders />} />
                        </Route>
                        <Route
                          exact
                          path="/user-account/address-shipping"
                          element={
                            <ProtectedRoutes>
                              <AddressShippingContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/user-account/orders/:orderId"
                          element={
                            <ProtectedRoutes>
                              <OrderDetailContainer />
                            </ProtectedRoutes>
                          }
                        />
                        <Route
                          path="/user-account/pay-methods"
                          element={
                            <ProtectedRoutes>
                              <PayMethodsContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/category/:categoryId"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <ItemsListContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/search/:productsSearched"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <ItemsListContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/itemDetail/:idProduct"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <ItemDetailContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/SizeGuide/:idCategory"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <SizeGuideContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/SizesAndColors/:idProduct"
                          element={
                            <ProtectedRoutes>
                              <Navbar />
                              <SizesAndColorsContainer />
                            </ProtectedRoutes>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/cart"
                          element={
                            <ProtectedRoutes>
                              <CartContainer />
                            </ProtectedRoutes>
                          }
                        />
                        <Route
                          exact
                          path="/cart/checkout"
                          element={
                            <ProtectedRoutes>
                              <CheckoutContainer />
                            </ProtectedRoutes>
                          }
                        />
                      </Routes>
                    </FilterContextProvider>
                  </ProductsContextProvider>
                </TimerContextProvider>
              </CartContextProvider>
            </DiscountProvider>
          </ConfigContextProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
