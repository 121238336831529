import { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Sheet from "react-modal-sheet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SpinnerContainer from "../../containers/SpinnerContainer";
import FilterWidget from "../FilterWidget";
import Filter from "../Filter";
import ProductCard from "../ProductCard";
import Sort from "../Sort/Sort";
import "./ItemsList.css";

const ItemsList = ({ products, isLoading }) => {
	const [productsSorted, setProductsSorted] = useState();
	const [originalProducts, setOriginalProducts] = useState();
	const [openFilter, setOpenFilter] = useState(false);
	const [sortValue, setSortValue] = useState("relevance");
	const [openSort, setOpenSort] = useState(false);

	const toggleFilterMenu = () => {
		setOpenFilter(!openFilter);
	};

	const toggleSortMenu = () => {
		setOpenSort(!openSort);
	};

	const handleSort = (evt) => {
		if (evt.target.value === "relevance") {
			setSortValue(evt.target.value);
			setProductsSorted(originalProducts);
		} else if (evt.target.value === "higherPrice") {
			// Orden descendente
			setSortValue(evt.target.value);
			const dupliProducts = JSON.parse(JSON.stringify(products));
			dupliProducts.sort((a, b) => b.price - a.price);
			setProductsSorted(dupliProducts);
		} else {
			setSortValue(evt.target.value);
			// Orden ascendente
			const dupliProducts = JSON.parse(JSON.stringify(products));
			dupliProducts.sort((a, b) => a.price - b.price);
			setProductsSorted(dupliProducts);
		}
	};

	useEffect(() => {
		setProductsSorted(products);
		setOriginalProducts(products);
	}, [products]);

	return isLoading ? (
		<SpinnerContainer />
	) : (
		<Fragment>
			<Container maxWidth="xl">
				{productsSorted?.length > 0 ? (
					<Fragment>
						<Box>
							<Typography
								variant="h1"
								sx={{
									textAlign: "center",
									fontFamily: "Quicksand",
									fontSize: "2rem",
									fontWeight: "bold",
									textTransform: "capitalize",
									pt: 2,
								}}
							>
								{products?.length > 0 && products[0]?.category}
							</Typography>
						</Box>
						<FilterWidget
							toggleFilterMenu={toggleFilterMenu}
							toggleSortMenu={toggleSortMenu}
						/>
						<Box className="imgCategoryContainer">
							<Grid container spacing={2}>
								{productsSorted.map((product, index) => (
									<ProductCard key={index} product={product} />
								))}
							</Grid>
						</Box>
					</Fragment>
				) : (
					<Fragment>
						<FilterWidget
							toggleFilterMenu={toggleFilterMenu}
							toggleSortMenu={toggleSortMenu}
						/>
						<Box className="noStock">
							<Typography variant="h5" color="secondary" textAlign="center">
								Producto inexistente o sin stock
							</Typography>
							<Link to="/" style={{ textDecoration: "none" }}>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									sx={{ textTransform: "capitalize", mt: 2 }}
								>
									Buscar productos
								</Button>
							</Link>
						</Box>
					</Fragment>
				)}
			</Container>
			{openFilter && (
				<Sheet
					isOpen={openFilter}
					onClose={() => toggleFilterMenu()}
					snapPoints={[700, 500, 400, 100, 0]}
					initialSnap={1}
				>
					<Sheet.Container>
						<Sheet.Header />
						<Sheet.Content>
							<Filter toggleFilterMenu={toggleFilterMenu} />
						</Sheet.Content>
					</Sheet.Container>
					<Sheet.Backdrop />
				</Sheet>
			)}
			{openSort && (
				<Sheet
					isOpen={openSort}
					onClose={() => toggleSortMenu()}
					snapPoints={[900, 700, 500, 400, 100, 0]}
					initialSnap={1}
				>
					<Sheet.Container>
						<Sheet.Header />
						<Sheet.Content>
							<Sort
								toggleSortMenu={toggleSortMenu}
								handleSort={handleSort}
								sortValue={sortValue}
							/>
						</Sheet.Content>
					</Sheet.Container>
					<Sheet.Backdrop />
				</Sheet>
			)}
		</Fragment>
	);
};

export default ItemsList;
