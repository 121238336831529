import { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import Swal from 'sweetalert2'
import Home from '../../components/Home/Home'
import { api } from '../../services/api'
import { useAuth } from '../../context/Auth/AuthContext'
import CategoriesSlider from '../../components/CategoriesSlider'
import { useProducts } from '../../context/Products/ProductsContext'

function HomeContainer() {
  const [products, setProducts] = useState(null)
  // const [scroll, setScroll] = useState({
  // 	isScrolling: false,
  // 	clientX: 0,
  // 	scrollX: 0,
  // });
  const [banners, setBanners] = useState([])
  const { categories } = useProducts()
  const { userData } = useAuth()
  const { clientPriceListId } = userData

  // const handleMouseDown = (evt) => {
  //   setScroll(currentState => ({...currentState, isScrolling: true, clientX: evt.clientX}) )
  // }

  // const handleMouseUp = () => {

  // }

  // const handleMouseMove = () => {

  // }

  useEffect(() => {
    const abortController = new AbortController()

    const getNewIn = async () => {
      try {
        const response = await api.products.listNewIn(
          clientPriceListId,
          abortController.signal
        )
        const newInProducts = await response.json()
        setProducts(newInProducts.slice(0, 8))
      } catch (error) {
        if (error.name === 'AbortError') return
        return Swal.fire({
          title: 'Error!',
          text: `Error de servidor: ${error.message}`,
          icon: 'error'
        })
      }
    }

    getNewIn()

    api.utilities
      .getBanners(abortController.signal)
      .then((response) => response.json())
      .then((banners) => setBanners(banners))
      .catch((error) => {
        if (error.name === 'AbortError') return
        alert('Hubo un error', error.message)
      })

    return () => {
      abortController.abort()
    }
  }, [clientPriceListId])

  return (
    <Container maxWidth="xl" sx={{ marginBottom: '5rem' }}>
      <CategoriesSlider />
      <Home categories={categories} banners={banners} products={products} />
    </Container>
  )
}

export default HomeContainer
