import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/Auth/AuthContext";

const ProtectedRoutes = ({ children }) => {
	const { auth } = useAuth();

	return auth ? children : <Navigate to="/login" />;
};

export default ProtectedRoutes;
