export const getCarriers = async (apiInstance) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Utilities/GetCarriers`, requestParameters)
}

export const getBanners = async (apiInstance, signal) => {
  let requestParameters = {
    method: 'GET',
    mode: 'cors',
    headers: apiInstance.headers
  }

  return fetch(`${apiInstance.apiURL}/Banners`, {
    ...requestParameters,
    signal
  })
}
