export const monthNames = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abnil",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];
