import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { api } from "../../services/api";
import Swal from "sweetalert2";
import { useAuth } from "../../context/Auth/AuthContext";
import { useCart } from "../../context/Cart/CartContext";
import { useDiscount } from "../../context/Discount/DiscountContext";
import { useTimer } from "../../context/TimerContext/TimerContext";
import ShipmentInfo from "../../components/ShipmentInfo";
import PaymentInfo from "../../components/PaymentInfo";
import CheckoutResume from "../../components/CheckoutResume";
// import { useConfig } from "../../context/Config/ConfigContext";
import { getCarrierId } from "../../utils/auxFunc";

const CheckoutContainer = () => {
	const { userData } = useAuth();
	const { setPayConditionDiscount } = useDiscount();
	const {
		cart,
		getTotalByComponents,
		getTotalQuantity,
		getTotalAmount,
		clear,
	} = useCart();
	const { timer } = useTimer();
	// const { config } = useConfig();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const [activeStep, setActiveStep] = useState(0);
	const [buyerForm, setBuyerForm] = useState({
		clientName: userData.userName,
		clientPhone: userData.userPhone,
		clientEmail: userData.userEmail,
		clientCuit: userData.clientDocNumber,
	});
	const [shipmentForm, setShipmentForm] = useState({
		shipmentOption: "sucursal",
		shipmentMethod: "0",
	});
	const [withdrawalDate, setWithdrawalDate] = useState({
		withdrawalDate: nextDayEnabled(),
	});

	const [destinationForm, setDestinationForm] = useState({
		shopName: "",
		shopAddress: userData.clientAddress || "",
		shopCity: userData.clientCity || "",
		shopCP: userData.clientZipCode || "",
		transportName: "la sevillanita",
	});
	const [carriers, setCarriers] = useState();
	const [paymentForm, setPaymentForm] = useState({
		paymentMethod: userData.payConditionId,
		paymentDescription: userData.payConditionDescription,
		payConditionDiscount: userData.payConditionDiscount,
	});

	const navigate = useNavigate();

	function nextDayEnabled() {
		if (new Date().getDay() === 5) {
			return moment().add(4, "days").toDate();
		} else if (new Date().getDay() === 6) {
			return moment().add(3, "days").toDate();
		} else {
			return moment().add(2, "days").toDate();
		}
	}

	const handleNext = async () => {
		let nextStep = false;

		let data;

		if (activeStep === 0) {
			const address =
				shipmentForm.shipmentOption === "sucursal"
					? "randomAdrress"
					: `${destinationForm.shopAddress}`;

			if (address !== " ") {
				data = {
					shopName: destinationForm.shopName,
					shopAddress: destinationForm.shopAddress,
					city: destinationForm.shopCity,
					postalCode: destinationForm.shopCP,
					carrierCode: getCarrierId(carriers, destinationForm.transportName),
				};
			}

			const tzoffset = new Date().getTimezoneOffset() * 60000; //offset en milliseconds
			const withdrawalDateISOLocale = new Date(
				withdrawalDate.withdrawalDate - tzoffset
			)
				.toISOString()
				.slice(0, -1);

			try {
				nextStep = await api.checkout
					.shippingMethod(
						userData.clientId,
						shipmentForm.shipmentMethod.toString(),
						address,
						withdrawalDateISOLocale,
						data
					)
					.then((data) => {
						if (data.status !== 200) {
							return Swal.fire({
								title: "Error!",
								text: `Intente nuevamente`,
								icon: "error",
							});
						}

						return true;
					});
			} catch (err) {
				Swal.fire({
					title: "Error!",
					text: `Intente nuevamente`,
					icon: "error",
				});
			}
		} else {
			try {
				nextStep = await api.checkout
					.paymentMethod(
						paymentForm.paymentMethod,
						paymentForm.payConditionDiscount,
						userData
					)
					.then((data) => {
						if (data.status !== 200) {
							return Swal.fire({
								title: "Error!",
								text: `Intente nuevamente`,
								icon: "error",
							});
						}
						return true;
					});
			} catch (err) {
				Swal.fire({
					title: "Error!",
					text: `Intente nuevamente`,
					icon: "error",
				});
			}
		}

		if (nextStep === true) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			nextStep = false;
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const confirmOrder = () => {
		api.checkout
			.confirm(userData)
			.then((data) => {
				if (data.status !== 200) {
					return Swal.fire({
						title: "Error!",
						text: `Error al finalizar la compra. Intente nuevamente`,
						icon: "error",
					});
				}

				Swal.fire({
					customClass: {
						confirmButton: "swalBtnOkCkout",
						cancelButton: "swalBtnCancelCkout",
						title: "swalTitleCkout",
						container: "swalTextSize",
					},
					position: "center",
					icon: "success",
					title: "Tu pedido fue realizado",
					text: `Podes revisar el estado de tu pedido desde la sección: "Mi cuenta"`,
					width: "350px",
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: "Continuar comprando",
					cancelButtonText: "Ver mis pedidos",
					focusConfirm: false,
					iconColor: "#36A160",
					color: "#292929",
					background: "#FAFAFA",
					confirmButtonColor: "#292929",
					cancelButtonColor: "#FAFAFA",
				}).then((result) => {
					if (result.value) {
						navigate("/");
						clear();
					} else {
						navigate("/user-account");
						clear();
					}
				});
			})
			.catch((err) => console.log(err.message));
	};

	useEffect(() => {
		api.utilities
			.getCarriers()
			.then((response) => response.json())
			.catch((err) => console.log(err))
			.then((data) => setCarriers(data))
			.catch((err) => console.log(err));

		setPayConditionDiscount(paymentForm.payConditionDiscount / 100);

		return () => {
			setCarriers();
		};
	}, [paymentForm.payConditionDiscount, setPayConditionDiscount]);

	const closeCheckout = () => {
		// initTimer(config.BUY_TIMER);
		navigate("/cart");
	};

	switch (activeStep) {
		case 0:
			return (
				<ShipmentInfo
					timer={timer}
					buyerForm={buyerForm}
					setBuyerForm={setBuyerForm}
					shipmentForm={shipmentForm}
					setShipmentForm={setShipmentForm}
					withdrawalDate={withdrawalDate}
					setWithdrawalDate={setWithdrawalDate}
					destinationForm={destinationForm}
					setDestinationForm={setDestinationForm}
					carriers={carriers}
					getTotalAmount={getTotalAmount}
					register={register}
					handleSubmit={handleSubmit}
					Controller={Controller}
					control={control}
					errors={errors}
					closeCheckout={closeCheckout}
					activeStep={activeStep}
					handleNext={handleNext}
				/>
			);
		case 1:
			return (
				<PaymentInfo
					userData={userData}
					timer={timer}
					paymentForm={paymentForm}
					shipmentForm={shipmentForm}
					setPaymentForm={setPaymentForm}
					register={register}
					closeCheckout={closeCheckout}
					getTotalAmount={getTotalAmount}
					activeStep={activeStep}
					handleNext={handleNext}
					handleBack={handleBack}
				/>
			);
		case 2:
			return (
				<CheckoutResume
					timer={timer}
					cart={cart}
					userData={userData}
					buyerForm={buyerForm}
					shipmentForm={shipmentForm}
					withdrawalDate={withdrawalDate}
					destinationForm={destinationForm}
					paymentForm={paymentForm}
					getTotalByComponents={getTotalByComponents}
					getTotalQuantity={getTotalQuantity}
					getTotalAmount={getTotalAmount}
					closeCheckout={closeCheckout}
					activeStep={activeStep}
					handleBack={handleBack}
					confirmOrder={confirmOrder}
					clear={clear}
				/>
			);
		default:
			<h1>Paso inexistente</h1>;
	}
};

export default CheckoutContainer;
