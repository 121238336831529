import Slider from 'react-slick'
import Box from '@mui/material/Box'
import { Container, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import ProductCard from '../ProductCard'
import SpinnerContainer from '../../containers/SpinnerContainer/SpinnerContainer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Home.css'

const settingsBanners = {
  dots: true,
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: true,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
}
function Home({ banners, products }) {
  return (
    <Container maxWidth="xl">
      <Box className="imgBannerContainer">
        <Slider {...settingsBanners}>
          {banners.map((banner) => (
            <Box key={banner.idBanner}>
              <img className="imgBanner" src={banner.img} alt={banner.alt} />
            </Box>
          ))}
        </Slider>
      </Box>
      <Box className="imgGridContainer">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '1.5rem',
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
              pb: 2
            }}
          >
            New In
          </Typography>
          {/* <Typography
						sx={{
							textAlign: "left",
							fontFamily: "Quicksand",
							pb: 2,
						}}
					>
						Ver todos
					</Typography> */}
        </Box>
        {products?.length > 0 ? (
          <Grid container spacing={2}>
            {products?.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </Grid>
        ) : (
          <SpinnerContainer />
        )}
      </Box>
    </Container>
  )
}

export default Home
