import { Button, Checkbox, Container, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
// import { NavLink } from "react-router-dom";
import "./LoginForm.css";
import logo from "../../img/logos/logo-eldon-blanco-xs.png";

const LoginTextField = styled(TextField)({
	input: {
		"&:-webkit-autofill": {
			WebkitBoxShadow: "0 0 0 1000px #0F0F0F inset",
			WebkitTextFillColor: "#FAFAFA !important",
		},
	},
	"&:-webkit-autofill": {
		WebkitBoxShadow: "0 0 0 1000px white inset",
	},
	"& label.Mui-focused": {
		color: "#FAFAFA",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#FAFAFA",
		},
		"&:hover fieldset": {
			borderColor: "#FFFFFF",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#FAFAFA",
		},
	},
});

const LoginForm = ({
	user,
	handleSubmit,
	handleChange,
	forgotPassword,
	isChecked,
	handleChecked,
}) => {
	return (
		<StyledEngineProvider injectFirst>
			<Container
				maxWidth="xs"
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					height: "100vh",
					color: "#FAFAFA",
					paddingTop: 10,
					paddingBottom: 3,
				}}
			>
				<Box component="div" sx={{ height: "fit-content" }}>
					<img
						src={logo}
						alt="Logo de El Don Mayorista"
						className="login-logo"
					/>
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{ textAlign: "center" }}
					>
						<Typography
							component="h1"
							variant="h5"
							sx={{ fontSize: "1rem", fontFamily: "Quicksand" }}
						>
							INICIAR SESIÓN
						</Typography>
						<LoginTextField
							id="userAccount"
							value={user.userAccount}
							fullWidth
							label="Usuario"
							name="userAccount"
							type="text"
							variant="outlined"
							margin="normal"
							autoComplete="on"
							onChange={handleChange}
							InputLabelProps={{
								style: { color: "#FAFAFA", fontFamily: "Quicksand" },
							}}
							sx={{ input: { color: "#FAFAFA" } }}
						/>
						<LoginTextField
							id="password"
							autoComplete="new-password"
							fullWidth
							label="Contraseña"
							name="userPassword"
							type="password"
							variant="outlined"
							margin="normal"
							onChange={handleChange}
							InputLabelProps={{
								style: { color: "#FAFAFA", fontFamily: "Quicksand" },
							}}
							sx={{ input: { color: "white" } }}
						/>
						<Typography
							component="h1"
							variant="h5"
							sx={{
								fontSize: "0.90rem",
								fontFamily: "Quicksand, sans-serif",
								textAlign: "center",
							}}
							className="checkBoxText"
						>
							<Checkbox
								style={{ color: "#FAFAFA", width: 33 }}
								checked={isChecked}
								onChange={handleChecked}
							/>
							Recordarme
						</Typography>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 1,
								fontSize: "1rem",
								fontWeight: 700,
								textTransform: "none",
							}}
							style={{ fontFamily: "Quicksand" }}
						>
							Ingresar
						</Button>
						<Button
							style={{
								fontFamily: "Quicksand",
								textTransform: "none",
								marginTop: "0.7rem",
							}}
							onClick={forgotPassword}
						>
							¿Olvidaste tu contraseña?
						</Button>
					</Box>
				</Box>
				{/* <Box display="flex" alignItems="baseline">
					<Typography sx={{ fontFamily: "Quicksand" }}>
						¿No sos cliente?{" "}
					</Typography>
					<Button
						style={{
							fontSize: "1rem",
							fontFamily: "Quicksand",
							textTransform: "none",
							color: "#292929",
						}}
					>
						<NavLink to="/create-user" className="create-user-link">
							Crea tu cuenta
						</NavLink>{" "}
					</Button>
				</Box> */}
			</Container>
		</StyledEngineProvider>
	);
};

export default LoginForm;
