import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#FAFAFA",
		},
		secondary: {
			main: "#292929",
		},
		light: {
			main: "#919494",
		},
		lightBg: {
			main: "#F5F6F7",
		},
	},
	typography: {
		fontFamily: "Quicksand",
	},
});

export default theme;
