import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAuth } from '../Auth/AuthContext'
import { useConfig } from '../Config/ConfigContext'
import { Product } from '../../entities/product'
import { api } from '../../services/api'
import { getComponentsWithQuantity } from '../../utils/auxFunc'
import { useDiscount } from '../Discount/DiscountContext'

const CartContext = createContext()

export const useCart = () => useContext(CartContext)

const Toast = Swal.mixin({
  // toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true
})

export const CartContextProvider = ({ defaultValue = [], children }) => {
  const [cart, setCart] = useState(defaultValue)
  const [minutes, setMinutes] = useState(null)
  const { config } = useConfig()
  const { userData } = useAuth()
  const { payConditionDiscount } = useDiscount()
  const navigate = useNavigate()

  const addToCart = (newProduct) => {
    const normalizedProduct = new Product(
      newProduct.idProduct,
      newProduct.name,
      newProduct.img,
      newProduct.description,
      newProduct.price,
      newProduct.category
    )
    newProduct.components.forEach((component) =>
      normalizedProduct.components.push(component)
    )

    let cartAux = JSON.parse(JSON.stringify(cart))

    const data = {
      cartItems: getComponentsWithQuantity(normalizedProduct.components),
      user: userData,
      clientId: userData.clientId
    }

    api.cart
      .addProducts(data)
      .then((data) => {
        if (!data.ok) {
          return Toast.fire({
            customClass: {
              title: 'productAdded',
              icon: 'productAddedIcon'
            },
            icon: 'error',
            title: 'No se pudo agregar al carrito'
          })
        }
        Toast.fire({
          customClass: {
            title: 'productAdded',
            icon: 'productAddedIcon'
          },
          icon: 'success',
          title: 'Productos Agregados'
        })

        if (cartAux !== null) {
          if (
            !cartAux?.some(
              (product) => product.idProduct === normalizedProduct.idProduct
            )
          ) {
            cartAux = [...cartAux, normalizedProduct]
          } else {
            let productIndex = cartAux.findIndex(
              (product) => product.idProduct === normalizedProduct.idProduct
            )

            normalizedProduct.components.forEach((component) => {
              if (
                !cartAux[productIndex].components.some(
                  (element) => element.idComponent === component.idComponent
                )
              ) {
                cartAux[productIndex].components.push(component)
              } else {
                cartAux[productIndex].components.find(
                  (element) => element.idComponent === component.idComponent
                ).quantity = component.quantity
              }
            })
          }
        } else {
          cartAux = []
          cartAux.push(normalizedProduct)
        }

        setCart(cartAux)
        setMinutes(config.BUY_TIMER)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addToComponent = async (idProduct, idComponent, quantity) => {
    let cartAux = JSON.parse(JSON.stringify(cart))

    const isFailed = await api.cart
      .addToComponent(idComponent, userData)
      .then((data) => {
        if (data.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Stock insuficiente',
            text: 'No es posible agregar más unidades'
          })
          return true
        }
        cartAux
          .find((product) => product.idProduct === idProduct)
          .components.find(
            (component) => component.idComponent === idComponent
          ).quantity = quantity

        setCart(cartAux)
        setMinutes(config.BUY_TIMER)
        return false
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error',
          text: 'Intentelo más tarde'
        })
        return true
      })

    return isFailed
  }

  const removeToComponent = (idProduct, idComponent, quantity) => {
    let cartAux = JSON.parse(JSON.stringify(cart))
    api.cart
      .removeToComponent(idComponent, userData)
      .then((data) => {
        if (data.status !== 200) {
          navigate('/', { replace: true })
          Swal.fire({
            icon: 'error',
            title: 'Se ha producido un error',
            text: 'Intentelo más tarde'
          })

          return
        }

        cartAux
          .find((product) => product.idProduct === idProduct)
          .components.find(
            (component) => component.idComponent === idComponent
          ).quantity = quantity

        setCart(cartAux)
        setMinutes(config.BUY_TIMER)
      })
      .catch((err) => {
        navigate('/', { replace: true })
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error',
          text: 'Intentelo más tarde'
        })
      })
  }

  //Eliminar producto completo, con todos sus componentes
  const removeByProductId = (id) => {
    let cartAux = JSON.parse(JSON.stringify(cart))

    api.cart
      .removeProduct(id, userData)
      .then((data) => {
        if (data.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: 'Se ha producido un error',
            text: 'Intentelo más tarde'
          })
          return
        }

        const newCart = cartAux.filter((product) => product.idProduct !== id)
        setCart(newCart)
        setMinutes(config.BUY_TIMER)
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error',
          text: 'Intentelo más tarde'
        })
      })
  }

  //Eliminar el item especifico por id
  const removeByComponentId = async (ids) => {
    let cartAux = [...cart]

    await api.cart.removeComponent(ids.idComponent, userData).then((data) => {
      if (data.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error',
          text: 'Intentelo más tarde'
        })
        return
      }
      const componentsUpdated = cartAux
        .find((product) => product.idProduct === ids.idProduct)
        .components.filter(
          (component) => component.idComponent !== ids.idComponent
        )

      componentsUpdated.length === 0
        ? //eliminar producto completo del cart cuando los componentes sean 0
          removeByProductId(ids.idProduct)
        : // o actualizar los componentes disponibles, asignando los componentes actualizados
          (cartAux.find(
            (product) => product.idProduct === ids.idProduct
          ).components = componentsUpdated)

      setMinutes(config.BUY_TIMER)
      setCart(cartAux)
    })
  }

  const getTotalByComponents = () => {
    const totalComponents = cart?.map((product) =>
      product.components.reduce((acc, component) => acc + component.quantity, 0)
    )
    return totalComponents
  }

  const getTotalQuantity = () => {
    const total = getTotalByComponents().reduce(
      (acc, quantity) => acc + quantity,
      0
    )
    return total
  }

  const getTotalAmountByComponents = () => {
    const totalComponentsAmount = cart?.map((product) =>
      product.components?.reduce(
        (acc, component) =>
          acc + component.quantity * product.price * (1 - payConditionDiscount),
        0
      )
    )
    return totalComponentsAmount
  }

  const getTotalAmount = () => {
    const total = getTotalAmountByComponents()?.reduce(
      (acc, amount) => acc + amount,
      0
    )
    return total
  }

  const clear = useCallback(() => {
    api.cart.emptyCart(userData).then((data) => {
      if (data.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error',
          text: 'Intentelo más tarde'
        })
        return
      }
      setCart([])
      // setMinutes(null)
      window.localStorage.removeItem('cartElDon')
    })
  }, [userData])

  const getCart = (data) => {
    api.cart
      .getCart(data)
      .then((response) => response.json())
      .catch((err) => console.log(err))
      .then((cart) => {
        if (cart.items?.length > 0) {
          setCart(cart.items)
          setMinutes(cart.cartTimer.totalMinutes)
        }
      })
  }

  useEffect(() => {
    if (userData?.status) {
      getCart(userData)
    }
  }, [userData])

  return (
    <CartContext.Provider
      value={{
        cart,
        minutes,
        addToCart,
        addToComponent,
        removeToComponent,
        removeByProductId,
        removeByComponentId,
        getTotalQuantity,
        getTotalByComponents,
        getTotalAmountByComponents,
        getTotalAmount,
        clear
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartContext
