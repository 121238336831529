import {
	Box,
	Button,
	Container,
	FilledInput,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Fragment, useState } from "react";

const CheckoutFormControl = styled(FormControl)(({ theme }) => ({
	marginBottom: "1rem",
	width: "100%",
	"& .MuiInputLabel-root.Mui-focused": {
		color: theme.palette.light.main,
	},
}));

const CheckoutShipmentForm = ({
	destinationForm,
	setDestinationForm,
	carriers,
	handleSubmit,
	Controller,
	control,
	errors,
	register,
}) => {
	const [edit, setEdit] = useState(false);

	const toggleEdit = () => {
		setEdit(!edit);
	};

	const handleEdit = (data) => {
		setDestinationForm({ ...destinationForm, ...data });
		toggleEdit();
	};

	return (
		<Fragment>
			<Container
				sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
			>
				<Box display="flex" justifyContent="space-between" pt={3}>
					<Typography
						variant="subtitle1"
						color={(theme) => theme.palette.light.main}
						sx={{
							fontSize: "1rem",
						}}
					>
						Dirección de envío:
					</Typography>
					<Box onClick={toggleEdit}>
						<Typography
							variant="subtitle1"
							color={(theme) => theme.palette.light.main}
							sx={{
								fontSize: "1rem",
							}}
						>
							Modificar
						</Typography>
					</Box>
				</Box>
			</Container>
			{!edit ? (
				<Fragment>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.1rem",
							}}
						>
							{destinationForm.shopName}
						</Typography>
						<Typography
							variant="subtitle1"
							color={(theme) => theme.palette.light.main}
							sx={{
								fontSize: "1rem",
								textTransform: "capitalize",
							}}
						>
							{destinationForm.shopAddress}
						</Typography>
						<Typography
							variant="subtitle1"
							color={(theme) => theme.palette.light.main}
							sx={{
								fontSize: "1rem",
								textTransform: "capitalize",
							}}
						>
							{`${destinationForm.shopCity}, CP: ${destinationForm.shopCP}`}
						</Typography>
					</Container>
					<Container
						sx={{ backgroundColor: (theme) => theme.palette.lightBg.main }}
					>
						<Box display="flex" justifyContent="space-between" pt={3}>
							<Typography
								variant="subtitle1"
								color={(theme) => theme.palette.light.main}
								sx={{
									fontSize: "1rem",
								}}
							>
								Medio de transporte:
							</Typography>
						</Box>
					</Container>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: "1.1rem",
								textTransform: "capitalize",
							}}
						>
							{destinationForm.transportName}
						</Typography>
					</Container>
				</Fragment>
			) : (
				<Fragment>
					<Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
						<Box
							component="form"
							onSubmit={handleSubmit((data) => handleEdit(data))}
						>
							<CheckoutFormControl fullWidth>
								<InputLabel>Nombre del Local</InputLabel>
								<FilledInput
									type="text"
									{...register("shopName", {
										required: "Debe completar este campo",
									})}
									variant=""
								></FilledInput>
								{errors.shopName ? (
									<Typography variant="caption" color="error">
										{errors.shopName.message}
									</Typography>
								) : (
									<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
										<br />
									</Typography>
								)}
							</CheckoutFormControl>
							<CheckoutFormControl>
								<InputLabel>Dirección del Local</InputLabel>
								<FilledInput
									type="text"
									{...register("shopAddress", {
										required: "Debe completar este campo",
									})}
								></FilledInput>
								{errors.shopAddress ? (
									<Typography variant="caption" color="error">
										{errors.shopAddress.message}
									</Typography>
								) : (
									<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
										<br />
									</Typography>
								)}
							</CheckoutFormControl>
							<CheckoutFormControl>
								<InputLabel>Ciudad</InputLabel>
								<FilledInput
									type="text"
									{...register("shopCity", {
										required: "Debe completar este campo",
									})}
								></FilledInput>
								{errors.shopCity ? (
									<Typography variant="caption" color="error">
										{errors.shopCity.message}
									</Typography>
								) : (
									<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
										<br />
									</Typography>
								)}
							</CheckoutFormControl>
							<CheckoutFormControl>
								<InputLabel>Código Postal</InputLabel>
								<FilledInput
									type="number"
									{...register("shopCP", {
										required: "Debe completar este campo",
									})}
								></FilledInput>
								{errors.shopCP ? (
									<Typography variant="caption" color="error">
										{errors.shopCP.message}
									</Typography>
								) : (
									<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
										<br />
									</Typography>
								)}
							</CheckoutFormControl>
							<CheckoutFormControl variant="filled">
								<InputLabel>Medio de transporte</InputLabel>
								<Controller
									name="transportName"
									control={control}
									render={({ field }) => (
										<Select
											onChange={(data) => field.onChange(data)}
											defaultValue=""
											{...register("transportName", {
												required: "Debe seleccionar un transporte",
											})}
										>
											{carriers?.map((carrier) => {
												return (
													<MenuItem
														key={carrier.id}
														value={carrier.name}
														sx={{ textTransform: "capitalize" }}
													>
														{carrier.name}
													</MenuItem>
												);
											})}
										</Select>
									)}
								/>
							</CheckoutFormControl>
							{errors.transportName ? (
								<Typography variant="caption" color="error">
									{errors.transportName.message}
								</Typography>
							) : (
								<Typography variant="caption" sx={{ color: "#FAFAFA" }}>
									<br />
								</Typography>
							)}
							<Button
								type="sumbit"
								color="secondary"
								variant="contained"
								fullWidth
								sx={{ textTransform: "capitalize" }}
							>
								Confirmar datos
							</Button>
						</Box>
					</Container>
				</Fragment>
			)}
		</Fragment>
	);
};

export default CheckoutShipmentForm;
