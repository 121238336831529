import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import Swal from "sweetalert2";
import { useAuth } from "../../context/Auth/AuthContext";
import { useDiscount } from "../../context/Discount/DiscountContext";
import ItemsList from "../../components/ItemsList";
import { useFilter } from "../../context/FilterContext/FilterContext";
import { api } from "../../services/api";
import CategoriesSlider from "../../components/CategoriesSlider";

const ItemsListContainer = () => {
	const [products, setProducts] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const { categoryId, productsSearched } = useParams();
	const { filterAPI, setFilterAPI } = useFilter();
	const { payConditionDiscount } = useDiscount();
	const { userData } = useAuth();
	const { clientPriceListId } = userData;

	const getProductsSearched = (url, signal) => {
		setIsLoading(true);
		api.products
			.listProductsSearched(url, signal)
			.then((response) => response.json())
			.then((productDTO) => {
				setProducts(productDTO.products);
				setIsLoading(false);
			})
			.catch((err) => {
				return Swal.fire({
					title: "Error!",
					text: `Error de servidor: ${err}`,
					icon: "error",
				});
			});
	};

	const getNewIn = (id, signal) => {
		setIsLoading(true);
		api.products
			.listNewIn(id, signal)
			.then((response) => response.json())
			.catch((err) => {
				Swal.fire({
					title: "Error!",
					text: `No hay productos nuevos`,
					icon: "error",
				});
			})
			.then((productsNewIn) => {
				setProducts(productsNewIn);
				setIsLoading(false);
			})
			.catch((err) => {
				return Swal.fire({
					title: "Error!",
					text: `Error de servidor: ${err}`,
					icon: "error",
				});
			});
	};

	useEffect(() => {
		const abortController = new AbortController();

		if (categoryId) {
			if (categoryId.toLowerCase() === "newin") {
				getNewIn(clientPriceListId, abortController.signal);
				return;
			}

			const urlCategory = `${categoryId}?codigoLista=${clientPriceListId}&color=${
				filterAPI?.color?.color || "%20"
			}&talle=${filterAPI?.sizeLetter?.sizeLetter || "%20"}&calce=${
				filterAPI?.fit?.fit || "%20"
			}&precioDesde=${
				filterAPI?.price[0] * (1 - payConditionDiscount) || 0
			}&precioHasta=${filterAPI?.price[1] * (1 - payConditionDiscount) || 0}`;

			const getByCategory = async () => {
				setIsLoading(true);
				try {
					const response = await api.products.listByCategory(
						urlCategory,
						abortController.signal
					);
					const productsDTO = await response.json();
					setProducts(productsDTO.products);
				} catch (error) {
					if (error.name === "AbortError") return;
					return Swal.fire({
						title: "Error!",
						text: `Error de servidor: ${error.message}`,
						icon: "error",
					});
				} finally {
					setIsLoading(false);
				}
			};

			getByCategory();
		} else {
			const urlSearch = `${productsSearched}/${
				filterAPI?.color?.color || "%20"
			}/${filterAPI?.sizeLetter?.sizeLetter || "%20"}/${
				filterAPI?.price[0] * (1 - payConditionDiscount) || 0
			}/${
				filterAPI?.price[1] * (1 - payConditionDiscount) || 0
			}?codigoLista=${clientPriceListId}`;

			getProductsSearched(urlSearch, abortController.signal);
		}

		return () => {
			abortController.abort();
		};
	}, [
		categoryId,
		clientPriceListId,
		filterAPI,
		filterAPI?.color,
		filterAPI?.fit?.fit,
		filterAPI?.price,
		filterAPI?.sizeLetter,
		payConditionDiscount,
		productsSearched,
		setFilterAPI,
	]);

	return (
		<Container maxWidth="xl">
			<CategoriesSlider />
			<ItemsList products={products} isLoading={isLoading} />
			{/* {!isLoading && (
				<PaginationComponent
					pages={pages}
					page={page}
					handlePage={handlePage}
				/>
			)} */}
		</Container>
	);
};

export default ItemsListContainer;
